import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid2";
import {checkPassword, renderReferralData, returnPhoneCountriesCode} from "../../utils/Helpers";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/material.css';
import {isValidPhoneNumber} from "libphonenumber-js";
import React from "react";
import Divider from "@mui/material/Divider";

const verifyAdmins = (admins: [{}]) => {
	for (let k = 0; k < admins.length; k ++) {
		if(!admins[k].is_external_advisor) {
			if (
				!admins[k].forename ||
				!admins[k].surname ||
				!admins[k].telephone ||
				!admins[k].password ||
				!admins[k].confirm_password ||
				admins[k].password.length < 6 ||
				!admins[k].forename ||
				(admins[k].password !== admins[k].confirm_password) ||
				admins[k].telephone.length < 5 ||
				!isValidPhoneNumber("+" + admins[k].telephone)
			)
				return true;
		}

	}
	return false;
};
/**
 * @param props
 * @param token
 * @param token
 * @param setAdmins
 * @param admins
 * @param setAdmins
 * @returns {unknown[]|*[]}
 * @private
 */
const _renderTexts = (props: any[], token: "", admins: {}, setAdmins: function) => {

	const handleInputChange = (e, index, isTelephone = false) => {
		const list = [...admins];
		if (isTelephone) {
			list[index]["telephone"] = e;
			setAdmins(list);
		} else {
			const {name, value} = e.target;
			list[index][name] = value;
			setAdmins(list);
		}
	};

	return admins.map((admin, index) => (
		!admin.is_external_advisor && (
			<Grid
				key={index}
			>
				<FormControl
					className={"signup-form-control"}
					fullWidth
				>
					<TextField
						type={"text"}
						onChange={e => handleInputChange(e, index)}
						label="Name*"
						variant="outlined"
						name={"forename"}
						value={admin?.forename ?? ""}
					/>
				</FormControl>
				<FormControl
					className={"signup-form-control"}
					fullWidth
				>
					<TextField
						type={"text"}
						onChange={e => handleInputChange(e, index)}
						label="Middle name"
						variant="outlined"
						name={"middle_name"}
						value={admin?.middle_name ?? ""}
					/>
				</FormControl>
				<FormControl
					className={"signup-form-control"}
					fullWidth
				>
					<TextField
						type={"text"}
						onChange={e => handleInputChange(e, index)}
						label="Surname*"
						variant="outlined"
						name={"surname"}
						value={admin?.surname ?? ""}
					/>
				</FormControl>
				{
					admin?.email_admin ?
						<FormControl
							className={"signup-form-control"}
							fullWidth
							disabled={true}
						>
							<TextField
								type={"email"}
								label="Email*"
								variant="outlined"
								name={"email_admin"}
								value={admin.email_admin ?? ""}
							/>
						</FormControl>
						:
						""
				}
				<FormControl
					className={"signup-form-control"}
					fullWidth
				>
					<TextField
						type={"password"}
						onChange={e => handleInputChange(e, index)}
						label="Password*"
						variant="outlined"
						name={"password"}
						value={admin?.password ?? ""}
						error={(admin?.password !== admin?.confirm_password)
							|| (admin?.password !== '' && !checkPassword(admin?.password))}
					/>

					<small>
						* Please type min. 6-character password containing
					</small>
					<small>
						letters and at least a number.
					</small>
				</FormControl>
				<FormControl
					className={"signup-form-control"}
					fullWidth
				>
					<TextField
						type={"password"}
						onChange={e => handleInputChange(e, index)}
						label="Confirm password*"
						variant="outlined"
						name={"confirm_password"}
						value={admin?.confirm_password ?? ""}
						error={admin?.password !== admin?.confirm_password}
					/>
					<small className={"red-text"}>
						{
							(admin?.password !== admin?.confirm_password) ?
								"Passwords do not match"
								:
								""
						}
					</small>
				</FormControl>
				<FormControl
					className={"signup-form-control phone-label"}
					fullWidth
				>
					<PhoneInput
						inputStyle={{
							width: "100%",
						}}
						country="gb"
						regions={returnPhoneCountriesCode()}
						inputProps={{
							name: 'telephone',
							id: "telephone",
							required: true,
						}}
						searchPlaceholder={"Country name"}
						searchNotFound={"No country found, please search again."}
						specialLabel={"Mobile | Phone*"}
						enableSearch
						onChange={e => handleInputChange(e, index, true)}
						countryCodeEditable={false}
					/>
					<small>
						There is no need to add the zero at the phone number
					</small>

				</FormControl>

				<FormControl
					className={"signup-form-control phone-label"}
					fullWidth
				>
					<small>
						All fields with * are required
					</small>
				</FormControl>
				{
					admins.length - 1 === index ?
						""
						:
						<Divider className={"divider-class"}/>
				}
			</Grid>
		)
	));
}

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const AdminDetailsForm = (props = {
	token: "",
	referral: {},
	admins: {},
	setAdmins: () => {},
	proceedStep: () => {},
	backStep: () => {}
}): JSX.Element => (
	<Grid
		id={"signup-container"}
		size={12}
		alignItems={"center"}
		alignContent={"center"}
		direction={"column"}
		container
		item
	>
		<form
			noValidate
			autoComplete="off"
		>
			{renderReferralData(props?.referral ?? {})}
			<FormControl
				className={"signup-form-control-2 first-title"}
				fullWidth
			>
				<Typography align={"center"}>
					Create your admin{props.admins.length > 0 ? "(s)" : ""} details
				</Typography>
			</FormControl>
			<FormControl
				className={"signup-form-control-2 second-title"}
				fullWidth
			>
				<Typography align={"center"}>
					Please follow the info below
				</Typography>
			</FormControl>
			{
				_renderTexts(props ?? false, props.token, props.admins, props.setAdmins)
			}
			<FormControl
				className={"signup-form-control"}
				fullWidth
			>
				<Button
					variant="contained"
					color="primary"
					onClick={() => props.proceedStep()}
					disabled={
						verifyAdmins(props.admins)
					}
				>
					Continue
				</Button>
			</FormControl>

			<FormControl
				className={"signup-form-control-2 forgot-password"}
				fullWidth
			>
				<span
					className={"back-span"}
					onClick={() => props.backStep()}
				>
					Back
				</span>
			</FormControl>
		</form>
	</Grid>
);

/**
 * @stateless
 * @param props
 * @returns {JSX.Element}
 */
export default AdminDetailsForm;