import React, {useEffect} from "react";

import Wrapper from "../../layout/Logged/Wrapper";
import SectionGrid from "../../layout/SectionGrid";
import LoadingPanel from "../LoadingPanel";

import Grid from "@mui/material/Grid2";
import Button from "@mui/material/Button";

import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";

import {useDispatch, useSelector} from "react-redux";
import * as Profile from '../../actions/Profile'
import {useNavigate as useHistory} from "react-router-dom";

import AccountPrimaryHeader from "./AccountPrimaryHeader";

/**
 * @param inputFields
 * @param buttons
 * @param loading
 * @param title
 * @returns {JSX.Element}
 * @constructor
 */
const AccountDataEditor = ({
	inputFields = [],
	buttons = [],
	loading = false,
	title = "",
}): JSX.Element => {
	const
		{
			profile_data,
			edited_fields = {}
		} = useSelector(state => state?.Profile),
		{
			company_number = 0
		} = profile_data?.employer,
		{
			employer_id,
		} = profile_data;
	
	const dispatch = useDispatch(),
		createUpdater = fieldname => value => dispatch(Profile.updateField(fieldname, value));
	
	
	useEffect(() => {
		if (!company_number) {
			dispatch(Profile.me());
		}
	}, [
		company_number,
		dispatch,
	]);
	
	const history = useHistory();
	
	if (loading) {
		return (
			<Wrapper>
				<LoadingPanel/>
			</Wrapper>
		);
	}
	
	return (
		<Wrapper
			history={history}
		>
			<SectionGrid>
				<Grid
					id={"menu-logged-wrapper"}
					justifyContent={"center"}
					container
					size={12}
				>
					<AccountPrimaryHeader
						title={title}
						history={history}
						location={"/my-account"}
					/>
					
					<Grid
						className={"collegia-border"}
						justifyContent={"space-around"}
						container
						size={{
							xs: 12,
							sm: 12,
							lg: 12,
							md: 12
						}}
					>
						{
							inputFields
								.map(({
									      id,
									      updater,
									      value,
									      label,
									      disabled = false,
									      error = false,
									      type = id
								      }, index) => (
									<Grid
										key={`field-${index}`}
										container
										size={{
											lg: 12,
											md: 12,
											sm: 12
										}}
									>
										<FormControl
											key={`field-${index}`}
											id={"change-email-fields"}
											fullWidth
										>
											<TextField
												fullWidth
												type={type}
												onChange={(event) =>
													createUpdater(id)(event.target.value)
												}
												label={label}
												variant={"outlined"}
												error={error}
												name={id}
												disabled={disabled || false}
												defaultValue={value}
												id={id}
											/>
										</FormControl>
									</Grid>
								))
						}
						{
							buttons.map(({
								             variant,
								             onClick,
								             text = '',
								             enabled = (inputFields
									             .filter(({id}) => edited_fields[id] && edited_fields[id] !== '')
									             .length === inputFields.length && !inputFields
									             .map(({error}) => error)
									             .includes(true))
							             }, index
							) => (
								<Grid
									key={`button-${index}`}
									justifyContent={"center"}
									container
									size={{
										xs: 6,
										sm: 6,
										lg: 6,
										md: 6
									}}
								>
									<Button
										id={"change-email-fields"}
										fullWidth
										color={"primary"}
										variant={variant}
										disabled={!enabled}
										onClick={() => onClick({...edited_fields, employer_id})}
									>
										{text}
									</Button>
								</Grid>
							))
						}
					</Grid>
				</Grid>
			</SectionGrid>
		</Wrapper>
	);
}

export default AccountDataEditor;