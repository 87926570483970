import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import Grid from "@mui/material/Grid2";
import {validateEmail} from "../../utils/Helpers";
import Modal from "@mui/material/Modal";


const InviteManuallyForm = (
	{
		full_name = "",
		email = "",
		isOpen = false,
		setFullName = () => {},
		setEmail = () => {},
		insert = (data = {}) => data,
		handleDrawer = () => {},
	}
) => (
	<Modal
		open={isOpen ?? false}
		aria-labelledby="simple-modal-title"
		aria-describedby="simple-modal-description"
		className={"upload-ddi-history-modal"}
		closeAfterTransition
		onClose={() => handleDrawer(isOpen)}
	>
		<Grid
            alignItems={"center"}
            alignContent={"center"}
            direction={"column"}
            container
            className={"grid-modal"}
            size={{
                xs: 12,
                sm: 12,
                lg: 12,
                md: 12
            }}
		>
			<Grid
                alignItems={"center"}
                alignContent={"center"}
                direction={"column"}
                container
                size={12}
                marginTop={6}
			>
				<FormControl className={"form-control-invite-employee"}>
					<TextField
						label={"Full name"}
						variant={"outlined"}
						name={`full_name`}
						id={`full_name`}
						value={full_name}
						onChange={(e) => setFullName(e.target.value)}
					/>
				</FormControl>
				<FormControl className={"form-control-invite-employee"}>
					<TextField
						type={"email"}
						label={"Email"}
						variant={"outlined"}
						name={`email`}
						id={`email`}
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					/>
				</FormControl>
				<FormControl>
					<Button
						variant={"contained"}
						color={"primary"}
						disabled={!full_name || !email || !validateEmail(email)}
						onClick={() => {
							insert({
								invitations: {
									name: full_name,
									email,
								}
							});
							handleDrawer(isOpen);
							setEmail("");
							setFullName("");
						}}
					>
						<ForwardToInboxIcon/>
						<span>
							Invite
						</span>
					</Button>
				</FormControl>
			</Grid>
		</Grid>
	</Modal>
);

export default InviteManuallyForm;
