import {getEmployeeContributions} from "../actions/Contributions";
import { connect } from 'react-redux';
import {useEffect} from "react";
import LoadingPanel from "../components/LoadingPanel";
import MUIDataTable from "mui-datatables";
import {ThemeProvider} from "@mui/styles";
import {useTheme} from "@mui/material";
import {useParams} from "react-router-dom";

const PapdisDetails = (props) => {
	const id = useParams().id;

	const theme = useTheme({
		breakpoints: {
			md: '800px'
		}
	});

	const {
		getEmployeeContributions = () => {}
	} = props;


	let columns = [];

	useEffect(() => {
		getEmployeeContributions("", "payroll", id, false);
	}, [getEmployeeContributions, id]);

	if (props?.papdis?.employees_contributions_loading) {
		return (
			<LoadingPanel/>
		);
	}

	if(!props?.papdis?.isLoading && props?.papdis?.isLoading !== undefined && props?.papdis?.employee_contributions?.length > 0){
		Object.keys(props?.papdis?.employee_contributions[0]).map(key => {
			columns.push({
				name: key,
				label: key.replace(/_/g, " ").toUpperCase(),
				options: {
					setCellProps: () => ({className: "table-cell-item-subtitle", align: "center"})
				}
			});
			return true;
		});
	}

	return (
		props?.papdis?.employee_contributions?.length > 0 ?
			<ThemeProvider theme={theme}>
				<MUIDataTable
					className={"data-table papdis-details-table"}
					columns={columns}
					data={props?.papdis?.employee_contributions}
					options={{
						print: false,
						elevation: 1,
						filter: false,
						selectableRowsHeader: false,
						selectableRows: "none",
						responsive: "simple",
						viewColumns: false,
					}}
				/>
			</ThemeProvider>
		:
			<h2
				style={{
					margin: "0 auto",
					marginTop: "60px"
				}}
			>
				Sorry, no matching records found
			</h2>
	);
};

/**
 * @param state
 * @returns {*}
 */
const mapStateToProps = state => {
	const { PAPDIS } = state;

	return {
		papdis: PAPDIS,
	};
};

export default connect(
	mapStateToProps,
	dispatch => ({
		getEmployeeContributions: (
			national_insurance_number: string = "",
			type: string = "",
			id:number = 0,
			isAdvisor:boolean = false) => dispatch(
				getEmployeeContributions(
					national_insurance_number,
					type,
					id,
					isAdvisor
				)
			),
	})
)(PapdisDetails)