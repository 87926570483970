import React from "react";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import moment from "moment/moment";

/**
 * @type {string[]}
 */
const
	fieldsGroup =
		[
			'Group',
			'Subgroup',
		],

	fieldsContributionsInfo =
		[
			'Employment start date',
			'Frequency code',
			'Employer contributions',
			'Employee contributions',
			'Additional voluntary contributions',
		],

	fieldsExit =
		[
			'Exit date',
			'Exit reason code',
			'Ae opt out Date',
		];

	function _renderFrequency (frequency) {
		switch(frequency) {
			case "W1":
				return "Weekly (W1)";
			case "W2":
				return "Fortnightly (W2)";
			case "TW":
				return "Tax Weekly (TW)";
			case "W4":
				return "Four Weekly (W4)";
			case "M1":
				return "Monthly (M1)";
			case "TM":
				return "Tax Monthly (TM)";
			case "M3":
				return "Quarterly (M3)";
			case "M6":
				return "Bi-Annually (M6)";
			default:
				return "Annually (MA)";
		}
	}


/**
 * @param employee_contributions
 * @returns {JSX.Element}
 * @constructor
 */
const RenderEmployeeTabModal: JSX.Element = ({
	employee_contributions= [],
}:{
	employee_contributions: any,
}): JSX.Element => {
	return (
		<Grid>
			<Grid
				className={"collegia-container-wrapper"}
				container
				size={12}
			>
				<Grid
					size={6}
				>
					{fieldsGroup.map((labels, index) => (
						<Typography
							key={index}
						>
							{labels}
						</Typography>
					))}
				</Grid>
				<Grid
					size={6}
				>
					<Typography>
						{!employee_contributions?.group
							?   "--"
							:   employee_contributions?.group
						}
					</Typography>
					<Typography>
						{!employee_contributions?.subgroup
							?   "--"
							:   employee_contributions?.subgroup
						}
					</Typography>
				</Grid>
			</Grid>
			<Grid
				className={"collegia-container-wrapper"}
				container
				size={12}
			>
				<Grid
					size={6}
				>
					{fieldsContributionsInfo.map((labels, index) => (
						<Typography
							key={index}
						>
							{labels}
						</Typography>
					))}
				</Grid>
				<Grid
					size={6}
				>
					<Typography>
						{!employee_contributions?.employment_start_date
							?   "£ 0"
							:   moment(employee_contributions?.employment_start_date).format("DD/MM/YYYY")
						}
					</Typography>
					<Typography>
						{!employee_contributions?.frequency_code
							?   "N/A"
							:   _renderFrequency(employee_contributions?.frequency_code)
						}
					</Typography>
					<Typography>
						{!employee_contributions?.employer_contributions_amount
							?   "£ 0"
							:   "£ "+employee_contributions?.employer_contributions_amount
						}
					</Typography>
					<Typography>
						{!employee_contributions?.employee_contributions_amount
							?   "£ 0"
							:   "£ "+employee_contributions?.employee_contributions_amount
						}
					</Typography>
					<Typography>
						{!employee_contributions?.additional_voluntary_contributions_amount
							?   "£ 0"
							:   "£ "+employee_contributions?.additional_voluntary_contributions_amount
						}
					</Typography>
				</Grid>
			</Grid>

			<Grid
				className={"collegia-container-wrapper"}
				container
				size={12}
			>
				<Grid
					size={6}
				>
					{fieldsExit.map((labels, index) => (
						<Typography
							key={index}
						>
							{labels}
						</Typography>
					))}
				</Grid>
				<Grid
					size={6}
				>
					<Typography>
						{!employee_contributions?.exit_date
							?   "--"
							:   moment(employee_contributions?.exit_date).format("DD/MM/YYYY")
						}
					</Typography>
					<Typography>
						{!employee_contributions?.exit_reason_code
							?   "--"
							:   employee_contributions?.exit_reason_code
						}
					</Typography>
					<Typography>
						{!employee_contributions?.ae_opt_out_date
							?   "--"
							:   moment(employee_contributions?.ae_opt_out_date).format("DD/MM/YYYY")
						}
					</Typography>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default RenderEmployeeTabModal;