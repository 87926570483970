import React, {useState, useEffect} from "react";

import {SIGNUP_VERIFY_COMPANY} from '../actions/SignUp/type';

import {
	getQueryStringsParams,
	mountQueryStringsUrls,
} from "../utils/Helpers";

import Wrapper from "../layout/Wrapper";
import Slide from "@mui/material/Slide";
import Grid from "@mui/material/Grid2";
import Logo from "../images/logo-icon.svg";
import MinimalFooter from "../layout/MinimalFooter";
import {connect} from "react-redux";
import AdminDetailsForm from "../components/SignUp/AdminDetailsForm";
import FindCompanyDetails from "../components/SignUp/FindCompanyDetails";
import EmailDetail from "../components/SignUp/EmailDetail";
import ConfirmCompanyDetails from "../components/SignUp/ConfirmCompanyDetails";
import GenericTermsAndConditions from "../components/GenericTermsAndConditions";
import BodyText from "../components/BodyText";
import {getBillingTypesList} from "../actions/BillingTypes";
import SuccessForm from "../components/SignUp/Success";

import {
	createDirectDebit,
	getCompanies,
	getDirectors,
	setOfficersEmpty,
	setup,
	signUp,
	verifyEmail,
} from "../actions/SignUp";

import * as SnackBar from "../actions/SnackBar"
import LoadingPanel from "../components/LoadingPanel";
import {findByReferralCode} from "../actions/Referral";
import {useNavigate, useParams} from "react-router-dom";

const fillFields = (
	setup_data: any = [],
	formValues: any = [],
	admins: any = [],
	setFormValues: function = () => {},
	setAdmins: function = () => {},
) => {
	setFormValues({
		...formValues,
		email: setup_data?.employer?.primary_email,
		forename: setup_data?.employer?.forename,
		company_number: setup_data?.employer?.company_number,
	});
	for (let k = 0; k < setup_data?.employer?.admins?.length; k++) {
		setAdmins(admins => [...admins,
			{
				is_external_advisor: setup_data?.employer?.admins[k]?.is_external_advisor,
				forename: setup_data?.employer?.admins[k]?.forename,
				middle_name: setup_data?.employer?.admins[k]?.middle_name,
				surname: setup_data?.employer?.admins[k]?.surname,
				telephone: setup_data?.employer?.admins[k]?.telephone,
				email_admin: setup_data?.employer?.admins[k]?.email
			}
		]);
	}
	admins.shift();
}

/**
 * @returns {[]}
 * @private
 */
const _addDirectors = (
	officers: any = [],
	directorList: any = [],
	setDirectorList: function = () => {
	},
) => {
	officers.filter((director => director?.officer_role.toLowerCase() === 'director')).forEach((director) => {
		setDirectorList(directorList => [...directorList,
			{
				name: director?.name,
				email: "",
				date_of_birth: {
					year: director?.date_of_birth?.year,
					month: director?.date_of_birth?.month,
				},
				officer_role: director?.officer_role,
				appointed_on: director?.appointed_on,
				address: {
					address_line_1: director?.address?.address_line_1,
					address_line_2: director?.address?.address_line_2,
					country: director?.address?.country,
					locality: director?.address?.locality,
					postal_code: director?.address?.postal_code,
					premises: director?.address?.premises,
					region: director?.address?.region
				},
				links: {
					officer: {
						appointments: director?.links?.officer?.appointments
					},
					self: director?.links?.self
				},
				signature: false
			}
		])
	});
};

/**
 * @param section
 * @param accordion
 * @param setAccordion
 * @private
 */
const _readTermsAndConditions = (
	section: string = "",
	accordion: any = [],
	setAccordion: function = () => {
	},
): void => {
	if (accordion.section === section) {
		setAccordion({
			section: section,
			read: true,
			expanded: !accordion.expanded
		});
	} else {
		setAccordion({
			section: section,
			read: true,
			expanded: true
		});
	}
};

/**
 * @private
 * @param referral
 * @param token
 * @param admins
 * @param directorList
 * @param formValues
 * @param setup_data
 * @param company
 * @param proceedStep
 * @param backStep
 * @param signUp
 */
const _agreedTermsAndConditions = (
	referral = [],
	token = "",
	admins = [],
	directorList = [],
	formValues = [],
	setup_data = [],
	company = [],
	proceedStep = () => {},
	backStep = () => {},
	signUp = () => {}
) => {
	let company_number, company_name;
	if (token) {
		company_number = setup_data?.employer?.company_number ?? "";
		company_name = setup_data?.employer?.title ?? "";
	} else if (company) {
		company_number = company.company_number ?? "";
		company_name = company.title ?? "";
	} else {
		company_number = formValues.company_number ?? "";
		company_name = formValues.company_name ?? "";
	}

	if (company_number && company_name) {
		const data = {
			employers: {
				title: company_name,
				company_number: company_number,
				primary_email: formValues.email,
				description: "Save by Collegia App",
				links: {},
				address: [
					{
						...company.address,
						address_type: 1
					},
					{
						locality: formValues.company_trading_locality,
						country: formValues.company_trading_country,
						address_line_1: formValues.company_trading_address,
						premises: formValues.company_trading_premises,
						postal_code: formValues.company_trading_postcode,
						address_snippet: company?.address?.address_snippet,
						address_type: 2
					}
				],
				officers: Array.isArray(directorList) ? directorList : [directorList],
				read_terms: true,
				admins: admins,
				is_employer_under_bureau: formValues.is_employer_under_bureau,
				manual_creation: formValues.manual_creation,
				referral,
			}
		};
		signUp(
			data,
			token,
			proceedStep,
			backStep,
		);
	}
};

/**
 * @returns {JSX.Element|*[]}
 * @private
 * @param formValues
 * @param props
 * @param token
 * @param admins
 * @param referral
 * @param stepIndex
 * @param enterManually
 * @param directorList
 * @param addDirector
 * @param checkConfirm
 * @param addDirectorConfirmPage
 * @param checkConfirmAuthorised
 * @param companyCheckAddress
 * @param company
 * @param accordion
 * @param setFormValues
 * @param setAdmins
 * @param setEnterManually
 * @param setStepIndex
 * @param setDirectorList
 * @param setAddDirector
 * @param setEnterManually
 * @param setCheckConfirm
 * @param setAddDirectorConfirmPage
 * @param setCheckConfirmAuthorised
 * @param setCompanyCheckAddress
 * @param setCompany
 * @param setAccordion
 */

const renderContent = (
	props = false,
	token = "",
	formValues = [],
	admins = {},
	referral = "",
	stepIndex = 0,
	enterManually = false,
	directorList = [],
	addDirector = false,
	checkConfirm = false,
	addDirectorConfirmPage = false,
	checkConfirmAuthorised = false,
	companyCheckAddress = false,
	company = [],
	accordion = {},
	setFormValues = () => {},
	setAdmins = () => {},
	setStepIndex = () => {},
	setEnterManually = () => {},
	setDirectorList = () => {},
	setAddDirector = () => {},
	setCheckConfirm = () => {},
	setAddDirectorConfirmPage = () => {},
	setCheckConfirmAuthorised = () => {},
	setCompanyCheckAddress = () => {},
	setCompany = () => {},
	setAccordion = () => {}
): JSX.Element | *[] => {
	const {
		isFormLoading = false,
		isFormFinished = false,
		setup_data = [],
		error_message = "",
		officers = [],
		getCompanies = () => {},
		getDirectors = () => {},
		setOfficersEmpty = () => {},
		signUp = () => {}
	} = props;

	if (isFormFinished || isFormLoading)
		return (
			<LoadingPanel/>
		);

	const proceedStep = () => {
		setStepIndex(stepIndex + 1);
		if (!token && stepIndex === 1) {
			const list = [...admins];
			list[0]["email_admin"] = formValues.email;
			setAdmins(list);
		}
	};

	const backStep = (alreadyExistCompany: boolean = false) => {
		if (alreadyExistCompany) {
			setStepIndex(2);
			setCompanyCheckAddress(false);
			setDirectorList([{
				name: "",
				email: "",
				date_of_birth: {
					year: 0,
					month: 0
				},
				officer_role: "director",
				appointed_on: "1900-01-01",
				address: "N/A",
				links: "N/A",
				signature: false,
				phone_number: "N/A",
			}]);
			setOfficersEmpty();
			setCompany([]);
			setCheckConfirm(false);
			setCheckConfirmAuthorised(false);
			setFormValues({
				...formValues,
				company_trading_locality: "",
				company_trading_country: "",
				company_trading_address_line_1: "",
				company_trading_premises: "",
				company_trading_postcode: "",
			});
		} else {
			setStepIndex(stepIndex - 1);
			if (stepIndex === 1 && !token) {
				setAdmins([{
					forename: "",
					surname: "",
					middle_name: "",
					email_admin: "",
					password: "",
					confirm_password: "",
					telephone: "",
					email: "",
					is_external_advisor: false
				}]);
			}
			if (stepIndex === 3 && !token) {
				setCompanyCheckAddress(false);
				setDirectorList([{
					name: "",
					email: "",
					date_of_birth: {
						year: 0,
						month: 0
					},
					officer_role: "director",
					appointed_on: "1900-01-01",
					address: "N/A",
					links: "N/A",
					signature: false
				}]);
				setOfficersEmpty();
				setCompany([]);
				setCheckConfirm(false);
			}
		}
	};

	switch (stepIndex) {
		case 0:
			return <EmailDetail
				email={formValues.email}
				errorMessage={error_message}
				formValues={formValues}
				token={token}
				referral={referral}
				setFormValues={setFormValues}
				checkEmailAddress={props.checkEmailAddress}
				history={props.history}
				proceedStep={proceedStep}
			/>;

		case 1:
			return <AdminDetailsForm
				token={token ?? false}
				referral={referral}
				admins={admins}
				manual_creation={formValues.manual_creation}
				errorMessage={error_message}
				adminError={props.adminError ?? []}
				history={props.history}
				setAdmins={setAdmins}
				proceedStep={proceedStep}
				backStep={backStep}
			/>;

		case 2:
			return <FindCompanyDetails
				token={token ?? false}
				referral={referral}
				formValues={formValues}
				directorList={directorList}
				company={company}
				companies={props.companies ?? []}
				addDirector={addDirector}
				setupData={setup_data ?? false}
				enterManually={enterManually}
				checkConfirm={checkConfirm}
				companyCheckAddress={companyCheckAddress}
				alreadyExistsMessage={props.alreadyExistsMessage}
				setFormValues={setFormValues}
				setDirectorList={setDirectorList}
				setCompany={setCompany}
				setAddDirector={setAddDirector}
				setCheckConfirm={setCheckConfirm}
				setCompanyCheckAddress={setCompanyCheckAddress}
				setEnterManually={setEnterManually}
				search={getCompanies}
				getOfficers={getDirectors}
				proceedStep={proceedStep}
				backStep={backStep}
			/>;

		case 3:
			return <ConfirmCompanyDetails
				referral={referral}
				token={token ?? false}
				setup_data={setup_data ?? []}
				formValues={formValues}
				company={company}
				directorList={directorList}
				officers={officers}
				addDirector={props.addDirector}
				setAddDirector={props.setAddDirector}
				company_trading_postcode={formValues.company_trading_postcode}
				company_trading_address_line_1={formValues.company_trading_address_line_1}
				company_trading_premises={formValues.company_trading_premises}
				company_trading_locality={formValues.company_trading_locality}
				company_trading_country={formValues.company_trading_country}
				companyCheckAddress={companyCheckAddress}
				addDirectorConfirmPage={addDirectorConfirmPage}
				checkConfirmAuthorised={checkConfirmAuthorised}
				enterManually={enterManually}
				setFormValues={setFormValues}
				setDirectorList={setDirectorList}
				setAddDirectorConfirmPage={setAddDirectorConfirmPage}
				setCheckConfirmAuthorised={setCheckConfirmAuthorised}
				setCompanyCheckAddress={setCompanyCheckAddress}
				addDirectors={_addDirectors}
				proceedStep={proceedStep}
				backStep={backStep}
			/>;

		case 4:
			return (
				<Grid
					id={"initialSetUpModalTermsAndConditions"}
					size={12}
					alignItems={"center"}
					alignContent={"center"}
					direction={"column"}
					className={"modal-container"}
					container
				>
					<Grid
						size={12}
						container
					>
						<BodyText/>
					</Grid>
					<GenericTermsAndConditions
						referral={referral}
						token={token}
						admins={admins}
						formValues={formValues}
						directorList={directorList}
						setup_data={setup_data}
						company={company}
						accordion={accordion ?? []}
						setAccordion={setAccordion}
						agreedTermsAndConditions={_agreedTermsAndConditions}
						handleChange={_readTermsAndConditions}
						signUp={signUp}
						proceedStep={proceedStep}
						backStep={backStep}
					/>
				</Grid>
			);
		case 5:
			return <SuccessForm
				redirectCondition={!props.isFormLoading}
				callback_url={setup_data?.callback_url}
				token={props?.collegia_token}
				referral={referral}
			/>

		default:
			return [];
	}
}

/**
 * @returns {JSX.Element}
 */
const SignUp = (props): JSX.Element => {

	const {
		setup,
		setup_data,
		generate_company_number,
		findByReferralCode,
		referral,
	} = props;

	const token =
			useParams().token ?? false,
		[formValues, setFormValues] = useState({
			email: "",
			company: [],
			company_name: "",
			company_number: "",
			company_trading_address: "",
			company_trading_postcode: "",
			company_registered_address: "",
			company_registered_address_postcode: "",
			company_trading_locality: "",
			company_trading_country: "",
			company_trading_address_line_1: "",
			company_trading_premises: "",
			country: "",
			locality: "",
			company_legal_structure: "",
			company_legal_structure_options: [
				{title: 'Limited Company'},
				{title: 'Limited Partnership'},
				{title: 'Limited Liability Partnership'},
				{title: 'Unincorporated Association'},
				{title: 'Ordinary Business Partnership'},
				{title: 'Sole Trader'},
				{title: 'Charity'},
			],
			is_employer_under_bureau: false,
		}),
		[admins, setAdmins] = useState([{
			forename: "",
			surname: "",
			middle_name: "",
			email_admin: "",
			password: "",
			confirm_password: "",
			telephone: "",
			email: "",
			is_external_advisor: false
		}]),
		[directorList, setDirectorList] = useState([]),
		[company, setCompany] = useState(""),
		[stepIndex, setStepIndex] = useState(0),
		[enterManually, setEnterManually] = useState(false),
		[addDirector, setAddDirector] = useState(false),
		[checkConfirm, setCheckConfirm] = useState(false),
		[addDirectorConfirmPage, setAddDirectorConfirmPage] = useState(false),
		[checkConfirmAuthorised, setCheckConfirmAuthorised] = useState(false),
		[companyCheckAddress, setCompanyCheckAddress] = useState(false),
		[accordion, setAccordion] = useState({
			section: "",
			read: true,
			expanded: true
		});

	useEffect(() => {
		findByReferralCode(getQueryStringsParams()?.id ?? "");
		if (token) {
			setup(token);
		}
	}, [findByReferralCode, setup, token]);

	useEffect(() => {
		if (setup_data?.employer?.primary_email && !formValues.company_number) {
			fillFields(setup_data, formValues, admins, setFormValues, setAdmins)
		}
		if (generate_company_number.length > 0 && formValues.company_number.length === 0) {
			setFormValues({
				...formValues,
				company_number: generate_company_number,
			});
		}
	}, [
		token,
		setup_data,
		formValues,
		admins,
		generate_company_number,
	]);

	const push = useNavigate();

	return (
		<Wrapper showSandwich={false}>
			<Slide direction="up" in={true} mountOnEnter unmountOnExit>
				<div id={"signup-wrapper"}>
					<Grid
						size={12}
						alignItems={"center"}
						alignContent={"center"}
						direction={"column"}
						id={"header"}
						container
						onClick={() => push(
							mountQueryStringsUrls("login", window.location.search)
						)}
					>
						<img
							src={Logo}
							alt={"Welcome Collegia"}
						/>
					</Grid>
					{
						renderContent(
							props,
							token,
							formValues,
							admins,
							referral,
							stepIndex,
							enterManually,
							directorList,
							addDirector,
							checkConfirm,
							addDirectorConfirmPage,
							checkConfirmAuthorised,
							companyCheckAddress,
							company,
							accordion,
							setFormValues,
							setAdmins,
							setStepIndex,
							setEnterManually,
							setDirectorList,
							setAddDirector,
							setCheckConfirm,
							setAddDirectorConfirmPage,
							setCheckConfirmAuthorised,
							setCompanyCheckAddress,
							setCompany,
							setAccordion,
						)
					}

					<MinimalFooter/>
				</div>
			</Slide>
		</Wrapper>
	);
}


/**
 * @param state
 * @returns {*}
 */
const mapStateToProps = state => {
	const {
		SignUp,
		BillingTypesStore,
		Employer,
		Referral,
	} = state;

	return {
		...SignUp,
		...BillingTypesStore,
		...Employer,
		...Referral,
	}
};

const mapDispatchToProps = dispatch => ({
	checkEmailAddress: (data: any[] = false, proceedStep: any[] = false) => dispatch(verifyEmail(data ?? false, proceedStep ?? false)),
	signUp: (data: any[], token, proceedStep: Function = () => {}, backStep: Function = () => {}) =>
		dispatch(signUp(data ?? false, token ?? false, proceedStep, backStep)),
	getCompanies: (data: any[] = false) => dispatch(getCompanies(data ?? false)),
	getDirectors: (data: any[] = false) => dispatch(getDirectors(data ?? false)),
	setOfficersEmpty: () => dispatch(setOfficersEmpty()),
	getBillingTypesList: () => dispatch(getBillingTypesList()),
	setup: (token: string = "") => dispatch(setup(token ?? "")),
	createDirectDebit: (token: string = "", data: any[] = false, callback: Function = () => {
	}) => dispatch(createDirectDebit(token, data, callback)),
	showError: message => dispatch(SnackBar.error(message)),
	cleanErrorMessage: () => dispatch({
		type: SIGNUP_VERIFY_COMPANY,
		alreadyExistsMessage: null,
		officers: [],
		continue_software_signup: false,
	}),
	findByReferralCode: (code: string = "", type = "Employer") => dispatch(findByReferralCode(code, type)),
});

export default connect(mapStateToProps ?? {}, mapDispatchToProps ?? {})(SignUp);