import Wrapper from "../layout/Logged/Wrapper";
import {useNavigate} from "react-router-dom";
import {getDebitInformation, me} from "../actions/Profile";
import {connect} from "react-redux";
import React, {useState} from "react";
import Loading from "../components/Loading";
import Grid from "@mui/material/Grid2";
import SectionGrid from "../layout/SectionGrid";
import AccountPrimaryHeader from "../components/MyAccount/AccountPrimaryHeader";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Slider from "@mui/material/Slider";
import {cancelDirectDebit, updateRecurringDay} from "../actions/DirectDebit";
import CircularProgress from "@mui/material/CircularProgress";
/**
 * @param profile_data
 * @returns {JSX.Element}
 * @constructor
 */
const DebitInformation = (
	{
		debit_information = {
			ddi_status: false,
			bank_name: "",
			sort_code: "",
			account_number: "",
			recurring_day: 0,
		},
		debit_information_loading = false,
		updateRecurringDay = () => {},
		cancelDirectDebit = () => {},
		confirm_mandate_loading = false,
	}
): JSX.Element => {
	const history = useNavigate();
	const [open, setOpen] = useState(false);
	const [openCancelDialog, setOpenCancelDialog] = useState(false);
	const [newRecurringDate, setNewRecurringDate] = useState(10);
	
	let fields = [
		{
			title: "Ddi status",
			subtitle: debit_information?.ddi_status ? "Active" : "Inactive",
			color: debit_information?.ddi_status ? "green" : "red"
		},
		{
			title: "Account Number",
			subtitle: debit_information?.account_number,
			color: "black"
		},
		{
			title: "Bank Name",
			subtitle: debit_information?.bank_name,
			color: "black"
		},
		{
			title: "Sort Code",
			subtitle: debit_information?.sort_code,
			color: "black"
		},
		{
			title: "Recurring Day",
			subtitle: debit_information?.recurring_day,
			color: "black"
		},
	];

	if (debit_information_loading) {
		return (
			<Loading absolute/>
		);
	}

	return (
        <Wrapper
			history={history}
		>
            <SectionGrid id={"my-account-section-grid"}>
				<Grid
					id={"menu-logged-wrapper"}
					justifyContent={"center"}
					container
					size={12}
				>
					<AccountPrimaryHeader
						title={"Direct Debit Details"}
						history={history}
						location={"/my-account"}
					/>
					<Grid
						className={"collegia-border"}
						container
						size={12}
						padding={"40px"}
						justifyContent={"flex-start"}
					>
						<Grid
							container
							size={12}
						>
							{
								fields.map(({subtitle, title, color}, index) =>
									<Grid key={`field-${index}`} marginBottom={"2%"} size={6}>
										<Typography
											noWrap
											className={"company-info-typog"}
										>
											<b>{title}: </b>
											<Typography
												component={"span"}
												color={color}
											>
												{subtitle}
											</Typography>
										</Typography>
									</Grid>
								)
							}
						</Grid>
						
						<Grid
							container
							size={12}
						>
							<Grid
                                size={{
                                    xs: 3,
                                    sm: 3,
                                    md: 3,
                                    lg: 3,
                                    xl: 3
                                }}
							>
								<Button
									variant={"contained"}
									color={"primary"}
									onClick={() => setOpen(!open)}
									disabled={!debit_information.ddi_status}
								>
									Change Recurring Day
								</Button>
							</Grid>
							
							<Grid
                                size={{
                                    xs: 3,
                                    sm: 3,
                                    md: 3,
                                    lg: 3,
                                    xl: 3
                                }}
							>
								<Button
									variant={"contained"}
									color={"primary"}
									onClick={() => history('/manual-ddi?edit=true')}
									disabled={!debit_information.ddi_status}
								>
									Edit Direct Debit Details
								</Button>
							</Grid>
							
							<Grid
                                size={{
                                    xs: 3,
                                    sm: 3,
                                    md: 3,
                                    lg: 3,
                                    xl: 3
                                }}
							>
								<Button
									variant={"contained"}
									color={"error"}
									onClick={() => setOpenCancelDialog(true)}
									disabled={!debit_information.ddi_status}
								>
									Cancel Direct Debit
								</Button>
							</Grid>
						</Grid>
					</Grid>
					
					<Dialog
						open={openCancelDialog}
						onClose={()=> !confirm_mandate_loading && setOpenCancelDialog(false)}
					>
						<DialogTitle>
							<Typography
								className={"red-text"}
								variant={'h4'}
							>
								Cancel Direct Debit
							</Typography>
						</DialogTitle>
						<DialogContent>
							<Typography>
								When updating your bank account details, we will cancel your current mandate and create a new one,
								under the provided details. Any transactions related to the old mandate, will be automatically cancelled.
								Are you sure that you want to proceed ?
							</Typography>
						</DialogContent>
						<DialogActions>
						<Grid
							container
							size={12}
							justifyContent={"space-between"}
						>
							<Grid
                                container
                                justifyContent={"flex-end"}
                                size={{
                                    xs: 12,
                                    sm: 12,
                                    md: 12,
                                    lg: 12,
                                    xl: 12
                                }}
							>
								<Button
									variant={"contained"}
									color={"error"}
									onClick={() => cancelDirectDebit()}
									disabled={confirm_mandate_loading}
								>
									{confirm_mandate_loading ? <CircularProgress/> : "Continue"}
								</Button>
							</Grid>
						</Grid>
					</DialogActions>
					</Dialog>
					
					<Dialog
						open={open}
					>
						<DialogTitle>
							Change Recurring Day
						</DialogTitle>
						<DialogContent>
							<Grid
								container
								size={12}
							>
								<Slider
									className={"slider-margin-top"}
									value={newRecurringDate}
									onChange={(e) => setNewRecurringDate(e.target.value)}
									min={8}
									max={22}
									valueLabelDisplay={"on"}
									step={1}
								/>
							</Grid>
						</DialogContent>
						<DialogActions>
							<Grid
								container
								size={12}
							>
								<Grid
									container
									justifyContent={"flex-start"}
                                    size={{
                                        xs: 6,
                                        sm: 6,
                                        md: 6,
                                        lg: 6,
                                        xl: 6
                                    }}
								>
									<Button
										variant={"outlined"}
										color={"error"}
										onClick={() => setOpen(false)}
									>
										Cancel
									</Button>
								</Grid>
								
								<Grid
                                    container
                                    justifyContent={"flex-end"}
                                    size={{
                                        xs: 6,
                                        sm: 6,
                                        md: 6,
                                        lg: 6,
                                        xl: 6
                                    }}
								>
									<Button
										variant={"contained"}
										color={"primary"}
										onClick={() => {
											updateRecurringDay({
												"employers": {
													recurring_day: newRecurringDate
												}
											})
											setOpen(false)
										}}
									>
										Save
									</Button>
								</Grid>
							</Grid>
						</DialogActions>
					</Dialog>
				</Grid>
			</SectionGrid>
        </Wrapper>
    );
}

const mapStateToProps = state => {
	const {
		DirectDebit = [],
		Profile = []
	} = state;
	
	return {
		...DirectDebit,
		...Profile
	}
};

const mapDispatchToProps = dispatch => ({
	me: dispatch(me()),
	getDebitInformation: dispatch(getDebitInformation()),
	updateRecurringDay: (data) => dispatch(updateRecurringDay(data)),
	cancelDirectDebit: (data) => dispatch(cancelDirectDebit(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DebitInformation);