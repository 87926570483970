import React from "react";
import SuccessForm from "../../components/SignUp/Success";
import {connect} from "react-redux";
import Logo from "../../images/logo-icon.svg";
import Wrapper from "../../layout/Wrapper";
import Slide from "@mui/material/Slide";
import Grid from "@mui/material/Grid2";
import MinimalFooter from "../../layout/MinimalFooter";
import {me} from "../../actions/Profile";
import {removeDefaultLoggedLayout} from "../../utils/Helpers";
import LoadingPanel from "../../components/LoadingPanel";
import {withRouter} from "../../utils/withRouter";

class Success extends React.Component {
	/**
	 * @param props
	 */
	constructor(props) {
		super(props);
		this.state = {
			profile_data: [],
			token:""
		}

		this.props.me();
	}

	/**
	 * @param prevProps
	 * @param prevState
	 * @param snapshot
	 */
	componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
		if (this.props !== prevProps) {
			removeDefaultLoggedLayout();

			this.setState({
				profile_data : this.props.profile_data
			});
		}
	}

	/**
	 * @returns {JSX.Element|*[]}
	 * @private
	 */
	_renderContent = (): JSX.Element|*[] => {

		const {
			profile_data = [],
		} = this.props

		if (!profile_data.employer) return (
			<LoadingPanel />
		)

		return <SuccessForm
			component={this}
			history={this.props.history}
			redirectCondition={!this.props.isFormLoading}
			callback_url={profile_data?.employer?.activation_code?.callback_url}
			token={this.props?.collegia_token}
		/>

	}

	/**
	 * @returns {JSX.Element}
	 */
	render(): JSX.Element {

		return (
			<Wrapper>
				<Slide direction="up" in={true} mountOnEnter unmountOnExit>
					<div id={"signup-wrapper"}>

						<Grid
							size={12}
							alignItems={"center"}
							alignContent={"center"}
							direction={"column"}
							id={"header"}
							container
							onClick={() => this.props.push("/login")}
						>
							<img
								src={Logo}
								alt={"Welcome Collegia"}
							/>
						</Grid>
						{this._renderContent()}

						<MinimalFooter />
					</div>
				</Slide>
			</Wrapper>
		);
	}
}

/**
 * @param state
 * @returns {*}
 */
const mapStateToProps = state => {
	const {
		Profile = [],
	} = state;

	return {
		...Profile,
	};
};

/**
 * @param dispatch
 * @returns {{getBillingTypesList: (function(): *), checkEmailAddress: (function(*[]=): *), getCompanies: (function(*[]=): *), setup: (function(string=): *), getDirectors: (function(*[]=): *), signUp: (function(*[]=): *)}}
 */
const mapDispatchToProps = dispatch => ({
	me: () => dispatch(me()),
});

export default withRouter(connect(mapStateToProps ?? {}, mapDispatchToProps ?? {})(Success));