import React from "react";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid2";
import Slider from "@mui/material/Slider";
import {renderReferralData} from "../../utils/Helpers";
import {useNavigate} from "react-router-dom";

/**
 * @param paymentDate
 * @param setPaymentDate
 * @param newValue
 */
const sliderOnChange = (paymentDate, setPaymentDate, newValue) => {
	if (newValue !== paymentDate) {
		setPaymentDate(newValue);
	}
};

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const PaymentDateForm = (props = {
	profile_data: {
		employer: {
			is_push_payment: false,
		}
	}
}): JSX.Element => {
	const push = useNavigate();
	let nextStep = 1;
	if (props.profile_data.employer.is_push_payment) {
		nextStep++;
	}

	return (
        <Grid
            id={props.isSetup ? "signup-container" : "activeaccount-container"}
            alignItems={"center"}
            alignContent={"center"}
            direction={"column"}
            spacing={0}
            container
            size={{
                lg: 12,
                xs: 12,
                md: 12,
                sm: 12
            }}
        >
            <form
				noValidate
				autoComplete="off"
			>
				{renderReferralData(props?.referral ?? {})}
				<FormControl
					className={props.isSetup ? "signup-form-control-2 first-title" : "activeaccount-form-control-2 first-title"}
					fullWidth
				>
					<Typography align={"center"}>
						Set your contribution collection date
					</Typography>
				</FormControl>
				<FormControl
					className={props.isSetup ? "signup-form-control-2 second-title" : "activeaccount-form-control-2 second-title"}
					fullWidth
				>
					<Typography align={"center"}>
						In which day of the month should we collect the pension contributions from your account?
					</Typography>
				</FormControl>

				<Slider
					className={"payment-date"}
					value={props?.paymentDate ?? 10}
					min={8}
					max={22}
					valueLabelDisplay="on"
					step={1}
					onChange={(event, newValue) => {
						if (typeof props?.setPaymentDate === "function") {
							sliderOnChange(props?.paymentDate, props?.setPaymentDate, newValue);
						} else {
							props?.component?.setState({
								paymentDate: newValue,
							});
						}
					}}
				/>

				<FormControl
					className={props.isSetup ? "signup-form-control-2 " : "activeaccount-form-control-2 "}
					fullWidth
				>
					<small style={{textAlign: "center"}}>
						Even if your pay period is weekly or fortnightly, we only collect contributions once a month.
					</small>
				</FormControl>

				<FormControl
					className={props.isSetup ? "signup-form-control" : "activeaccount-form-control"}
					fullWidth
				>
					<Button
						variant="contained"
						color="primary"
						onClick={() => {
							if (typeof props.updateContributionDate === "function") {
								props.updateContributionDate(
									{
										employers: {
											employer_id: props?.profile_data?.employer?.id,
											contribution_date: props?.paymentDate,
										}
									}
								);
								props?.setStepIndex(props?.activation_step + nextStep);
							} else {
								props?.proceedStep();
							}
						}}
					>
						Continue
					</Button>
				</FormControl>

				<FormControl
					className={"signup-form-control-2 forgot-password"}
					fullWidth
				>
						<span
							className={"back-span"}
							onClick={() => push("/main-page")}
							style={{marginTop: "10px"}}
						>
							Back
						</span>
				</FormControl>

			</form>
            {
				!props.profile_data.employer.is_push_payment && (
					<Grid
                        id={"direct-debit-container"}
                        alignItems={"flex-start"}
                        alignContent={"flex-start"}
                        direction={"column"}
                        spacing={0}
                        container
                        marginBottom={"7rem"}
                        size={{
                            lg: 12,
                            xs: 12,
                            md: 12,
                            sm: 12
                        }}
					>
						<form
							noValidate
							autoComplete="off"
						>
							<FormControl
								className={props.isSetup ? "signup-form-control" : "activeaccount-form-control"}
								fullWidth
							>
								<small>
									<strong>Important:</strong>
									<br/>
									<br/>
									<strong>
										Collegia only collects contributions for employees whose opt out period have lapsed for
										more than 3 days.
										This means that the earliest we collect pension contributions for new employees is 33
										days after enrolment.
										More often, we will collect contributions for newly enrolled employees in the following
										month.
									</strong>
								</small>
							</FormControl>
						</form>
					</Grid>
				)
			}
            {
				props.profile_data.employer.is_push_payment && (
					<Grid
                        id={"direct-debit-container"}
                        alignItems={"flex-start"}
                        alignContent={"flex-start"}
                        direction={"column"}
                        spacing={0}
                        container
                        marginBottom={"7rem"}
                        size={{
                            lg: 12,
                            xs: 12,
                            md: 12,
                            sm: 12
                        }}
					>
						<form
							noValidate
							autoComplete="off"
						>
							<FormControl
								className={props.isSetup ? "signup-form-control" : "activeaccount-form-control"}
								fullWidth
							>
								<small>
									<strong>About push payment:</strong>
									<br/>
									<br/>
									<strong>
										Collegia only collects contributions for employees whose opt out period have lapsed for
										more than 3 days.
										This means that the earliest we collect pension contributions for new employees is 33
										days after enrolment.
										More often, we will collect contributions for newly enrolled employees in the following
										month.

										All the push payments should be informed to Collegia once is submitted in payslip period
										to <a href={"mailto:hello@collegia.co.uk"}>hello@collegia.co.uk</a> and once it's confirmed
										all the employees money will be processed immediately for next investment flow.
									</strong>
								</small>
							</FormControl>
						</form>
					</Grid>
				)
			}
        </Grid>
    );
};

/**
 * @stateless
 * @param props
 * @returns {JSX.Element}
 */
export default PaymentDateForm;