import React from 'react';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import Grid from '@mui/material/Grid2';
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import {mountQueryStringsUrls} from "../../utils/Helpers";
import {useNavigate} from "react-router-dom";

/**
 * @param props
 * @returns {JSX.Element|*[]}
 * @constructor
 */
export default function EmployerCard(props): JSX.Element | *[] {
	const push = useNavigate();

	if (props) {
		return (
			<Card
				id={"employer-card-container"}
				onClick={() => push(
					mountQueryStringsUrls("/sign-up", window.location.search)
				)}
				className={"card-size-equal"}
			>
				<CardHeader
					avatar={
						<Typography gutterBottom className={"MuiCardHeader-avatar"}/>
					}
					title={"For Employers"}
					subheader={"Sign up as an employer"}
				/>
				<CardContent className={"MuiCard-content"}>
					<Typography gutterBottom>
						Setting up your new Auto Enrolment scheme with Collegia takes only a couple of minutes.
					</Typography>
				</CardContent>
				<CardActions>
					<Grid
						size={6}
						container
					>
						<Grid
							size={6}
							alignContent={"flex-start"}
							direction={"column"}
							item
						>
							<Button className={"MuiCardHeader-button adjust-height-between-buttons"} >SIGN UP</Button>
						</Grid>

					</Grid>
				</CardActions>
			</Card>
		);
	}

	return [];
}
