import Grid from "@mui/material/Grid2";
import {connect} from "react-redux";
import {PensionPayment} from "./PensionPayment";

const PaymentPanel = (props = {
	next_contributions: [],
	past_contributions: [],
	outstanding_amount: "",
}): JSX.Element => {
	return (
		<Grid
			container
			size={12}
			justifyContent={"space-evenly"}
		>
			<PensionPayment
				size={4}
				contributions={props?.next_contributions ?? []}
			>
				Next Pension Payment
			</PensionPayment>

			<PensionPayment
				size={4}
				contributions={props?.past_contributions ?? []}
			>
				Last Pension Payment
			</PensionPayment>
			<PensionPayment
				size={4}
				contributions={props.outstanding_amount}
				is_outstanding={"true"}
			>
				Outstanding Payment
			</PensionPayment>
		</Grid>
	);
}

const mapStateToProps = (state = {
	PAPDIS: [],
}) => {
	const {
		PAPDIS = []
	} = state;

	return {
		...PAPDIS,
	}
};

export default connect(mapStateToProps, ({}))(PaymentPanel);