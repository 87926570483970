import React from "react";
import Grid from "@mui/material/Grid2";
import Link from "@mui/material/Link";
import logo from "../../images/logo-icon.svg";

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Footer = (props = {
	className: "",
	includeLogo: false,
}): JSX.Element => (
	<Grid
        id={"footer-logged"}
        direction={"column"}
        alignItems={"center"}
        alignContent={"center"}
        container
        className={props?.className || ""}
        size={{
            xs: 12,
            lg: 12,
            md: 12
        }}
	>
		{props?.includeLogo ?
			<Grid
				justify={"center"}
				alignItems={"center"}
                size={{
                    xs: 12,
                    md: 12,
                    sm: 12,
                    lg: 12
                }}
			>
				<img
					src={logo}
					alt={"Collegia logo."}
					className={"default-collegia-logo"}
				/>
			</Grid> : null
		}
		<Grid
			alignItems={"center"}
			justifyContent={"center"}
            size={{
                xs: 12,
                md: 12,
                sm: 12,
                lg: 12
            }}
			container
		>
			<Link href={"https://www.collegia.co.uk"} target={"_blank"}>
				<span>© {new Date().getFullYear()} Collegia Partners Limited. All rights reserved.</span>
			</Link>
		</Grid>
	</Grid>
);

export default Footer;