import Grid from "@mui/material/Grid2";
import HelpOutline from "@mui/icons-material/HelpOutline";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import GenericModal from "./GenericModal";
import {useState} from "react";
import {connect} from "react-redux";
import {toggleMainPageTab} from "../actions/Layout/Menu";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const PrimaryHeader = (props = {
	backIcon: false,
	helpIcon: false,
	modalTitle: "",
	modalBody: {},
	changeInfo: {},
	backPage: "",
	downloadButton: false,
	downloadFunction: () => {},
}): JSX.Element => {
	const [show, setShow] = useState(true);
	const [isModalVisible, setModalVisible] = useState(false);

	return (
        <Grid
			className={"primary-header"}
			alignItems={"center"}
			justifyContent={"space-evenly"}
			container
			size={12}
		>
            <Grid
                justifyContent={"center"}
                alignItems={"center"}
                container
                size={{
                    xs: 1,
                    sm: 1,
                    lg: 1,
                    md: 1
                }}
            >
				{
					props?.changeInfo ?
						<ArrowBackIos
							cursor={"pointer"}
							onClick={() =>
								props.backPage ?
									window.location.href = props?.backPage
									:
									window.location.href = "/"
							}
						/>
						:
						props?.backIcon ?
							<ArrowBackIos
								cursor={"pointer"}
								onClick={() => props?.toggleTab(0)}
							/>
							: ""
				}
			</Grid>
            <Grid
                justifyContent={"center"}
                alignItems={"center"}
                container
                size={{
                    xs: 10,
                    sm: 10,
                    lg: 10,
                    md: 10
                }}
            >
				{props.children}
			</Grid>
            {
				isModalVisible &&
				<GenericModal
					show={show}
					setShow={setShow}
					setModalVisible={setModalVisible}
					title={props?.helpModal?.title}
					body={props?.helpModal?.body()}
				/>
			}
            <Grid
                justifyContent={"center"}
                alignItems={"center"}
                container
                size={{
                    xs: 1,
                    sm: 1,
                    lg: 1,
                    md: 1
                }}
            >
				{
					props?.downloadButton ? (
						<Grid
							container
                            size={{
                                xs: 12,
                                sm: 12,
                                md: 6,
                                lg: 6,
                                xl: 6
                            }}
						>
							<CloudDownloadIcon
								cursor={"pointer"}
								onClick={() => {
									props?.downloadFunction();
									setModalVisible(false);
								}}
							/>
						</Grid>
					) : ""
				}
				<Grid
					container
                    size={{
                        xs: 12,
                        sm: 12,
                        md: !props?.downloadButton ? 12 : 6,
                        lg: !props?.downloadButton ? 12 : 6,
                        xl: !props?.downloadButton ? 12 : 6
                    }}
				>
					{
						props?.helpIcon ?
							<HelpOutline
								cursor={"pointer"}
								onClick={() => setModalVisible(true)}
							/>
							:
							""
					}
				</Grid>
			</Grid>
        </Grid>
    );
}

const mapStateToProps = state => {
	const {
		Menu,
	} = state;
	
	return {
		...Menu
	}
};

const mapDispatchToProps = dispatch => ({
	toggleTab: (selectedTab: number = 0) => dispatch(toggleMainPageTab(selectedTab ?? 0))
});

export default connect(mapStateToProps, mapDispatchToProps)(PrimaryHeader);