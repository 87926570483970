import React from "react";
import Grid from "@mui/material/Grid2";
import Tooltip from "@mui/material/Tooltip";

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function OverviewCard(props): JSX.Element {

	const headers = {
		line1: {
			h1: "Scheme Name",
			h2: "Scheme Address",
			h3: "Type of Scheme",
		},
		line2: {
			h1: "Employer Pension Scheme Reference (EPSR)",
			h2: "Pension Scheme Tax Reference (PSTR)",
			h3: "Employer ID",
		},
		line3: {
			h1: "Pension Scheme Registry (PSR) number",
			h2: "Direct Debit",
		}
	};

	const descriptions = {
		line1: {
			d1: "Collegia",
			d2: "3 West Way, Buxton Court OX2 0JB, United Kingdom",
			d3: "Personal",
		},
		line2: {
			d1: "Col-" + props.profile_data?.employer?.company_number,
			d2: "20004161RJ",
			d3: props.profile_data?.employer?.company_number,
		},
		line3: {
			d1: "12018421",
			d2: props?.profile_data?.employer?.emandate !== null ? "Active" : "Inactive",
		}
	};

	const tooltips = {
		line1: {
			t1: "This is the name of your new workplace pension scheme.",
			t2: "The official address of your new workplace pension scheme.",
			t3: "You have a corporate account with us. Each one of your employees will/has a personal account with Collegia." +
				"For that reason, the type of Scheme is personal.",
		},
		line2: {
			t1: "When filling your declaration of compliance with the The Pensions Regulator," +
				"this is the Employer Pension Scheme Reference you should provide." +
				"It is a unique identifier of your corporate account with us.",
			t2: "Collegia's Pension Scheme Registry Number with HMRC.",
			t3: "This is your ID with us. It is used mainly to identify you in your payroll files. " +
				"We use your Companies House number as your Employer ID. " +
				"Our systems automatically assign you one if your company does not have a Companies House number.",
		},
		line3: {
			t1: "A PSR number is allocated to workplace pension schemes by " +
				"The Pensions Regulator. It starts with 1",
		},
	};

	/**
	 * @param props
	 * @returns {JSX.Element}
	 * @constructor
	 */
	function FormRow(props): JSX.Element {
		return (
            <Grid
				className={"overview-card-row"}
				direction={"row"}
				container
				size={12}
			>
                <Tooltip
					title={props?.tooltips?.t1 || ""}
					placement={props?.placement}
					arrow
				>
					<Grid size={5}>
						<h5>
							{props?.headers?.h1 || ""}
						</h5>

						<h6>
							{props?.descriptions?.d1 || ""}
						</h6>
					</Grid>
				</Tooltip>
                <Tooltip
					title={props?.tooltips?.t2 || ""}
					placement={props?.placement}
					arrow
				>
					<Grid size={5}>
						<h5>
							{props?.headers?.h2 || ""}
						</h5>

						<h6>
							{props?.descriptions?.d2 || ""}
						</h6>
					</Grid>
				</Tooltip>
                <Tooltip
					title={props?.tooltips?.t3 || ""}
					placement={props?.placement}
					arrow
				>
					<Grid size={2}>
						<h5>
							{props?.headers?.h3 || ""}
						</h5>

						<h6>
							{props?.descriptions?.d3 || ""}
						</h6>
					</Grid>
				</Tooltip>
            </Grid>
        );
	}

	return (
        <div>
            <Grid className={'collegia-border collegia-container-wrapper'} id={'overview-card-content'}>
				<Grid container size={12}>
					<FormRow
						headers={headers?.line1}
						descriptions={descriptions?.line1}
						tooltips={tooltips?.line1}
						placement={"top"}
					/>
				</Grid>
				<Grid container size={12}>
					<FormRow
						headers={headers?.line2}
						descriptions={descriptions?.line2}
						tooltips={tooltips?.line2}
						placement={"bottom"}
					/>
				</Grid>
				<Grid container size={12}>
					<FormRow
						headers={headers?.line3}
						descriptions={descriptions?.line3}
						tooltips={tooltips?.line3}
						placement={"bottom"}
					/>
				</Grid>
			</Grid>
        </div>
    );
}
