import Grid from "@mui/material/Grid2";


const TableCellItem = (props = {
	title: "",
	subtitle: "",
}): JSX.Element => {
	return (
		<Grid
			container
			{...props}
			direction={"column"}
		>
			<Grid
				className={"table-cell-item-title"}
			>
				{props?.title}
			</Grid>

			<Grid
				className={"table-cell-item-subtitle"}
			>
				{props?.subtitle}
			</Grid>
		</Grid>
	)
};

export default TableCellItem;