import Grid from "@mui/material/Grid2";
import moment from "moment";

export const PensionPayment = (props = {
	contributions: [],
	is_outstanding: false,
}) => {
	const formatter = new Intl.NumberFormat('en-GB', {
		style: 'decimal',
		currency: 'GBP', // Use GBP for pounds
	});
	return (
		<Grid
			className={"collegia-border pension-payment"}
			container
			justifyContent={"center"}
			alignItems={"center"}
			{...props}
		>
			<Grid
				className={"pension-payment-title"}
				container
				justifyContent={"center"}
				size={12}
			>
				{props?.children}
			</Grid>

			{!props.is_outstanding && <Grid
				className={"pension-payment-value"}
				container
				justifyContent={"center"}
				size={12}
			>
				{
					props?.contributions[0]?.total_contributions
						? `£ ${formatter.format(props?.contributions[0]?.total_contributions)}`
						: "£ 0.00"}
			</Grid>}

			{props.is_outstanding && <Grid
				className={"pension-payment-value"}
				container
				justifyContent={"center"}
				size={12}
			>
				{
					props?.contributions !== "" ?
					`£ ${formatter.format(parseFloat(props?.contributions).toFixed(2))}` : "£ 0.00"}
			</Grid>}

			{!props.is_outstanding && <Grid
				className={"pension-payment-date"}
				container
				justifyContent={"center"}
				size={12}
			>
				{props?.contributions[0]?.payment_date ?
					moment(props?.contributions[0]?.payment_date).format("DD/MM/YYYY") :
					"N/a"}
			</Grid>}
		</Grid>
	);
}