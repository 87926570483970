import React from "react";
import Grid from "@mui/material/Grid2";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

/**
 * @param proxps
 * @returns {JSX.Element}
 * @constructor
 */
const DialogComponent = ({
	id= 0,
	handleDialog= false,
	file = null,
	setHandleDialog = () => {},
	sendFile = () => {},
 	taxPeriod = false,
 	frequencyCode = false
}): JSX.Element => {
	return (
		<Grid>
			<Dialog
				open={handleDialog}
				onClose={() => setHandleDialog(false)}
				aria-labelledby={"alert-dialog-title"}
				aria-describedby={"alert-dialog-description"}
			>
				<DialogTitle>
					{"Confirmation"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Did you check all file lines and information before proceeding? <br/>
						Please keep in mind that your file should be exactly as the model provided by Collegia.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => setHandleDialog(false)}
					>
						No
					</Button>
					<Button
						onClick={() => sendFile(file, id, frequencyCode, taxPeriod)} autoFocus
					>
						Yes
					</Button>
				</DialogActions>
			</Dialog>
		</Grid>
	);
}

export default DialogComponent