import React, {useEffect, useState} from "react";
import {connect, useDispatch} from "react-redux";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import {disconnectEmployee, downloadEmployeesReport, getEmployeesList} from "../../actions/Employer";
import {getEmployeeContributions} from "../../actions/Contributions";
import MUIDataTable from "mui-datatables";
import TableCellItem from "../../components/TableCellItem";
import Grid from "@mui/material/Grid2";
import DescriptionIcon from "@mui/icons-material/DescriptionOutlined";
import {toCurrency} from "../../utils/Helpers";
import DisconnectModal from "../../components/Employees/DisconnectModal";
import {Accordion, AccordionDetails} from "@mui/material";
import Typography from "@mui/material/Typography";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SectionGrid from "../../layout/SectionGrid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import * as SnackBarActions from "../../actions/SnackBar/types";
import ContactMailIcon from '@mui/icons-material/ContactMail';
import {useNavigate} from "react-router-dom";
/**
 * @param employees
 * @param profile_data
 * @param getEmployeesList
 * @param getEmployeeContributions
 * @param disconnectEmployee
 * @param downloadEmployeesReport
 * @returns {JSX.Element}
 * @constructor
 */
const EmployeesTable: JSX.Element = (
    {
        employees = [],
        profile_data = {},
        getEmployeesList = () => {},
        getEmployeeContributions = () => {},
        disconnectEmployee = () => {},
        downloadEmployeesReport = () => false,
    }
): JSX.Element => {
    useEffect(getEmployeesList, [
        getEmployeesList
    ]);

    const [show, setShow] = useState(true);
    const [isModalVisible, setModalVisible] = useState(false);
    const [isConfirmModalVisible, setConfirmModalVisible] = useState(false);
    const [rowInfo, setRowInfo] = useState("");
    const [exitReasonCode, setExitReasonCode] = useState("");
    const dispatch = useDispatch();
    const push = useNavigate();

    const {
        isLoading = false,
    } = employees;

    const Loading: JSX.Element = () => (
        <Box justifyContent="center" display="flex" padding={4}>
            <CircularProgress/>
        </Box>
    );

    const renderName = (value,tableMeta) => (
        <TableCellItem
            title={tableMeta.rowData[1]}
        />
    );

    const renderNIN = (value,tableMeta) => (
        <TableCellItem
            subtitle={tableMeta.rowData[2]}
        />
    );

    const renderContributions = (value,tableMeta) => (
        <Grid
            container
            size={12}
        >
            <TableCellItem
                title={`£ ${toCurrency(tableMeta.rowData[3])}`}
                subtitle={"Employee"}
                size={{
                    xs: 12,
                    sm: 12,
                    md: 12,
                    xl: 6,
                    lg: 6,
                }}
            />

            <TableCellItem
                title={`£ ${toCurrency(tableMeta.rowData[4])}`}
                subtitle={"Employer"}
                size={{
                    xs: 12,
                    sm: 12,
                    md: 12,
                    xl: 6,
                    lg: 6,
                }}
            />
        </Grid>
    );

    const renderStatus = (value,tableMeta) => {
        let className = getEmployeeStatusClass(tableMeta.rowData[8]);

        let status = tableMeta?.rowData[8]?.split(" ");

        if (status?.length > 1) {
            return (
                <div>
					<span className={className}>
						{status[0]}
					</span>
                    <span className={className}>
						{status[1]}
					</span>
                </div>
            );
        }

        return (
            <Grid>
                {
                    status[0] === "Enrolled" ?
                        <span
                            className={className}
                            onClick={() => {
                                setRowInfo(tableMeta.rowData)
                                setModalVisible(true)
                            }}
                        >
							{status[0]}
						</span>
                        :
                        <span
                            className={className}
                        >
							{status[0]}
						</span>
                }

            </Grid>

        );
    }

    const renderInactivationDate = (value,tableMeta) => (
        <TableCellItem
            subtitle={tableMeta.rowData[10]}
        />
    );

    const renderEnrollmentDate = (value,tableMeta) => (
        <TableCellItem
            subtitle={tableMeta.rowData[9]}
        />
    );

    const renderButton = (value, tableMeta) => {

        return (
            <Grid
                alignItems={"center"}
                direction={"row"}
                container
                size={12}
            >
                <Grid
                    size={6}
                >
                    <DescriptionIcon
                        onClick={() => getEmployeeContributions(tableMeta.rowData[0], "")}
                        sx={{color: "#3C69E4"}}
                    />
                </Grid>
                <Grid
                    size={6}
                >
                    <ContactMailIcon
                        onClick={() => push("/employees-communications/" + tableMeta.rowData[0])}
                        sx={{color: "#3C69E4"}}
                    />
                </Grid>
            </Grid>
        );
    }

    function getEmployeeStatusClass(status) {
        switch (status) {
            case 'Enrolled':
                return 'status-enrolled';
            case 'Disconnected':
                return 'status-disconnected';
            default:
                return 'status-opted-out';
        }
    }

    const columns = [
        {
            name: 'individual_id',
            label: "",
            options: {
                display: 'excluded',
            },
        },
        {

            label: "NAME",
            name: 'name',
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({align: 'center'}),
                customBodyRender: (value, tableMeta) => renderName(value,tableMeta),
            },
        },
        {
            label: 'NATIONAL INSURANCE NUMBER',
            name: 'national_insurance_number',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value, tableMeta) => renderNIN(value,tableMeta),
                setCellProps: () => ({align: 'center'})
            }
        },
        {
            label: 'EMPLOYEE CONTRIBUTIONS',
            name: 'employee_contributions',
            options: {
                filter: true,
                sort: true,
                display: 'excluded'
            }
        },
        {
            label: 'EMPLOYER CONTRIBUTIONS',
            name: 'employer_contributions',
            options: {
                filter: true,
                sort: true,
                display: 'excluded'
            }
        },
        {
            label: 'CONTRIBUTIONS',
            name: 'contributions',
            options: {
                filter: true,
                sort: false,
                download: false,
                customBodyRender: (value, tableMeta) => renderContributions(value,tableMeta),
                setCellProps: () => ({align: 'center'}),
            }
        },
        {
            label: 'EMPLOYEE ID',
            name: 'employee_id',
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value, tableMeta) => value,
                setCellProps: () => ({align: 'center'}),
                setCellHeaderProps: () => ({align: 'center'}),
            }
        },
        {
            label: 'EXTERNAL REFERENCE',
            name: 'employee_id',
            options: {
                filter: true,
                sort: false,
                display: profile_data?.thirdPartySoftwareConnected ? true : "excluded",
                setCellProps: () => ({align: 'center'}),
                setCellHeaderProps: () => ({align: 'center'}),
            }
        },
        {
            label: 'STATUS',
            name: "active",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta) => renderStatus(value,tableMeta),
                setCellProps: () => ({align: 'center'}),
                setCellHeaderProps: () => ({align: 'center'}),
            }
        },
        {
            label: 'ENROLLMENT DATE',
            name: 'enrollment_date',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta) => renderEnrollmentDate(value,tableMeta),
                setCellProps: () => ({align: 'center'}),
                sortCompare: (order) => {
                    return (obj1, obj2) => {
                        const date1 = new Date(obj1?.data.replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3"));
                        const date2 = new Date(obj2?.data.replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3"));
                        if (date1.getTime() === date2.getTime()) {
                            return 0;
                        } else {
                            if (order === 'asc') {
                                return date1.getTime() < date2.getTime() ? -1 : 1;
                            } else if (order === 'desc') {
                                return date1.getTime() > date2.getTime() ? -1 : 1;
                            }
                        }
                    };
                }
            }
        },
        {
            label: 'INACTIVATION DATE',
            name: 'inactivation_date',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta) => renderInactivationDate(value,tableMeta),
                setCellProps: () => ({align: 'center'}),
            }
        },
        {
            label: '',
            name: "DETAILS",
            options: {
                filter: true,
                sort: true,
                display: true,
                customBodyRender: (value, tableMeta) => renderButton(value,tableMeta),
                setCellProps: () => ({align: 'center'}),
            }
        },
    ];

    function handleDisconnect(data) {
        const object = {
            individual_id: data,
            exit_reason_code: exitReasonCode,
            employer_id: profile_data?.employer_id,
        };

        disconnectEmployee(object);
    }

    const customTheme = createTheme({
        components: {
            MuiToolbar: {
                styleOverrides: {
                    root: {
                        display: "flex",
                        flex: "initial",
                        "& > span, & > button": {
                            order: 99,
                        },
                        "& > span:last-child, & > button:last-child": {
                            order: 1,
                        },
                        "& > span:nth-of-type(4), & > button:nth-of-type(4)": {
                            order: 2,
                        },
                    },
                },
            },
        },
    });

    const AddButton = () => (
        <Tooltip disableFocusListener title="Download employee report">
            <IconButton onClick={() => {
                dispatch({
                    type: SnackBarActions.TRIGGER_SNACK_BAR,
                    message: "Starting your download, please do not close the browser.",
                })
                downloadEmployeesReport();
            }}>
                <DescriptionIcon color={"white"}/>
            </IconButton>
        </Tooltip>
    );

    return (
        <div>
            <Accordion id={"overview-table-accordion"} style={{marginBottom: "30px"}}>
                <AccordionSummary
                    expandIcon={
                        <ExpandMoreIcon className={"accordion-arrow-icon"}/>
                    }
                    className={"overview-table-accordion-summary"}
                >
                    <Typography variant={"h5"}>
                        Manage Your Employees
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid
                        className={"tab-panel"}
                    >
                        <SectionGrid>
                            {isLoading ? (
                                <Loading/>
                            ) : (
                                <div>
                                    <ThemeProvider theme={customTheme}>
                                        <MUIDataTable
                                            className={"data-table employees-table"}
                                            columns={columns}
                                            data={employees?.list}
                                            options={{
                                                filter: false,
                                                selectableRowsHeader: false,
                                                selectableRows: "none",
                                                responsive: "simple",
                                                viewColumns: true,
                                                customToolbar: AddButton,
                                                download: false
                                            }}
                                            pagination
                                        />
                                    </ThemeProvider>

                                    {isModalVisible &&
                                        <DisconnectModal
                                            show={show}
                                            setShow={setShow}
                                            setModalVisible={setModalVisible}
                                            setConfirmModalVisible={setConfirmModalVisible}
                                            isConfirmModalVisible={isConfirmModalVisible}
                                            isModalVisible={isModalVisible}
                                            title={"Discard"}
                                            onPressSave={(data) => {
                                                setExitReasonCode(data);
                                            }}
                                        />
                                    }

                                    {isConfirmModalVisible &&
                                        <DisconnectModal
                                            show={show}
                                            setShow={setShow}
                                            setModalVisible={setModalVisible}
                                            setConfirmModalVisible={setConfirmModalVisible}
                                            isConfirmModalVisible={isConfirmModalVisible}
                                            isModalVisible={isModalVisible}
                                            title={"Confirmation"}
                                            onPressDisconnect={(data) => {
                                                handleDisconnect(data);
                                            }}
                                            rowInfo={rowInfo}
                                        />
                                    }
                                </div>
                            )}
                        </SectionGrid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}

export default connect(
    ({Employer, Profile}) => ({
        ...Profile,
        employees: Employer.employees,
    }),
    (dispatch) => ({
        getEmployeesList: (page_index) => dispatch(getEmployeesList(page_index)),
        getEmployeeContributions: (national_insurance_number: string = "", type: string = "payroll") =>
            dispatch(getEmployeeContributions(national_insurance_number, type)),
        disconnectEmployee: (userdata) => dispatch(disconnectEmployee(userdata)),
        downloadEmployeesReport: () => dispatch(downloadEmployeesReport()),
    })
)(EmployeesTable);