import React from "react";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid2";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Tooltip from "@mui/material/Tooltip";


/**
 * @param data
 * @param roles
 * @param updateUserField
 * @param onPressCreate
 * @param open
 * @param handleClose
 * @param profile_data
 * @returns {JSX.Element}
 * @constructor
 */
const UserCreationPanel: React.Component = ({
	data,
	roles = [],
	updateUserField,
	onPressCreate,
	open,
    handleClose,
	profile_data,
}): JSX.Element => {
	const {id = null, surname, forename, middle_name, email, role_id} = data

	const setSurname = value => updateUserField('surname', value);
	const setForename = value => updateUserField('forename', value);
	const setmiddle_name = value => updateUserField('middle_name', value);
	const setEmail = value => updateUserField('email', value);
	const setRoleID = value => updateUserField('role_id', value);

	const inputFields = [
		{id: 'forename', required: true, value: forename, updater: setForename, label: 'Forename'},
		{id: 'middle_name', required: false, value: middle_name, updater: setmiddle_name, label: 'Middle Name'},
		{id: 'surname', required: true, value: surname, updater: setSurname, label: 'Surname'},
		{id: 'email', required: true, value: email, updater: setEmail, label: 'E-Mail'},
	]

	const tooltip =
		<Typography>
			<strong>Standard:</strong> A standard user can enrol new employees,
			upload contributions, update company details and change direct debit details.
			<br />
			<strong>Admin:</strong> An admin user can do everything
			that the standard user can and also add new users,
			delete users, allow an advisor access to the employer account and cancel the account.
		</Typography>;

	return (
        <div>
            <Modal
				open={open}
				onClose={handleClose}
			>
				<Box className={"create-user-modal"}>
					<Grid
                        justifyContent={"space-around"}
                        container
                        size={{
                            xs: 12,
                            sm: 12,
                            lg: 12,
                            md: 12
                        }}>
						<Grid
                            className={"create-user-form-fields"}
                            size={{
                                lg: 12,
                                md: 12,
                                sm: 12
                            }}>
							<Typography
								className={"create-user-form-title"}
								variant={"h5"}
							>
								{id ? 'Update user' : 'Create a new user'}
							</Typography>
						</Grid>
						<Grid
                            size={{
                                lg: 12,
                                md: 12,
                                sm: 12
                            }}>
							{
								inputFields.map(({id_field, updater, value, label}, index) => (
									<Grid
                                        className={"create-user-form-fields"}
                                        key={`field-${index}`}
                                        size={{
                                            lg: 12,
                                            md: 12,
                                            sm: 12
                                        }}>
										<FormControl fullWidth>
											<TextField
												fullWidth
												type={id_field}
												onChange={(event) => updater(event.target.value)}
												label={label}
												variant="outlined"
												disabled={id ? value === email : false}
												name={id_field}
												defaultValue={value}
												id={id_field}
											/>
										</FormControl>
									</Grid>
								))
							}
							<Grid
                                className={"create-user-form-role-field"}
                                justifyContent={"space-between"}
                                container
                                size={{
                                    lg: 12,
                                    md: 12,
                                    sm: 12
                                }}>
								<Grid
                                    size={{
                                        lg: 11,
                                        md: 11,
                                        sm: 11
                                    }}>
									<FormControl fullWidth variant="outlined" style={{minWidth:"16px"}}>
										<InputLabel htmlFor="role-select">Role</InputLabel>
										<Select
											native
											onChange={event => setRoleID(event.target.value)}
											label="Role"
											defaultValue={data.role_id}
											inputProps={{
												name: 'role',
												id: 'role-select',
											}}
										>
											<option aria-label="None" value="" />
											{
												profile_data?.role?.role?.name === "Admin" ?
													roles.filter((v) => v.name !== "Advisor")
														.map(({name, id}, index) => {
														return (
															<option key={`role-option-${index}`} value={id}>
																{name}
															</option>
														)
													})
													: roles.filter((v) => v.name === "Standard")
														.map(({ name, id }, index) => {
															return (
																<option key={`role-option-${index}`} value={id}>
																	{name}
																</option>
															);
														})
											}
										</Select>
									</FormControl>
								</Grid>
								<Grid
                                    size={{
                                        lg: 1,
                                        md: 1,
                                        sm: 1
                                    }}>
									<Tooltip title={tooltip}>
										<HelpOutlineIcon className={"create-user-form-tooltip-icon"}/>
									</Tooltip>
								</Grid>
							</Grid>
						</Grid>

						<Grid
							className={"create-user-form-fields"}
							justifyContent={"space-between"}
							direction={"row"}
							spacing={2}
							container
						>
							<Grid
                                size={{
                                    lg: 6,
                                    md: 6,
                                    sm: 6
                                }}>
								<Button
									fullWidth
									variant={"outlined"}
									color={"primary"}
									onClick={handleClose}
								>
									Cancel
								</Button>
							</Grid>
							<Grid
                                size={{
                                    lg: 6,
                                    md: 6,
                                    sm: 6
                                }}>
								<Button
									id={"create-user-form-update-button"}
									fullWidth
									variant={"contained"}
									disabled={Boolean(inputFields
										.filter(f => f.required)
										.map(f => Boolean(f.value))
										.includes(false) || (!role_id))
									}
									onClick={() => {
										onPressCreate(data);
										handleClose();
									}}
								>
									{id ? 'Update User' : 'Create User'}
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Box>
			</Modal>
        </div>
    );
};

export default UserCreationPanel;