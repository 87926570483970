import React, {useState} from "react";

import Fade from "@mui/material/Fade";
import Grid from "@mui/material/Grid2";
import TableContainer from "@mui/material/TableContainer";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import RenderEmployeeTabModal from "./RenderEmployeeTabModal";
import RenderContributionsTabModal from "./RenderContributionsTabModal";
import {handleDateFormat} from "../../utils/Helpers";
import EmployeeDetails from "./employeeDetails";
import DescriptionIcon from "@mui/icons-material/DescriptionOutlined";

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const NewContributionsModal = ({
	open = false,
	employee_contributions = [],
	close = () => {},
	isContribution = false,
	isPayroll = false,
	downloadEmployeeAllContributionOrPerPeriod = () => {}
}): JSX.Element => {

	const [menuDate, setMenuDate] =
		useState(0);
	const [indexModal, setIndexModal] =
		useState(0);

	function handleEmployeeModal(event) {
		setMenuDate(event.target.value)
		setIndexModal(event.target.value)
	}

	return (
		<Modal
			open={open ?? false}
			aria-labelledby="simple-modal-title"
			aria-describedby="simple-modal-description"
			closeAfterTransition
			id={"employees-contributions-modal"}
			onClose={close}
		>
			<Fade in={open ?? false}>
				<Grid
					size={12}
					container
				>
					<TableContainer
						className={"table-container"}
						component={Grid}
					>
						{
							(isContribution) && (
								<RenderContributionsTabModal
									employee_contributions={employee_contributions}
								/>
							)
						}
					</TableContainer>

					<TableContainer
						className={"table-container-employee table-container-employee-margin"}
						component={Grid}
					>
						{
							(employee_contributions?.length > 0 && isContribution === false
								&& isPayroll === false) && (
								<Grid>
									<Grid
										className={"collegia-container-wrapper"}
										container
										size={12}
									>
										<Grid
											size={6}
										>
											<h3>
												Employee Details
											</h3>
										</Grid>
									</Grid>
									<Divider orientation={"horizontal"}/>
									<EmployeeDetails
										employeeDetails={employee_contributions[indexModal]?.individual}
									/>
									<Divider orientation={"horizontal"}/>
									<Grid
										className={"collegia-container-wrapper"}
										container
										size={12}
										alignItems={"center"}
									>
										<Grid
											size={6}
										>
											<h3>
												Payroll Records
											</h3>
										</Grid>
										<Grid
											size={6}
										>
											<FormControl
												variant={"outlined"}
											>
												<Grid
													container
													size={12}
													direction={"row"}
													alignItems={"center"}
													alignContent={"center"}
												>
												<Grid>
													<TextField
														style={{width: "300px"}}
														onChange={event => handleEmployeeModal(event)}
														size={"small"}
														label={"Payroll Start Date / Payroll End Date"}
														variant={"outlined"}
														defaultValue={''}
														select
														value={menuDate}
													>
														{employee_contributions?.map((contribution,index = {
															document_id: 0,
														}) => (
															<MenuItem key={index} value={index}>
																{handleDateFormat(contribution["payroll_start_date"])
																	+" / "+
																	handleDateFormat(contribution["payroll_end_date"])}
															</MenuItem>
														))}
													</TextField>
												</Grid>
												<Grid>
													<Button
														onClick={() => downloadEmployeeAllContributionOrPerPeriod({
															individuals: {
																individual_id: employee_contributions[indexModal]?.individual?.id
															}
														}, employee_contributions[indexModal]?.individual?.forename + "_" +
															employee_contributions[indexModal]?.individual?.surname + "_" +
															employee_contributions[indexModal]?.individual?.national_insurance_number
														)}
													>
														<DescriptionIcon className={"icon-color"}/>
													</Button>
												</Grid>
												</Grid>
											</FormControl>
										</Grid>
									</Grid>
									<RenderEmployeeTabModal
										employee_contributions={employee_contributions[indexModal]}
									/>
									<Divider orientation={"horizontal"}/>

									<Grid
										className={"table-container-employee"}
										size={12}
										direction={"column"}
										alignContent={"flex-end"}
										alignItems={"flex-end"}
										container
									>
										<Button
											onClick={() => close()}
											style={{color:"#3C69E4"}}
										>
											OK
										</Button>
									</Grid>
								</Grid>
							)
						}
					</TableContainer>
				</Grid>
			</Fade>
		</Modal>
	);
};

export default NewContributionsModal;