import React from 'react'

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';

import {toCurrency, toLocaleDate, toLocaleTime} from "../../../utils/Helpers";
import Loading from "../../Loading";
import TableCellItem from "../../TableCellItem";
import Grid from "@mui/material/Grid2";

/**
 * @param error_data
 * @returns {boolean}
 */
const hasError = (error_data = []) => (
	error_data
		?.length > 0
);

/**
 * @param number_of_employees
 * @param total_employer_contributions
 * @param total_employee_contributions
 * @param upload_date
 * @param status
 * @param error_data
 * @returns {JSX.Element}
 * @constructor
 */
const ApiPastUploadRow: JSX.Element = (
	{
		number_of_employees = 0,
		total_employer_contributions = 0,
		total_employee_contributions = 0,
		upload_date = null,
		name = "",
		error_data = null,
		tax_period = null,
		tax_year = null,
	}
): JSX.Element => {
	const error = hasError(error_data);
	const URL = process.env.REACT_APP_EMPLOYER_DEFAULT_APP_LINK;

	return (
		<TableRow
			className={"employee-table-row"}
			onClick={error ? () => false : () => window.open(URL + `/software-papdis-details?taxPeriod=${tax_period}&taxYear=${tax_year}`)}
		>
			<TableCell align={"center"}>
				<TableCellItem
					title={toLocaleDate(upload_date)}
					subtitle={toLocaleTime(upload_date)}
					alignItems={"flex-start"}
				/>
			</TableCell>
			
			<TableCell align={"center"}>
				<TableCellItem
					title={name}
					alignItems={"center"}
				/>
			</TableCell>
			
			<TableCell align={"center"}>
				<TableCellItem
					title={tax_year}
					alignItems={"center"}
				/>
			</TableCell>
			
			<TableCell align={"center"}>
				<TableCellItem
					title={tax_period}
					alignItems={"center"}
				/>
			</TableCell>
			
			<TableCell align={"center"}>
				<TableCellItem
					title={number_of_employees}
					subtitle={number_of_employees !== 1 ? "Employees" : "Employee"}
					alignItems={"center"}
				/>
			</TableCell>

			<TableCell align={"center"}>
				<Grid
					container
					size={12}
				>
					<TableCellItem
						title={`£ ${toCurrency(total_employee_contributions)}`}
						subtitle={"Employee"}
						size={6}
					/>

					<TableCellItem
						title={`£ ${toCurrency(total_employer_contributions)}`}
						subtitle={"Employer"}
						size={6}
					/>
				</Grid>
			</TableCell>

			<TableCell align={"center"}>
			</TableCell>
		</TableRow>
	);
};

/**
 * @param uploads
 * @param triggerModal
 * @param loading
 * @param deletePapdisPastUpload
 * @param profile_data
 * @param percentage
 * @param papdis_file_upload_id
 * @param label
 * @returns {JSX.Element}
 * @constructor
 */
const ApiPastUploadTable: React.Component = (
	{
		uploads = {
			year_value: 0
		},
		triggerModal = () => {},
		loading = false,
		deletePapdisPastUpload = () => {},
		profile_data = [],
		papdis_file_upload_id = null,
		label = "",
	}: {
		uploads: Array<any>,
		triggerModal: Function,
		loading: Boolean,
		deletePapdisPastUpload: Function,
		profile_data: Array<any>
	}
): JSX.Element => {
	const alignments = [
		"left",
		"center",
		"center",
		"center",
		"center",
	];

	if (loading === true) {
		return (
			<Box justifyContent="center" display="flex" padding={4}>
				<Loading absolute inPlace centered/>
			</Box>
		);
	}

	return (
		<Table>
			<TableHead
				className={"table-head"}
			>
				<TableRow>
					{[
						"Upload Date",
						"Software",
						"Tax Year",
						"Tax Period",
						"Employees",
						"Total Contributions",
						""].map((label, index) => (
						<TableCell
							className={"number-field"}
							key={`th-${label}`}
							align={alignments[index]}
						>
							{label}
						</TableCell>
					))}
				</TableRow>
			</TableHead>
			<TableBody>
				{
					uploads.map((upload, index) => (
						<ApiPastUploadRow
							key={`tr-papdis-${index}`}
							{...upload}
							onClickHelp={triggerModal}
							deletePapdisPastUpload={deletePapdisPastUpload}
							profile_data={profile_data}
							tax_year={upload.year_value}
							tax_period={upload.tax_period}
							papdis_file_upload_id={papdis_file_upload_id}
							percentage={upload.progress}
							label={label}
						/>
					))
				}
			</TableBody>
		</Table>
	);
};

export default ApiPastUploadTable;