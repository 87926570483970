import React from 'react'

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import {toCurrency, toLocaleDate, toLocaleTime} from "../../../utils/Helpers";
import Loading from "../../Loading";
import TableCellItem from "../../TableCellItem";
import Grid from "@mui/material/Grid2";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import DeleteIcon from '@mui/icons-material/Delete';
import UploadProgress from "../../PAPDIS/UploadProgress";
import DescriptionIcon from '@mui/icons-material/Description';
import RemoveRedEye from "@mui/icons-material/RemoveRedEye";

/**
 * @param error_data
 * @returns {boolean}
 */
const hasError = (error_data = []) => (
	error_data
		?.length > 0
);

/**
 * @param number_of_employees
 * @param total_employer_contributions
 * @param total_employee_contributions
 * @param upload_date
 * @param status
 * @param error_data
 * @param onClickHelp
 * @param classes
 * @param id
 * @returns {JSX.Element}
 * @constructor
 */
const PastUploadRow: JSX.Element = (
	{
		number_of_employees = 0,
		total_employer_contributions = 0,
		total_employee_contributions = 0,
		upload_date = null,
		filename = "",
		status = 0,
		error_data = null,
		onClickHelp = () => {
		},
		id,
		deletePapdisPastUpload = () => {
		},
		profile_data = [],
		papdis_file_upload_id = null,
		percentage = "",
		label = "",
		downloadPapdisPastUpload = () => {
		},
	}
): JSX.Element => {
	const error = hasError(error_data);
	const URL = process.env.REACT_APP_EMPLOYER_DEFAULT_APP_LINK;

	return (
		<TableRow
			className={"employee-table-row"}
		>
			<TableCell align={"center"}>
				<TableCellItem
					title={toLocaleDate(upload_date)}
					subtitle={toLocaleTime(upload_date)}
					alignItems={"flex-start"}
				/>
			</TableCell>
			
			<TableCell align={"center"}>
				<TableCellItem
					title={filename}
					alignItems={"center"}
				/>
			</TableCell>
			
			<TableCell align={"center"}>
				<TableCellItem
					title={number_of_employees}
					subtitle={number_of_employees !== 1 ? "Employees" : "Employee"}
					alignItems={"center"}
				/>
			</TableCell>

			<TableCell align={"center"}>
				<Grid
					container
					size={12}
				>
					<TableCellItem
						title={`£ ${toCurrency(total_employee_contributions)}`}
						subtitle={"Employee"}
						size={6}
					/>

					<TableCellItem
						title={`£ ${toCurrency(total_employer_contributions)}`}
						subtitle={"Employer"}
						size={6}
					/>
				</Grid>
			</TableCell>
			<TableCell
				align={"center"}
			>
				<span
					className={`status-${(status || 0) % 3}`}
				>
					{papdis_file_upload_id === id ? label : ["In Progress", "Success", "Error"][(status || 0) % 3]}
				</span>
			</TableCell>
			<TableCell
				className={"status-field"}
				align={"center"}
			>
				<UploadProgress
					variant="determinate"
					progress={parseFloat(percentage ?? 0)}
					className={`status-progress-${(status || 0) % 3}`}
				/>
			</TableCell>
			<TableCell align={"center"}>
				{
					error
						? <Grid>
							<InfoOutlined
								color={"primary"}
								cursor={"pointer"}
								onClick={() => onClickHelp(error_data)}
								className={"icon-margin-right"}
							/>
							<DeleteIcon
								color={"error"}
								cursor={"pointer"}
								className={"icon-margin-left"}
								onClick={() => deletePapdisPastUpload(id, profile_data?.employer?.id)}
							/>
					</Grid>
						: status === 1 ? <Grid>
							<RemoveRedEye
								cursor={"pointer"}
								className={"icon-margin-right"}
								onClick={error ? () => false : () => window.open(URL + "/papdis-details/" + id)}
							/>
							<DescriptionIcon
								cursor={"pointer"}
								className={"icon-margin-left"}
								color={"primary"}
								onClick={() => downloadPapdisPastUpload(id, filename)}
							/>
						</Grid>
						: ""
				}
			</TableCell>
		</TableRow>
	);
};

/**
 * @param uploads
 * @param triggerModal
 * @param loading
 * @param deletePapdisPastUpload
 * @param profile_data
 * @param percentage
 * @param papdis_file_upload_id
 * @param label
 * @param downloadPapdisPastUpload
 * @returns {JSX.Element}
 * @constructor
 */
const PastUploadTable: React.Component = (
	{
		uploads = [],
		triggerModal = () => {},
		loading = false,
		deletePapdisPastUpload = () => {},
		profile_data = [],
		papdis_file_upload_id = null,
		label = "",
		downloadPapdisPastUpload = () => {
		},
	}
): JSX.Element => {
	const alignments = [
		"left",
		"center",
		"center",
		"center",
		"center",
		"center",
	];

	if (loading === true) {
		return (
			<Box justifyContent="center" display="flex" padding={4}>
				<Loading absolute inPlace centered/>
			</Box>
		);
	}

	if (!uploads?.length) {
		return (
			<Typography
				variant="subtitle1"
				className={"adjusted-paddging-title-response-not-upload"}
			>
				You have not made an upload yet!
			</Typography>
		);
	}

	return (
		<Table>
			<TableHead
				className={"table-head"}
			>
				<TableRow>
					{[
						"Upload Date",
						"File Name",
						"Employees",
						"Total Contributions",
						"Status",
						"Progress",
						""].map((label, index) => (
						<TableCell
							className={"number-field"}
							key={`th-${label}`}
							align={alignments[index]}
						>
							{label}
						</TableCell>
					))}
				</TableRow>
			</TableHead>
			<TableBody>
				{
					uploads.map((upload, index) => (
						<PastUploadRow
							key={`tr-papdis-${index}`}
							{...upload}
							onClickHelp={triggerModal}
							deletePapdisPastUpload={deletePapdisPastUpload}
							profile_data={profile_data}
							papdis_file_upload_id={papdis_file_upload_id}
							percentage={upload.progress}
							label={label}
							downloadPapdisPastUpload={downloadPapdisPastUpload}
						/>
					))
				}
			</TableBody>
		</Table>
	);
};

export default PastUploadTable;