import React from "react";

import Grid from '@mui/material/Grid2';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

/**
 * @returns {JSX.Element}
 * @constructor
 */
const BodyText = (): JSX.Element => (
    <Grid
        direction="column"
        alignItems="center"
        justifyContent="center"
        container
        size={{
            lg: 12,
            md: 12,
            xs: 12,
            sm: 12
        }}
    >
        <Grid
            direction="column"
            alignItems="center"
            justifyContent="center"
            className={"pension-terms-header terms-and-conditions-header"}
            container
            size={{
                lg: 12
            }}
        >
            <Typography
                variant={"h4"}
                component={"h1"}
                align={"center"}
                style={{marginTop: '1em'}}
            >
                Collegia Terms & Conditions
            </Typography>
            <br />
            <Typography
                variant={"subtitle1"}
                component={"h3"}
                align={"center"}
            >
                We hope you are excited to start your Collegia Journey. First, let’s go over the legal bits:
            </Typography>
            <br />
            <Typography
                variant={"subtitle2"}
                compoennt={"p"}
                align={"center"}
            >
                <Link
                    href={"https://www.collegia.co.uk/legal"}
                    className={"collegia-terms-and-conditions-link"}
                    target={"_blank"}
                >
                    Legal
                </Link>
            </Typography>
        </Grid>
    </Grid>
);

/**
 * @stateless
 * @param props
 * @returns {JSX.Element}
 */
export default BodyText;