import React from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid2';
import NewPayrollSoftwareConnectionOn from "../../../images/NewPayrollSoftwareConnectionOn.svg";
import NewPayrollSoftwareConnectionOff from "../../../images/NewPayrollSoftwareConnectionOff.svg";

import {
	styled,
	withStyles
} from "@mui/styles";
import {ModalContents} from "../../../utils/ModalContents";
import PrimaryHeader from "../../PrimaryHeader";
import {useNavigate} from "react-router-dom";


/**
 * @param theme
 * @returns {{card: {[p: string]: number|{padding: number}, padding: number}}}
 */
const styles = theme => ({
	card: {
		padding: 32,
		[theme.breakpoints.up("md")]: {
			padding: 80
		}
	}
});

/**
 * @type {React.ComponentProps<"img">}
 */
const CardImage = styled(props =>
	<img
		alt={props?.alt} {...props}
	/>
)(theme => ({
	padding: 0,
	objectFit: 'contain',
	width: '100%'
}));

/**
 * @param connected
 * @param onPressDisconnect
 * @param onPressSetup
 * @param setUpManually
 * @returns {JSX.Element}
 * @constructor
 */
const ConnectionPanel: JSX.Element = ({
	connected = false,
	onPressDisconnect = () => {},
	onPressSetup = () => {},
  	setUploadManually = () => {},
}:{
	connected: boolean,
	onPressDisconnect: Function,
	onPressSetup: Function,
	history: Function,
	setUploadManually: Function
}): JSX.Element => {

	const push = useNavigate();


	return (
        <Grid
			className={"tab-panel"}
		>
            <PrimaryHeader
				backIcon
				helpIcon
				helpModal={ModalContents.payroll_help}
			>
				Payroll
			</PrimaryHeader>
            <Grid
				className={"collegia-border collegia-container-wrapper"}
				alignItems={"center"}
				container
				size={12}
			>
				<Grid
                    size={{
                        xs: 6,
                        md: 6,
                        lg: 6
                    }}
				>

					<Grid
						alignItems={"center"}
						container
						size={12}
					>
						<span
							className={"change-payroll-software"}
							onClick={() => push('/payroll-software', {payrollButton: true})}
						>
							Change Payroll Software
						</span>
					</Grid>
				</Grid>

				<Grid
                    size={{
                        xs: 6,
                        md: 6,
                        lg: 6
                    }}
				>
					<Grid
						alignItems={"center"}
						justifyContent={"flex-end"}
						container
						size={12}
					>
						<Button
							variant={"contained"}
							className={"default-button"}
							onClick={connected ? onPressDisconnect : onPressSetup}
						>
							{connected ? "Disconnect" : "How to Set Up"}
						</Button>
					</Grid>
				</Grid>
				<Grid
					alignItems={"center"}
					container
					size={12}
				>
					<CardImage
						src={connected ? NewPayrollSoftwareConnectionOn : NewPayrollSoftwareConnectionOff}
						alt="Connection indication"
						className={"margin-top-botom-image-connection"}
					/>
				</Grid>
				<Grid
                    size={{
                        xs: 12,
                        md: 12,
                        lg: 12
                    }}
				>
					<Grid
						alignItems={"center"}
						justifyContent={"center"}
						container
						size={12}
					>
						<span
							className={"btn-papdis-manually"}
							onClick={() => setUploadManually(true)}
						>
							PAPDIS manual upload
						</span>
					</Grid>
				</Grid>
			</Grid>
        </Grid>
    );
};


export default withStyles(styles)(ConnectionPanel);