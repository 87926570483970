import React, {useEffect} from "react";
import {connect} from "react-redux";

import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";

import PagesPrimaryHeader from "../components/PagesPrimaryHeader";
import Wrapper from "../layout/Logged/Wrapper";
import SectionGrid from "../layout/SectionGrid";
import {getMaterialsList} from "../actions/MaterialsPage";
import LoadingPanel from "../components/LoadingPanel";
import Divider from "@mui/material/Divider";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';


/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Materials = ({
	history = () => {},
	materials = {},
	getMaterialsFiles = () => {},
}): JSX.Element => {
	const cards = materials?.data?.data;
	const loading = materials?.data?.loading;

	useEffect(getMaterialsFiles, [
		getMaterialsFiles
	]);

	if (loading) {
		return (
			<Wrapper>
				<LoadingPanel/>
			</Wrapper>
		);
	}

	return (
        <Wrapper
			history={history}
		>
            <SectionGrid>
				<Grid
					id={"menu-logged-wrapper"}
					justifyContent={"center"}
					container
					size={12}
					style={{marginBottom: 40}}
				>
					<PagesPrimaryHeader
						history={history}
						title={"Materials"}
					/>
					<Grid
                        className={"collegia-border material-screen-content"}
                        justifyContent={"space-around"}
                        container
                        size={{
                            xs: 12,
                            sm: 12,
                            lg: 12,
                            md: 12
                        }}
					>
						{
							materials?.data?.data?.length === 0 && (
								<Grid
                                    alignItems={"center"}
                                    justifyContent={"center"}
                                    container
                                    size={{
                                        xs: 12,
                                        sm: 12,
                                        lg: 12,
                                        md: 12
                                    }}>
									<Typography>
										There are no materials.
									</Typography>
								</Grid>
							)
						}
						{
							materials?.data?.data?.length > 0 && (
								<Grid
									container
									size={12}
									paddingTop={"30px"}
									paddingLeft={"40px"}
									paddingRight={"40px"}
								>
									<Grid
										container
										size={12}
										direction={"row"}
									>
										<Grid
											justifyContent={"center"}
											container
											size={6}
										>
											<Grid
												paddingLeft={"30px"}
											>
												<Typography
													fontSize={25}
												>
													DOCUMENT
												</Typography>
											</Grid>
										</Grid>
										<Grid
											justifyContent={"center"}
											container
											size={6}
										>
											<Typography
												fontSize={25}
											>
												DESCRIPTION
											</Typography>
										</Grid>
									</Grid>
									<Grid
										width={"100%"}
									>
										<Divider/>
									</Grid>
									{
										cards?.map((card, index) => (
											<Grid
												container
												size={12}
												direction={"row"}
												key={index}
											>
												<Grid
													size={0.5}
												>
													<CloudDownloadIcon
														className={'material-mouse-cursor'}
														onClick={() => window.open(card?.material, '_blank')}
													/>
												</Grid>
												<Grid
													justifyContent={"center"}
													container
													size={5.5}
												>
													<Typography
														gutterBottom
														component={"div"}
														className={"typography-break-line"}
													>
														{
															card?.description &&
															JSON.parse(card?.description)[0]?.title
														}
													</Typography>
												</Grid>
												<Grid
													justifyContent={"center"}
													container
													size={6}
												>
													<Typography
														color={"text.secondary"}
														className={"typography-break-line"}
													>
														{
															card?.description &&
															JSON.parse(card?.description)[0]?.subtitle
														}
													</Typography>
												</Grid>
												{
													index !== cards.length - 1 &&
													<Grid
														width={"100%"}
													>
														<Divider/>
													</Grid>
												}
											</Grid>
										))
									}
								</Grid>
							)
						}
					</Grid>
				</Grid>
			</SectionGrid>
        </Wrapper>
    );
};

/**
 * @param state
 * @returns {{}}
 */
const mapStateToProps = state => {
	const {Materials = {}} = state;

	return {
		...Materials,
	};
};

export default connect(
	mapStateToProps,
	dispatch => ({
		getMaterialsFiles: () => dispatch(getMaterialsList()),
	})
)(Materials);