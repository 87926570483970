import React, {useEffect, useState} from "react";

import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid2";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import AddDirector from "./AddDirector";
import FormGroup from "@mui/material/FormGroup";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";
import {array_key_exists, renderReferralData, returnPhoneCountriesCode, validateEmail} from "../../utils/Helpers";
import PhoneInput from "react-phone-input-2";

function isJson(str) {
	try {
		JSON.parse(str);
	} catch (e) {
		return false;
	}
	return true;
}


/**
 * @param props
 * @returns {string|string}
 * @private
 */
const _renderCompanyTitle = (props = {
	company: {
		company_number: "",
		company_name: "",
		title: "",
	},
	setup_data: false,
	employer: {
		title: "",
		company_number: "",
		company_name: "",
		component: {
			state: {
				company_name: "",
				company_number: "",
			}
		}
	},
}): string => {
	const {
		company = true,
		token = false,
		enterManually = false,
	} = props;

	if (token) {
		const {setup_data = false} = props;
		if (setup_data) {
			const {employer = false} = setup_data;
			if (employer) {
				return employer?.title + " - " + employer?.company_number;
			}
		}
	} else if (company && !enterManually) {
		return props?.company?.title + " - " + props?.company?.company_number;
	} else {
		return props.formValues.company_name + " - " + props.formValues.company_number;
	}
}

/**
 * @param props
 * @returns {string}
 * @private
 */
const _renderCompanyRegisteredAddress = (props): string => {
	const {company = true} = props;

	let address_snippet = props.formValues.company_registered_address;

	if (props.token) {
		const {setup_data = false} = props;
		if (setup_data) {
			const {employer = false} = setup_data;
			if (employer) {
				const {addresses = []} = employer
				if (addresses.length > 0) {
					address_snippet = addresses[0].address_snippet;
				}
			}
		}
	} else if (company && !props.enterManually) {
		let address = "";
		if (!company.address_snippet) {
			const address_jason = JSON.parse(company.address);
			address = address_jason.premises + " " + address_jason.address_line_1 + ", " + address_jason.locality
				+ ", " + address_jason.country + ", " + address_jason.postal_code;
		}
		address_snippet = company.address_snippet ? company.address_snippet : address;
	}

	return `${address_snippet}`;
}

/**
 * @param props
 * @returns {string}
 * @private
 */
const _renderTradingAddress = (props): string => {
	let company_trading_address = "";

	if (props?.companyCheckAddress) {
		if (props.token) {
			company_trading_address = props?.setup_data?.employer?.snippet ?? "";
		} else {
			if (props?.company?.address_snippet) {
				company_trading_address = props?.company?.address_snippet;
			} else if (props?.formValues.company_trading_address) {
				company_trading_address = props?.formValues.company_trading_address;
			} else {
				const address_jason = JSON.parse(props.company.address);
				company_trading_address = address_jason.premises + " " + address_jason.address_line_1 + ", " + address_jason.locality
					+ ", " + address_jason.country + ", " + address_jason.postal_code;
			}
		}
	}

	return `${company_trading_address}`;
};

/**
 * @param props
 * @private
 */
const _renderInputAddresses = (props = {
	setup_data: {
		employer: {
			addresses: [{
				address_line_1: "",
				country: "",
				locality: "",
				premises: "",
				addresses: "",
			}]
		},
		company: {
			address: {}
		}
	}
}) => {
	if (props?.setup_data?.employer?.addresses?.length > 0) {
		if (props?.setup_data?.employer?.addresses[0].addresses !== null && isJson(props?.setup_data?.employer?.addresses[0].addresses)) {
			let address = JSON.parse(props?.setup_data?.employer?.addresses[0].addresses);
			return {
				address_line_1: address?.address_line_1 ?? "",
				country: address?.country ?? "United Kingdom",
				locality: address?.locality ?? "",
				postal_code: address?.postal_code ?? "",
				premises: address?.premises ?? "",
			};
		}
	} else if (props?.company?.address) {
		let address;
		if (props?.company?.address?.constructor === Object) {
			address = props?.company?.address;
		} else {
			address = JSON.parse(props?.company?.address);
		}
		return {
			address_line_1: address?.address_line_1 ?? "",
			country: address?.country ?? "United Kingdom",
			locality: address?.locality ?? "",
			postal_code: address?.postal_code ?? "",
			premises: address?.premises ?? "",
		};
	}

	return {
		address_line_1: props?.address_line_1,
		country: props?.country,
		locality: props?.locality,
		postal_code: props?.postal_code,
		premises: props?.premises,
	};
}

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @private
 */
const _renderCompanyTradingAddress = (props = {
	companyCheckAddress: false,
}): JSX.Element => {

	return (
        (<div>
            <FormControlLabel
				className={"signup-form-control-checkbox"}
				control={
					<Checkbox
						name={"company_check_address"}
						id={"company_check_address"}
						checked={props.companyCheckAddress}
						onChange={() => {
							props.setCompanyCheckAddress(!props.companyCheckAddress);
						}}
					/>
				}
				label="My trading address is the same of my registered address"
			/>
            {
				!props.companyCheckAddress && props?.setup_data?.employer?.manual_creation && (
					<>
						<FormControl className={"signup-form-control"} fullWidth>
							<TextField
								type={"text"}
								onChange={(event) => {
									props.setFormValues({...props.formValues, [event.target.name]: event.target.value})
								}}
								label="Trading address postcode"
								variant="outlined"
								name={"company_trading_postcode"}
								id={"company_trading_postcode"}
								disabled={props.companyCheckAddress}
								value={
									props.companyCheckAddress ?
										_renderInputAddresses(props)?.postal_code ?? ""
										:
										props?.company_trading_postcode ?? ""
								}
								slotProps={{
                                    inputLabel: {shrink: true}
                                }}
							/>
						</FormControl>
						<FormControl className={"signup-form-control"} fullWidth>
							<TextField
								type={"text"}
								onChange={(event) => {
									props.setFormValues({...props.formValues, [event.target.name]: event.target.value})
								}}
								label="Address line 1"
								variant="outlined"
								name={"company_trading_address_line_1"}
								id={"company_trading_address_line_1"}
								disabled={props.companyCheckAddress}
								value={
									props.companyCheckAddress ?
										_renderInputAddresses(props)?.address_line_1 ?? ""
										:
										props?.company_trading_address_line_1 ?? ""
								}
								slotProps={{
                                    inputLabel: {shrink: true}
                                }}
							/>
						</FormControl>
						<FormControl className={"signup-form-control"} fullWidth>
							<TextField
								type={"text"}
								onChange={(event) => {
									props.setFormValues({...props.formValues, [event.target.name]: event.target.value})
								}}
								label="Premises"
								variant="outlined"
								name={"company_trading_premises"}
								id={"company_trading_premises"}
								disabled={props.companyCheckAddress}
								value={
									props.companyCheckAddress ?
										_renderInputAddresses(props)?.premises ?? ""
										:
										props?.company_trading_premises ?? ""
								}
								slotProps={{
                                    inputLabel: {shrink: true}
                                }}
							/>
						</FormControl>

						<FormControl className={"signup-form-control"} fullWidth>
							<TextField
								type={"text"}
								onChange={(event) =>
									props.setFormValues({...props.formValues, [event.target.name]: event.target.value})
								}
								label="City"
								variant="outlined"
								name={"company_trading_locality"}
								id={"company_trading_locality"}
								disabled={props.companyCheckAddress}
								value={
									props.companyCheckAddress ?
										_renderInputAddresses(props)?.locality ?? ""
										:
										props?.company_trading_locality ?? ""
								}
								slotProps={{
                                    inputLabel: {shrink: true}
                                }}
							/>
						</FormControl>
						<FormControl className={"signup-form-control"} fullWidth>
							<TextField
								type={"text"}
								onChange={(event) => {
									props.setFormValues({...props.formValues, [event.target.name]: event.target.value})
								}}
								label="Country"
								variant="outlined"
								name={"company_trading_country"}
								id={"company_trading_country"}
								disabled={props?.companyCheckAddress}
								value={
									props.companyCheckAddress ?
										_renderInputAddresses(props)?.country ?? ""
										:
										props?.company_trading_country ?? ""
								}
								slotProps={{
                                    inputLabel: {shrink: true}
                                }}
							/>
						</FormControl>
					</>
				)}
        </div>)
    );
}

/**
 * @param props
 * @param e
 * @param index
 */
const handleInputChange = (props, e, index) => {
	const list = props.directorList.map((director, i) => {
		director.signature = i === index

		if (!director.appointed_on) {
			director.appointed_on = "1900-01-01"
		}
		if (!director.address) {
			director.address = "N/A"
		}
		if (!director.links) {
			director.links = "N/A"
		}
		if (!director.officer_role) {
			director.links = "director"
		}
		return director
	});
	props.setDirectorList(list);
}

const _verifySelectDirector = (directorList) => {
	if (Array.isArray(directorList)) {
		return directorList?.filter(director => director.signature === true).length === 0;
	}

}


const _checkDisabledButton = (props = {}): boolean => {

	if (!props.checkConfirmAuthorised) {
		return true;
	}

	if (props?.error) {
		return true;
	}

	if (_verifySelectDirector(props.directorList)) {
		return true;
	}

	if (props.addDirectorConfirmPage) {

		if ((!props?.directorList[props.directorList.length - 1]?.name)) {
			return true;
		}

		if (!validateEmail(props?.directorList[props.directorList.length - 1]?.email)) {
			return true;
		}
	}


	return false;
}

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ConfirmCompanyDetails = (props = {
	referral: {},
	setup_data: [],
	formValues: {},
	company: [],
	directorList: [],
	officers: [],
	addDirector: false,
	company_trading_postcode: "",
	company_trading_address_line_1: "",
	company_trading_premises: "",
	company_trading_locality: "",
	company_trading_country: "",
	companyCheckAddress: false,
	addDirectorConfirmPage: false,
	checkConfirmAuthorised: false,
	enterManually: false,
	setFormValues: () => {},
	setDirectorList: () => {},
	setAddDirectorConfirmPage: () => {},
	setAddDirector: () => {},
	setCheckConfirmAuthorised: () => {},
	setCompanyCheckAddress: () => {},
	addDirectors: () => {},
	proceedStep: () => {},
	backStep: () => {},
}): JSX.Element => {
	const monthNames = [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December",
	];

	const [value, setSelectedValue] = React.useState(""),
		[directorPhone, setDirectorPhone] = useState("");

	useEffect(() => {
		if (array_key_exists(0, props.directorList) ||
			(props.directorList[0].name === "" && props.officers.length > 0) ) {
			if (props.directorList.length > 0) {
				props.directorList.shift();
			}

			props.addDirectors(props.officers, props.directorList, props.setDirectorList);
		}
	}, [props]);

	const handleChange = (event) => {
		setSelectedValue(event.target.value);
	};

	const directors = props.company && !props.enterManually ?
		props.officers.filter((director) => director?.officer_role.toLowerCase() === "director")
		:
		[props.directorList];

	sessionStorage.setItem("employer_id", props.setup_data.employer_id);

	if (!Array.isArray(props?.directorList)) {
		props?.setDirectorList([props?.directorList]);
	}

	return (
		<Grid
			id={"signup-container"}
			size={12}
			alignItems={"center"}
			alignContent={"center"}
			direction={"column"}
			container
			item
		>
			{renderReferralData(props?.referral ?? {})}
			<FormControl className={"signup-form-control-2 first-title"} fullWidth>
				<Typography align={"center"}>Please, confirm your company</Typography>
			</FormControl>
			<FormControl className={"signup-form-control-2 second-title"} fullWidth>
				<Typography align={"center"}>
					please, review the details below and confirm they are correct
				</Typography>
			</FormControl>
			<Grid
				id={"signup-container-detail"}
				size={12}
				alignItems={"center"}
				alignContent={"center"}
				direction={"column"}
				spacing={0}
				container
				item
			>
				<form noValidate autoComplete="off">
					<FormControl
						className={"signup-form-control-2 confirm-company-title"}
						fullWidth
					>
						<Typography align={"left"}>
							{_renderCompanyTitle(props ?? false)}
						</Typography>
					</FormControl>
					<FormControl
						className={"signup-form-control-2 confirm-title"}
						fullWidth
					>
						<Typography align={"left"}>Registered Address</Typography>
					</FormControl>
					<FormControl
						className={"signup-form-control-2 confirm-description"}
						fullWidth
					>
						<Typography align={"left"}>
							{_renderCompanyRegisteredAddress(props ?? false)}
						</Typography>
					</FormControl>
					<FormControl
						className={"signup-form-control-2 confirm-title"}
						fullWidth
					>
						<Typography align={"left"}>Trading Address</Typography>
					</FormControl>
					<FormControl
						className={"signup-form-control-2 confirm-description"}
						fullWidth
					>
						<Typography align={"left"}>
							{_renderTradingAddress(props ?? false)}
						</Typography>
					</FormControl>

					{
						(props.company && !props.enterManually) || props.token ?
							_renderCompanyTradingAddress(props ?? false)
							:
							""
					}

					<FormControl
						className={"signup-form-control-2 confirm-title"}
						fullWidth
					>
						<Typography align={"left"}>Directors</Typography>
					</FormControl>

					{
						Array.isArray(directors) && directors.map((director, index) => (
							<FormControl
								key={`director-${index}`}
								className={"signup-form-control-2 confirm-description"}
								fullWidth
							>
								<Typography align={"left"}>
									{
										director?.date_of_birth?.year !== undefined ?
											`${director?.name} (Date of birth: 
											${monthNames[Math.max(0, director?.date_of_birth?.month - 1)]} 
											${director?.date_of_birth?.year})`
											:
											director?.name
									}
								</Typography>
							</FormControl>
						))
					}

					<FormControl
						className={"signup-form-control-2 confirm-text"}
						fullWidth
					>
						<Typography align={"left"}>
							Which Director or Authorised Signatory is signing up on behalf of the company?
						</Typography>
					</FormControl>
					{
						props?.directorList?.length > 0 && props?.directorList[0]?.name !== "" ?
							props.directorList.map((director, i) => (
								<RadioGroup
									aria-label={"director"}
									name={"director"}
									value={value}
									onChange={handleChange}
									key={`director-${i}`}
								>
									<FormGroup>
										<FormControlLabel
											className={"signup-form-control-2 confirm-title"}
											control={
												<Radio
													name={"director_check_address"}
													id={"director_check_address"}
													color={"primary"}
													onChange={(e) =>
														handleInputChange(props, e, i)
													}
													value={director?.name}
												/>
											}
											label={
												director?.email?.length > 0
													? director?.name + "(" + director?.email + ")"
													: director?.name
											}
										/>
									</FormGroup>
								</RadioGroup>
							))
							:
							[]
					}
					{
						props.addDirectorConfirmPage ? (
								<Grid>
									<AddDirector
										directorList={props.directorList}
										setDirectorList={props.setDirectorList}
									/>
									<FormControl className={"signup-form-control"} fullWidth>
										<Button
											id={"add-director"}
											variant="contained"
											color="primary"
											onClick={() => {
												props.setDirectorList(props.directorList.slice(0, props?.directorList?.length - 1));
												props.setAddDirectorConfirmPage(false);
											}}
										>
											Discard director
										</Button>
									</FormControl>
								</Grid>

							) :
							(
								<FormControl className={"signup-form-control"} fullWidth>
									<Button
										id={"add-director"}
										variant="contained"
										color="primary"
										onClick={() => {
											props.setDirectorList([...props.directorList, {
												name: "",
												email: "",
											}]);
											props.setAddDirectorConfirmPage(true);
										}}
									>
										Add other director / Authorised signatory
									</Button>
								</FormControl>
							)
					}

					<FormControl
						className={"signup-form-control"}
						fullWidth
					>
						<PhoneInput
							className={"phone-number"}
							country={'gb'}
							regions={returnPhoneCountriesCode()}
							inputProps={{
								name: "mobile",
								id: "mobile",
								required: true,
							}}
							value={directorPhone}
							searchPlaceholder={"Country name"}
							searchNotFound={"No country found, please search again."}
							onChange={e => setDirectorPhone(e)}
							specialLabel={"Director's Phone*"}
							countryCodeEditable={false}
							enableSearch
						/>
						<small>
							There is no need to add the zero at the phone number
						</small>
					</FormControl>

					<Divider/>

					<FormControl
						className={"signup-form-control-2 director-text"}
						fullWidth
					>
						<Typography align={"left"}>
							You can only apply to set up a pension scheme for a company that
							you lawfully represent or work for.
						</Typography>
					</FormControl>
					<FormControlLabel
						className={"signup-form-control-checkbox"}
						control={
							<Checkbox
								name={"check_confirm_authorised"}
								id={"check_confirm_authorised"}
								value={props.checkConfirmAuthorised}
							/>
						}
						onChange={() => {
							props.setCheckConfirmAuthorised(!props.checkConfirmAuthorised)
						}}
						label="I confirm that I’m authorised to act on behalf of the company*"
					/>

					<FormControl className={"signup-form-control"} fullWidth>
						<Button
							variant="contained"
							color="primary"
							onClick={() => {
								props?.setDirectorList({
									...props?.directorList[0],
									phone_number: directorPhone,
								});
								if (props.companyCheckAddress) {
									props.setFormValues({
										...props.formValues,
										company_trading_locality: props?.company?.address?.locality ?? _renderInputAddresses(props)?.locality ?? "",
										company_trading_country: props?.company?.address?.country ?? _renderInputAddresses(props)?.country ?? "",
										company_trading_address_line_1: props?.company?.address?.address_line_1 ?? _renderInputAddresses(props)?.address_line_1 ?? "",
										company_trading_premises: props?.company?.address?.address_line_2 ?? "",
										company_trading_postcode: props?.company_registered_address_postcode ?? _renderInputAddresses(props)?.postal_code ?? "",
									})
								}
								props?.proceedStep();
							}}
							disabled={_checkDisabledButton(props) || directorPhone.length <= 9}
						>
							Continue
						</Button>
					</FormControl>
					<FormControl
						className={"signup-form-control-2 forgot-password"}
						fullWidth
					>
						<span
							className={"back-span"}
							onClick={() => props?.backStep()}
						>
							Back
						</span>
					</FormControl>

					<FormControl
						className={"signup-form-control-2 forgot-password"}
						fullWidth
					>
						<small>Wrong info?</small>
					</FormControl>
					<FormControl
						className={"signup-form-control-2 forgot-password"}
						fullWidth
					>
			            <span
				            onClick={() => props?.backStep()}
			            >
			              Search again or enter manually
			            </span>
					</FormControl>
				</form>
			</Grid>
		</Grid>
	);
}

/**
 * @stateless
 * @param props
 * @returns {JSX.Element}
 */
export default ConfirmCompanyDetails;
