import React from 'react';
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid2";
import MUIDataTable from "mui-datatables";
import SectionGrid from "../../layout/SectionGrid";
import TableCellItem from "../../components/TableCellItem";
import PowerIcon from '@mui/icons-material/Power';
import PowerOffIcon from '@mui/icons-material/PowerOff';

/**
 * @param action
 * @param employer_id
 * @param advisor_id
 * @param authorization
 * @param modifyAccess
 */
function handleUpdateUserData(action, employer_id, advisor_id, authorization, modifyAccess) {
	const updateobject = {
		employers: {
			employer_id: employer_id,
			advisor_id: advisor_id,
			authorization: authorization,
		}
	};
	modifyAccess(updateobject, action);
}


/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const IFARequestCard = ({
	myIfas = [],
	employerId = "",
	modifyAccess = () => {},
}): JSX.Element => {
	/**
	 * @param value
	 * @returns {JSX.Element}
	 * @constructor
	 */
	const ShowStatus = (value) => (
		<Grid>
			{
				!value && (
					<span className={"status-2"}>
						{"Disconnected"}
					</span>
				)
			}
			{
				value && (
					<span className={"status-1"}>
						{"Active"}
					</span>
				)
			}
		</Grid>
	);


	/**
	 * @param value
	 * @param tableMeta
	 * @returns {JSX.Element}
	 */
	const renderActions = (value, tableMeta) =>
		 (
			<Grid
				justifyContent={"center"}
				alignItems={"center"}
				container
			>
				<Grid
                    direction={"column"}
                    alignContent={"space-around"}
                    alignItems={"center"}
                    container
                    size={{
                        xs: 3,
                        sm: 3,
                        md: 3,
                        lg: 3
                    }}
				>
					<IconButton
						id={value ? "advisors-table-deny-button" : "advisors-table-accept-button"}
						onClick={() =>
							tableMeta.rowData[2] ?
								handleUpdateUserData(
									"deny",
									employerId,
									value,
									false,
									modifyAccess)
								:
								handleUpdateUserData(
									"authorize",
									employerId,
									value,
									true,
									modifyAccess)
						}
					>
						{
							tableMeta.rowData[2] ?
								<Toolbar
									title={"Revoke connection"}
								>
									<PowerOffIcon
										className={"advisors-table-deny-icon"}
									/>
								</Toolbar>
								:
								<Toolbar
									title={"Allow connection"}
								>
									<PowerIcon
										className={"advisors-table-accept-icon"}
									/>
								</Toolbar>
						}
					</IconButton>
				</Grid>
			</Grid>
		);


	const columns = [
		{
			name: 'title',
			label: "NAME",
			options: {
				filter: true,
				sort: true,
				setCellProps: () => ({align: 'center'}),
				customBodyRender: (value) =>
					<TableCellItem
						title={value}
					/>,
			},
		},
		{
			name: 'email',
			label: 'E-MAIL',
			options: {
				filter: true,
				sort: true,
				setCellProps: () => ({align: 'center'}),
				customBodyRender: (value) =>
					<TableCellItem
						subtitle={value}
					/>,
			}
		},
		{
			name: "active",
			label: 'STATUS',
			options: {
				filter: true,
				sort: true,
				setCellProps: () => ({align: 'center'}),
				customBodyRender: (value) =>
					ShowStatus(value),
			}
		},
		{
			name: "id",
			label: 'ACTIONS',
			options: {
				filter: true,
				sort: true,
				setCellProps: () => ({align: 'center'}),
				customBodyRender: (value,tableMeta) =>
					renderActions(value, tableMeta),
			}
		},
	];

	return (
		<SectionGrid>
			<MUIDataTable
				className={"data-table users-table"}
				columns={columns}
				data={myIfas ?? []}
				options={{
					filter: false,
					selectableRowsHeader: false,
					selectableRows: "none",
					responsive: "simple",
					viewColumns: false,
					print: false,
					download: false,
					elevation: 0,
				}}
				pagination
			/>
		</SectionGrid>
	);
}

export default IFARequestCard;