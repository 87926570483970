import React from "react";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import {connect} from "react-redux";

/**
 * @type {string[]}
 */
const fieldsEmail =
        [
            'Name',
            'E-mail',
            'NIN',
            'Status',
            'Enrollment Date',
            'Inactivation Date',
        ];

/**
 * @param employee_contributions
 * @returns {JSX.Element}
 * @constructor
 */
const EmployeeDetails: JSX.Element = ({
    employeeDetails = [],
    employees = [],
}): JSX.Element => {

    let selectedEmployee = [];

    if (employees?.list?.length > 0) {
        employees?.list.map(employee => {
            if (employeeDetails?.id === employee?.individual_id) {
                selectedEmployee = employee
            }

            return selectedEmployee;
        });
    }

    return (
        <Grid>
            <Grid
                className={"collegia-container-wrapper"}
                container
                size={12}
            >
                <Grid
                    size={6}
                >
                    {fieldsEmail.map((labels, index) => (
                        <Typography
                            key={index}
                        >
                            {labels}
                        </Typography>
                    ))}
                </Grid>
                <Grid
                    size={6}
                >
                    <Typography>
                        {!employeeDetails?.forename
                            ?   "--"
                            :   employeeDetails?.forename + " " + employeeDetails?.surname
                        }
                    </Typography>
                    <Typography>
                        {!employeeDetails?.email
                            ?   "--"
                            :   employeeDetails?.email
                        }
                    </Typography>
                    <Typography>
                        {!employeeDetails?.national_insurance_number
                            ?   "--"
                            :   employeeDetails?.national_insurance_number
                        }
                    </Typography>
                    <Typography>
                        {!selectedEmployee?.active
                            ?   "--"
                            :   selectedEmployee?.active
                        }
                    </Typography>
                    <Typography>
                        {!selectedEmployee?.enrollment_date
                            ?   "--"
                            :   selectedEmployee?.enrollment_date
                        }
                    </Typography>
                    <Typography>
                        {!selectedEmployee?.inactivation_date
                            ?   "--"
                            :   selectedEmployee?.inactivation_date
                        }
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = state => {
    const {
        Employer = [],
    } = state;

    return {
        employees: Employer.employees,
    };
};

export default connect(mapStateToProps, null)(EmployeeDetails);
