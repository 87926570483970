import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import {renderReferralData, validateEmail} from "../../utils/Helpers";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid2";
import React from "react";
import {useNavigate} from "react-router-dom";

/**
 * @returns {JSX.Element|*[]}
 * @private
 * @param errorMessage
 */
const _renderErrorMessage = (errorMessage = false): JSX.Element | *[] => {
	if (errorMessage !== "") {
		return (
			<small className={"red-text"}>
				{errorMessage ?? ""}
			</small>
		);
	}
	
	return [];
}

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const EmailDetail = ({
	email = "",
	token = "",
	formValues = [{}],
	referral = {},
	proceedStep = () => {},
	checkEmailAddress = () => {},
	setFormValues = () => {},
	error = false,
	errorMessage = "",
}): JSX.Element => {

	const push = useNavigate();


	return (
		<Grid
			id={"signup-container"}
			size={12}
			alignItems={"center"}
			alignContent={"center"}
			direction={"column"}
			spacing={0}
			container
			item
		>
			<form
				noValidate
				autoComplete="off"
				method={"post"}
				onSubmit={event => event.preventDefault()}
			>
				{renderReferralData(referral ?? false)}
				<FormControl
					className={"signup-form-control-2 first-title"}
					fullWidth
				>
					<Typography align={"center"}>
						Sign up as an employer
					</Typography>
				</FormControl>
				<FormControl
					className={"signup-form-control-2 second-title"}
					fullWidth
				>
					<Typography align={"center"}>
						Please type your e-mail to continue
					</Typography>
				</FormControl>
				<FormControl
					className={"signup-form-control"}
					fullWidth
				>
					<TextField
						type={"email"}
						onChange={(event) => {
							setFormValues({...formValues, [event.target.name]: event.target.value})
						}}
						label="E-mail"
						variant="outlined"
						name={"email"}
						id={"email"}
						value={email ?? ""}
						error={(!validateEmail(email)) && email !== ''}
					/>
					<small className={"red-text"}>
						{
							(!validateEmail(email) && email !== '') ?
								"* Please enter a valid e-mail"
								:
								""
						}
					</small>
					{_renderErrorMessage(errorMessage ?? false)}
				</FormControl>
				<FormControl
					className={"signup-form-control"}
					fullWidth
				>
					<Button
						variant="contained"
						color="primary"
						onClick={() => {
							checkEmailAddress({
								employers: {
									primary_email: email,
									setup: token ?? false,
									token: token,
								}
							}, proceedStep);
						}}
						disabled={
							!validateEmail(email) ||
							error
						}
					>
						Continue
					</Button>
				</FormControl>

				<FormControl
					className={"signup-form-control-2 forgot-password"}
					fullWidth
				>
				<span
					className={"back-span"}
					onClick={() => push("/")}
				>
					Back
				</span>
				</FormControl>

				<FormControl
					className={"signup-form-control-2 forgot-password"}
					fullWidth
				>
					<small className={"font-size-09"}>
						Already have an account?
					</small>
				</FormControl>
				<FormControl
					className={"signup-form-control-2 forgot-password"}
					fullWidth
				>
                    <span
	                    onClick={() => push('/')}
	                    className={"font-size-09"}
                    >
                        Sign in
                    </span>
				</FormControl>
			</form>
		</Grid>
	);
};

/**
 * @stateless
 * @param props
 * @returns {JSX.Element}
 */
export default EmailDetail;