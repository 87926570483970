import React from 'react'

import Dropzone from 'react-dropzone';
import Typography from '@mui/material/Typography';
import UploadLogo from "../../images/upload-logo.svg";
import Delete from "@mui/icons-material/Delete";
import Grid from "@mui/material/Grid2";
import Button from "@mui/material/Button";
import {useDispatch} from "react-redux";
import {updateLogo} from "../../actions/Profile";

/**
 *
 * @param {*} props
 * @returns {JSX.Element}
 * @constructor
 */
const LogoUpload: JSX.Element = React.forwardRef((
	{
		fileHandler = () => {},
		alt,
		src,
		nameImg,
		pictureLogo,
		acceptedFormats = "*",
		setImgName,
		setPreview,
		initialState,
		setPictureLogo,
	}: {
		acceptedFormats: String
	}, ref
): JSX.Element => {

	const dispatch = useDispatch();

	function updateLogoCompany() {
		const data = {
			picture: pictureLogo,
		};
		dispatch(updateLogo(data));
	}

	const handleDrop = event => {
		const files = event;
		if (files.length > 0) {
			setImgName(files[0].name);
			setPreview(
				files.length
					? {
						src: URL.createObjectURL(files[0]),
						alt: files[0].name
					}
					: initialState
			);
			setPictureLogo(files[0]);
		}
	};

	function deleteLogo() {
		src = null;
		setPictureLogo(null);
	}

	return (
        <Grid
			container
			marginTop={2}
			size={12}
		>
            <Dropzone
				ref={ref}
				accept={acceptedFormats}
				onDrop={e => handleDrop(e)}
			>
				{({getRootProps, getInputProps, isDragAccept}) => (
					<div
						className={!src ? "upload-logo" : "upload-logo-no-hover"}
						{...getRootProps()}>
						<input
							{...getInputProps()}
							onChange={fileHandler}
							disabled={src ? "disabled" : ""}
						/>
						<img
							alt="Upload Logo your company"
							src={!src ? UploadLogo : src}
							className={"company-info-image"}
						/>
						<Grid
							container
                            className={!src ? "company-info-logo-trash-text" : "company-info-logo-trash-text-with-image"}
                            alignItems={"flex-end"}
                            alignContent={"center"}
							justifyContent={"center"}
                            size={{
                                xs: 12,
                                sm: 12,
                                md: 12,
                                lg: 12
                            }}
						>
							{
								!src ?
									<Typography
										variant={"body1"}
										component={"p"}
										noWrap
										className={"company-info-drag-and-drop"}
									>
										Drag and drop your logo here. In png or svg format
									</Typography>
									:
									<Typography
										variant={"body1"}
										component={"p"}
										className={"company-info-img-name"}
										noWrap
									>
										{nameImg}
									</Typography>
							}
							{src && (
								<Delete
									className={"company-info-logo-deleted"}
									onClick={() => deleteLogo()}
								/>
							)}
						</Grid>
					</div>
				)}
			</Dropzone>
            <Grid
				container
				alignItems={"center"}
				justifyContent={"flex-end"}
				padding={2}
				size={12}
			>
				<Button
					color="primary"
					variant="contained"
					className={"company-info-buttons-save"}
					onClick={() => updateLogoCompany()}
				>
					Save
				</Button>
			</Grid>
        </Grid>
    );
})

export default LogoUpload;