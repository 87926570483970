import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {authorizationLogin, buttonActions, getByAuthorizationCode} from "../../actions/Authorization";
import Wrapper from "../../layout/Wrapper";
import Slide from "@mui/material/Slide";
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Loading from "../../components/Loading";
import LoggedUser from "../../components/Authorization/Advisor/LoggedUser";
import LoginForm from "../../components/Authorization/LoginForm";
import {me} from "../../actions/Profile";
import Grid from "@mui/material/Grid2";
import {useParams} from "react-router-dom";

const renderContent = (
	profile_data = {
		id: null
	},
	showLoginForm = false,
	errors = [],
	software = {},
	code: "",
	authorizationLogin: () => {},
	buttonActions: () => {}
): JSX.Element => {
	if (!showLoginForm) {
		return (
			<div id={"authorization-container"}>
				<LoggedUser
					profile_data={profile_data}
					code={code}
					buttonActions={buttonActions}
					isAdvisor={false}
					software={software}
				/>
			</div>
		)
	} else {
		return (
			<div id={"authorization-container"}>
				<LoginForm
					errors={errors}
					isAdvisor={false}
					code={code}
					authorizationLogin={authorizationLogin}
				/>
			</div>
		)
	}
}

const EmployerConnection = (props) => {
	const {
		loading = false,
		software = {
			software_id: "",
			name: "",
			active: true,
			email: "",
		},
		showLoginForm = false,
		profile_data = {
			id: null,
		},
		errors = [],
		getByAuthorizationCode = () => {},
		authorizationLogin = () => {},
		buttonActions = () => {}
	} = props;

	const { code } = useParams();

	useEffect(() => {
		getByAuthorizationCode(code ?? false);
	}, [
		getByAuthorizationCode,
		code,
	]);

	if (loading) {
		return <Loading absolute />;
	}

	return (
		<Wrapper showSandwich={false}>
			<Slide direction="up" in={true} mountOnEnter unmountOnExit>
				<Grid
					id={"login-wrapper"}
				>
					<Modal
						id={"authorization-modal"}
						aria-labelledby="transition-modal-title"
						aria-describedby="transition-modal-description"
						open={true}
						onClose={() => false}
						slots={{backdrop: Backdrop}}
						slotsProps={{
							backdrop: {
								timeout: 500
							}
						}}
						closeAfterTransition
					>
						<Grid
							container
							size={12}
						>
							{
								renderContent(
									profile_data,
									showLoginForm,
									errors,
									software,
									code,
									authorizationLogin,
									buttonActions
								)
							}
						</Grid>
					</Modal>
				</Grid>
			</Slide>
		</Wrapper>
	);
}


/**
 * @param state
 * @returns {
 *  {
 *      terms_accepted: boolean,
 *      software: {
 *          software_id: string,
 *          name: string,
 *          active: boolean,
 *          email: string
 *      },
 *      intermediary_token: string,
 *      active: boolean,
 *      created_at: string,
 *      loading: boolean,
 *      user_intermediary_id: string,
 *      updated_at: string,
 *      third_party_software_id: null,
 *      setup_complete: boolean,
 *      employer: {
 *          snippet: string,
 *          company_number: string,
 *          code: string,
 *          company_logo: null,
 *          contribution_date: null,
 *          nickname: string,
 *          company_status: string,
 *          description: string,
 *          primary_email: string,
 *          creation_date: string,
 *          title: string,
 *          employer_type_id: null
 *      },
 *      id: null,
 *      employer_id: null
 *   }
 * }
 */
const mapStateToProps = state => {
	const {
		Authorization = {
			active: false,
			setup_complete: false,
			terms_accepted: false,
			loading: false,
			created_at: "",
			intermediary_token: "",
			updated_at: "",
			user_intermediary_id: "",
			employer_id: null,
			id: null,
			third_party_software_id: null,
			employer: {
				code: "",
				company_number: "",
				company_status: "",
				creation_date: "",
				description: "",
				nickname: "",
				primary_email: "",
				snippet: "",
				title: "",
				company_logo: null,
				employer_type_id: null,
				contribution_date: null,
			},
			software: {
				software_id: "",
				name: "",
				active: true,
				email: "",
			},
		},
		Profile = {},
	} = state;

	return {
		...Authorization,
		...Profile
	};
};

/**
 * @param dispatch
 * @returns {
 *  {
 *      buttonActions: (function(string=, string=): *),
 *      getByAuthorizationCode: (function(string=): *)
 *   }
 * }
 */
const mapDispatchToProps = dispatch => ({
	getByAuthorizationCode: (code:string = "", isAdvisor:boolean = false) => dispatch(getByAuthorizationCode(code ?? false, isAdvisor)),
	buttonActions: (code:string = "", action:string = "") => dispatch(buttonActions(code ?? false, action ?? false)),
	authorizationLogin: (data, code) => dispatch(authorizationLogin(data, code)),
	me: () => dispatch(me()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmployerConnection);