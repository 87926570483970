import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as PopupActions from '../actions/Popup';
import MuiDialogTitle from '@mui/material/DialogTitle';
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid2";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { Markup } from 'interweave';

/**
 * @param fields
 * @param index
 * @param format
 * @returns {JSX.Element|string}
 * @private
 */
const _renderFields = (fields = "", index, format): JSX.Element|string => {
	if (fields !== "") {
		return (
			<Typography
				key={`popup-${index}-${format}-${fields}`}
				className={format === 'text' ? 'popup-text' : 'popup-code'}
			>
				CSV field: {fields}.
			</Typography>
		);
	}

	return "";
}

/**
 * @param line
 * @param index
 * @param format
 * @returns {JSX.Element|string}
 * @private
 */
const _renderFileLine = (line = "", index, format): JSX.Element|string => {
	if (line !== "") {
		return (
			<Typography
				key={`popup-${index}-${format}-${line}`}
				className={format === 'text' ? 'popup-text' : 'popup-code'}
			>
				File Row: {line}.
			</Typography>
		);
	}

	return "";
}

/**
 * @param code
 * @param index
 * @param format
 * @returns {JSX.Element|string}
 * @private
 */
const _renderCollegiaErrorCode = (code = "", index, format) => {
	if (code !== "") {
		return (
			<Typography
				key={`popup-${index}-${format}-${code}`}
				className={format === 'text' ? 'popup-text' : 'popup-code'}
			>
				Collegia error code: {code}.
			</Typography>
		);
	}

	return "";
}

/**
 * @param message
 * @param index
 * @param format
 * @returns {JSX.Element}
 * @private
 */
const _renderMessageSummary = (message = "", index, format) => {
	if (message !== "") {

		if (message !== "The following errors were encountered: " && message !== "Please upload the file again") {
			return (
				<Typography
					key={`popup-${index}-${format}-${message}`}
					className={format === 'text' ? 'popup-text' : 'popup-code'}
				>
					Error summary: {message}
				</Typography>
			);
		}
	}

	return (
		<Typography
			key={`popup-${index}-${format}-${message}`}
			className={'popup-text'}
		>
			{message}
		</Typography>
	);
}

/**
 * @param description
 * @param index
 * @param format
 * @returns {JSX.Element|string}
 * @private
 */
const _renderErrorDescription = (description = "", index, format) => {
	if (description !== "" && description !== null) {
		return (
			<Typography
				key={`popup-${index}-${format}-${description}`}
				className={format === 'text' ? 'popup-text' : 'popup-code'}
			>
				Error description: <br />

				<Markup content={description} />
			</Typography>
		);
	}

	return "";
};

/**
 * @param message
 * @param fields
 * @param description
 * @param code
 * @param popupState
 * @param updateField
 * @param format
 * @param field_name
 * @param label
 * @param options
 * @param getOptionName
 * @param fileLine
 * @param value
 * @param index
 * @returns {JSX.Element}
 * @constructor
 */
const PopupField: React.Component = (
	{
		message,
		fields,
		description,
		code,
		popupState,
		updateField,
		format,
		field_name,
		label,
		options = [],
		getOptionName = () => {},
		fileLine = "",
		value = null,
	}
	, index
): JSX.Element => (
	(format === 'dropdown')
		? <FormControl
			key={`popup-${index}-${format}`}
			fullWidth
			variant="outlined">
			<InputLabel
				htmlFor={`select-${label}`.toLowerCase()}
			>
				{label}
			</InputLabel>
			<Select
				fullWidth
				native
				defaultValue={{name: 'error'}}
				onChange={(event) => {
					updateField(field_name, options[event.target.value] || '')
				}}
			>
				<option aria-label="None" value=""/>
				{options.map((option, id) => (
					<option key={`role-option-${id}`} value={id}>
						{getOptionName(option)}
					</option>
				))}
			</Select>
		</FormControl>
		:
		<div
			key={`popup-${index}-${format}`}
			className={format === 'text' ? 'popup-text' : 'popup-code'}
		>
			{_renderFields(fields, index, format === 'text' ? 'popup-text' : 'popup-code')}
			{_renderCollegiaErrorCode(code, index, format === 'text' ? 'popup-text' : 'popup-code')}
			{_renderFileLine(fileLine, index, format === 'text' ? 'popup-text' : 'popup-code')}
			{_renderMessageSummary(message, index, format === 'text' ? 'popup-text' : 'popup-code')}
			{_renderErrorDescription(description, index, format === 'text' ? 'popup-text' : 'popup-code')}
		</div>
)

/**
 * @returns {JSX.Element}
 * @constructor
 */
const Popup: React.Component = () => {
	const dispatch = useDispatch();
	const hide = () => dispatch(PopupActions.hide());

	const {open, content, title} = useSelector(state => state.Popup);

	const [popupState, setPopupState] = useState({});

	const isForm = Boolean(content.find(x => x.format === 'dropdown'));

	return (
        <Dialog
			maxWidth={"xl"}
			fullWidth
			onBlur={isForm ? undefined : hide}
			onClose={hide}
			open={open}
		>
            <MuiDialogTitle
				onClose={hide}
			>
				<Grid
                    container
                    size={{
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12
                    }}
				>
					<Grid
                        direction={"column"}
                        alignItems={"flex-start"}
                        alignContent={"flex-start"}
                        container
                        paddingTop={"15px"}
                        size={{
                            xs: 6,
                            sm: 6,
                            md: 6,
                            lg: 6
                        }}
					>
						<Typography>
							{title}
						</Typography>
					</Grid>
					<Grid
                        direction={"column"}
                        alignItems={"flex-end"}
                        alignContent={"flex-end"}
                        container
                        size={{
                            xs: 6,
                            sm: 6,
                            md: 6,
                            lg: 6
                        }}
					>
						{
							hide && (
								<IconButton aria-label="close" onClick={hide}>
									<CloseIcon/>
								</IconButton>
							)
						}
					</Grid>
				</Grid>
			</MuiDialogTitle>
            <DialogContent>
				{(content?.length > 0)
					&& content.map((field, index) => {
							return (
								<PopupField
									key={`popup-field-${index}`}
									updateField={(field, value) => setPopupState({
										...popupState,
										[field]: value
									})}
									popupState={popupState}
									index={index}
									{...field}
								/>
							)
						}
					)}
			</DialogContent>
            {
				hide &&
				<Grid
                    alignItems={"center"}
                    alignContent={"center"}
                    direction={"column"}
                    container
                    marginBottom={2}>
					<Button
						variant={"contained"}
						color={"primary"}
						onClick={hide}
					>
						OK
					</Button>
				</Grid>
			}
        </Dialog>
    );
}

export default Popup;