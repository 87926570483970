import React, {useEffect, useState} from "react";
import {connect} from "react-redux";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import SectionGrid from "../../../layout/SectionGrid";
import TabPanel from "../TabPanel"
import {getEmployeeContributions} from "../../../actions/Contributions";
import MUIDataTable from "mui-datatables";
import PrimaryHeader from "../../PrimaryHeader";
import {ModalContents} from "../../../utils/ModalContents";
import TableCellItem from "../../TableCellItem";
import Grid from "@mui/material/Grid2";
import {getEmployeesList} from "../../../actions/Employer";

/**
 * @param tab_index
 * @param index
 * @param employees
 * @param getEmployeesList
 * @returns {JSX.Element}
 * @constructor
 */
const OptOutTab: JSX.Element = (
	{
		tab_index = 0,
		index = 0,
		employees_opt_out = {},
		getEmployeesList = () => {
		},
	}
): JSX.Element => {
	useEffect(getEmployeesList, [
		getEmployeesList
	]);

	const [setRowInfo] = useState("");

	const {
		list = {
			current_page: 1,
			last_page: 1,
		},
		isLoading = false,
	} = employees_opt_out;

	const Loading: JSX.Element = () => (
		<Box justifyContent="center" display="flex" padding={4}>
			<CircularProgress/>
		</Box>
	);

	const renderName = (tableMeta) => (
		<TableCellItem
			title={tableMeta.rowData[1]}
		/>
	);

	const renderNIN = (tableMeta) => (
		<TableCellItem
			subtitle={tableMeta.rowData[2]}
		/>
	);

	const renderRefund = (tableMeta) => (
		<TableCellItem
			title={tableMeta.rowData[3]}
		/>
	);

	const renderStatus = (tableMeta) => {
		let className = getEmployeeStatusClass(tableMeta.rowData[4])

		let status = tableMeta.rowData[4].split(" ")

		if (status.length > 1) {
			return (
				<div>
					<span className={className}>
						{status[0]}
					</span>
					<span className={className} >
						{status[1]}
					</span>
				</div>
			);
		}

		return (
			<Grid>
				{
					status[0] === "Enrolled" ?
						<span
							className={className}
							onClick={() => {
								setRowInfo(tableMeta.rowData)
							}}
						>
							{status[0]}
						</span>
						:
						<span
							className={className}
						>
							{status[0]}
						</span>
				}

			</Grid>

		);
	}

	const renderEnrollmentDate = (tableMeta) => (
		<TableCellItem
			subtitle={tableMeta.rowData[5]}
		/>
	);

	const renderInactivationDate = (tableMeta) => (
		<TableCellItem
			subtitle={tableMeta.rowData[6]}
		/>
	);

	function getEmployeeStatusClass(status) {
		switch (status) {
			case 'Enrolled':
				return 'status-enrolled';
			case 'Disconnected':
				return 'status-disconnected';
			default:
				return 'status-opted-out';
		}
	}

	const columns = [
		{
			name: 'individual_id',
			label: "",
			options: {
				filter: true,
				sort: true,
				display: false,
				setCellProps: () => ({align: 'center'}),
				customBodyRender: (value, tableMeta) => renderName(tableMeta)
			},
		},
		{
			name: 'name',
			label: "NAME",
			options: {
				filter: true,
				sort: true,
				setCellProps: () => ({align: 'center'}),
				customBodyRender: (value, tableMeta) => renderName(tableMeta)
			},
		},
		{
			label: 'NATIONAL INSURANCE NUMBER',
			name: 'national_insurance_number',
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value, tableMeta) => renderNIN(tableMeta),
				setCellProps: () => ({align: 'center'})
			}
		},
		{
			label: 'REFUND',
			name: 'refund',
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value, tableMeta) => renderRefund(tableMeta),
				setCellProps: () => ({align: 'center'})
			}
		},
		{
			label: 'STATUS',
			name: 'active',
			options: {
				filter: true,
				sort: true,
				customBodyRender: (value, tableMeta) => renderStatus(tableMeta),
				setCellProps: () => ({align: 'center'}),
			}
		},
		{
			label: 'ENROLLMENT DATE',
			name: 'enrollment_date',
			options: {
				filter: true,
				sort: true,
				customBodyRender: (value, tableMeta) => renderEnrollmentDate(tableMeta),
				setCellProps: () => ({align: 'center'}),
			}
		},
		{
			label: 'INACTIVATION DATE',
			name: 'inactivation_date',
			options: {
				filter: true,
				sort: true,
				customBodyRender: (value, tableMeta) => renderInactivationDate(tableMeta),
				setCellProps: () => ({align: 'center'}),
			}
		},
	];

	return (
		<TabPanel
			className={"tab-panel"}
			value={tab_index}
			index={index}
		>
			<SectionGrid>
				{isLoading ? (
					<Loading/>
				) : (
					<>
						<PrimaryHeader
							backIcon
							helpIcon
							helpModal={ModalContents.employees_help}
						>
							Check Opt-Outs
						</PrimaryHeader>

						<MUIDataTable
							className={"data-table opt-out-table"}
							columns={columns}
							data={list}
							options={{
								filter: false,
								selectableRowsHeader: false,
								selectableRows: "none",
								responsive: "simple",
								viewColumns: false,
							}}
							pagination
						/>
					</>
				)}
			</SectionGrid>
		</TabPanel>
	);
}

export default connect(
	({Employer, Profile}) => ({
		...Profile,
		employees_opt_out: Employer.employees_opt_out,
	}),
	(dispatch) => ({
		getEmployeesList: (page_index) => dispatch(getEmployeesList(page_index)),
		getEmployeeContributions: (national_insurance_number: string = "", type: string = "payroll") =>
			dispatch(getEmployeeContributions(national_insurance_number, type)),
	})
)(OptOutTab);
