import React from 'react';
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid2";
import {buttonRequest, deleteAdvisorsRequest} from "../../actions/MyAdvisorsRequest";
import MUIDataTable from "mui-datatables";
import SectionGrid from "../../layout/SectionGrid";
import TableCellItem from "../../components/TableCellItem";
import PowerIcon from '@mui/icons-material/Power';
import PowerOffIcon from '@mui/icons-material/PowerOff';
import DeleteIcon from "@mui/icons-material/Delete";


/**
 * @param props
 * @param employer_id
 * @param advisor_id
 */
function handleDeleteUserData(props, employer_id, advisor_id) {
	const deleteobject = {
		emp_id: employer_id,
		adv_id: advisor_id,
	};
	props.dispatch(deleteAdvisorsRequest(deleteobject));
}

/**
 * @param props
 * @param action
 * @param employer_id
 * @param advisor_id
 * @param authorization
 */
function handleUpdateUserData(props, action, employer_id, advisor_id, authorization) {
	const updateobject = {
		emp_id: employer_id,
		adv_id: advisor_id,
		authorization: authorization,
	};
	props.dispatch(buttonRequest(updateobject, action));
}


/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const AdvisorsRequestCard = (props = {
	data: [{
		id: null,
		email: "",
		active: false,
		title: "",
	}],
	employerId: "",
	buttonRequest: (data: null, action: string = "") => {},
	deleteAdvisorsRequest: (data: null) => {},
	dispatch: null,
}): JSX.Element => {
	/**
	 * @param value
	 * @returns {JSX.Element}
	 * @constructor
	 */
	const ShowStatus = (value) => (
		<Grid>
			{
				!value && (
					<span className={"status-2"}>
						{"Disconnected"}
					</span>
				)
			}
			{
				value && (
					<span className={"status-1"}>
						{"Active"}
					</span>
				)
			}
		</Grid>
	);


	/**
	 * @param value
	 * @returns {JSX.Element}
	 */
	const renderActions = (value) => {
		const advisor = props?.data?.filter(u => u.id === value);
		const data = advisor[0];
		return (
            <Grid
				justifyContent={"center"}
				alignItems={"center"}
				container
				size={12}
			>
                <Grid
                    direction={"column"}
                    alignContent={"space-around"}
                    alignItems={"center"}
                    container
                    size={{
                        xs: 3,
                        sm: 3,
                        md: 3,
                        lg: 3
                    }}
                >
					<IconButton
						id={data.active ? "advisors-table-deny-button" : "advisors-table-accept-button"}
						onClick={() =>
							data.active ?
								handleUpdateUserData(props,
									"deny",
									props.employerId, data.id,
									false)
								:
								handleUpdateUserData(props,
									"authorize",
									props.employerId,
									data.id,
									true)
						}
					>
						{
							data.active ?
								<Toolbar
									title={"Revoke connection"}
								>
									<PowerOffIcon
										className={"advisors-table-deny-icon"}
									/>
								</Toolbar>
								:
								<Toolbar
									title={"Allow connection"}
								>
									<PowerIcon
										className={"advisors-table-accept-icon"}
									/>
								</Toolbar>
						}
					</IconButton>
				</Grid>
                {
					!data.active && (
						<Grid
                            direction={"column"}
                            alignContent={"center"}
                            alignItems={"center"}
                            className={"connection-status"}
                            container
                            size={{
                                xs: 3,
                                sm: 3,
                                md: 3,
                                lg: 3
                            }}
						>
							<IconButton
								className={"users-table-delete-icon"}
								onClick={() => {
									const confirmBox = window.confirm(
										"If you delete this request, this advisor will no longer be able to access your Collegia account"
									)
									if (confirmBox === true) {
										handleDeleteUserData(props, props.employerId, data.id);
									}
								}}
							>
								<DeleteIcon/>
							</IconButton>
						</Grid>
					)
				}
            </Grid>
        );
	};

	const columns = [
		{
			name: 'title',
			label: "NAME",
			options: {
				filter: true,
				sort: true,
				setCellProps: () => ({align: 'center'}),
				customBodyRender: (value) =>
					<TableCellItem
						title={value}
					/>,
			},
		},
		{
			name: 'email',
			label: 'E-MAIL',
			options: {
				filter: true,
				sort: true,
				setCellProps: () => ({align: 'center'}),
				customBodyRender: (value) =>
					<TableCellItem
						subtitle={value}
					/>,
			}
		},
		{
			name: "active",
			label: 'STATUS',
			options: {
				filter: true,
				sort: true,
				setCellProps: () => ({align: 'center'}),
				customBodyRender: (value) =>
					ShowStatus(value),
			}
		},
		{
			name: "id",
			label: 'ACTIONS',
			options: {
				filter: true,
				sort: true,
				setCellProps: () => ({align: 'center'}),
				customBodyRender: (value) =>
					renderActions(value),
			}
		},
	];

	return (
		<SectionGrid>
			<MUIDataTable
				className={"data-table users-table"}
				columns={columns}
				data={props?.data}
				options={{
					filter: false,
					selectableRowsHeader: false,
					selectableRows: "none",
					responsive: "simple",
					viewColumns: false,
					print: false,
					download: false,
					elevation: 0,
				}}
				pagination
			/>
		</SectionGrid>
	);
}

export default AdvisorsRequestCard;