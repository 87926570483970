import React from 'react';
import Button from "@mui/material/Button";
import Grid from '@mui/material/Grid2';
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import {createAutoCorrectedDatePipe} from "../../../utils/Helpers";
import MaskedInput from "react-text-mask";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

/**
 * @param props
 * @returns {JSX.Element}
 * @private
 */
const _renderErrorMessage = (props: any[] = {
	errorMessage: "",
}): JSX.Element => {
	if (props) {
		const {
			errorMessage = ""
		} = props;

		if (errorMessage !== "") {
			return (
				<FormControl
					className={"Card-form"}
					fullWidth
				>
                    <span className={"error"}>
                        {errorMessage ?? ""}
                    </span>
				</FormControl>
			);
		}
	}

	return [];
}

function validateStagingDateChange(dateString) {
	const [day, month, year] = dateString.split('/').map(Number);

	const isValidDate = (day, month, year) => {
		if (year < 1900) return false;
		const date = new Date(year, month - 1, day);
		return (
			date instanceof Date && !isNaN(date) &&
			date.getDate() === day &&
			date.getMonth() === month - 1 &&
			date.getFullYear() === year
		);
	};

	return !isValidDate(day, month, year);
}

/**
 * @param props
 * @returns {*}
 * @constructor
 */
export default function BusinessStagingDate(props) {
	if (!props) return;

	return (
        <Grid
            id={"business-before-october-2017-card-container"}
            direction={"column"}
            spacing={0}
            container
            size={{
                lg: 12,
                xs: 12,
                md: 12,
                sm: 12
            }}
        >
            <Typography
				fontSize={"17px"}
				marginLeft={"1.5%"}
				marginTop={"1.8%"}
			>
				You don’t know your staging date?
				<a
					href="#business-before-october-2017-card-container"
					onClick={()=> props.setStagingDateOption(!props.stagingDateOption)}
				>
					{!props.stagingDateOption ? " Click here" : " Hide"}
				</a>
			</Typography>
            <Divider className={"divider-class"}/>
            <FormControl
				className={"Card-form"}
				fullWidth
			>
				<MaskedInput
					mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
					placeholderChar={'\u2000'}
					placeholder="Staging Date (dd/mm/yyyy format)."
					className={"Card-form"}
					label="Staging Date"
					variant="outlined"
					name={"stagingDate"}
					id={"stagingDate"}
					value={props.stagingDate}
					onChange={event => props.setStagingDate(event.target.value)}
					pipe={createAutoCorrectedDatePipe("dd mm yyyy", {
						minYear: 1900,
						maxYear: 2099
					})}
				/>
			</FormControl>
            <Grid
				container
				spacing={3}
			>
				<Grid
                    display={props.stagingDateOption ?  {lg: "block"} : {xs: "none"}}
                    size={{
                        lg: 8,
                        sm: 8,
                        xs: 8,
                        md: 8
                    }}
				>
					<FormControl
						className={"Card-form"}
						fullWidth
					>
						<TextField
							type={"text"}
							onChange={event => props.setTaxOfficeNumber(event.target.value)}
							label="Tax Office Number"
							variant="outlined"
							name={"taxOfficeNumber"}
							id={"taxOfficeNumber"}
							value={props.taxOfficeNumber}
						/>
						<small>
							Payer Scheme Reference (example: 123/AB45678)
						</small>
					</FormControl>
				</Grid>

				{_renderErrorMessage(props ?? false)}

				<Grid
                    display={props.stagingDateOption ?  {lg: "block"} : {xs: "none"}}
                    size={{
                        lg: 4,
                        sm: 4,
                        xs: 4,
                        md: 4
                    }}
				>
					<FormControl
						className={"Card-form"}
						fullWidth
					>
						<Button
							variant="contained"
							color="primary"
							onClick={() =>
								props.searchStagingDate(
									props.taxOfficeNumber,
									props.getStagingDateByApiAction
								)}
							disabled={!props.taxOfficeNumber || props.payer_loading}
						>
							{!props.payer_loading ? "Look Up" : "Searching"}
						</Button>
					</FormControl>
				</Grid>

			</Grid>
            <FormControl
				className={"Card-form"}
				fullWidth
			>
				<Button
					variant="contained"
					color="primary"
					onClick={() =>
						props.updateStagingDate({
							employers: {
								company_number: props.company_number,
								stagingDate: props.stagingDate,
								setup_complete: props.isPushPayment,
							},
						})
					}
					disabled={
						!props.stagingDate ||
						props.payer_loading ||
						validateStagingDateChange(props.stagingDate)
					}
				>
					SAVE
				</Button>
			</FormControl>
        </Grid>
    );
}