import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Wrapper from "../layout/Logged/Wrapper";
import LoadingPanel from "../components/LoadingPanel";
import PrimaryHeader from "../components/PrimaryHeader";
import TabPanel from "../components/MainPage/TabPanel";
import Grid from '@mui/material/Grid2';
import Button from '@mui/material/Button';
import * as Profile from "../actions/Profile";
import {uploadDdiFile} from "../actions/Profile";
import Dropzone from "react-dropzone";
import Delete from "@mui/icons-material/Delete";
import Typography from "@mui/material/Typography";
import UploadDNDDrawing from '../images/UploadDNDDrawing.svg';
import ProcessingFileDdi from '../images/ProcessingFileDdi.svg';
import PdfIcon from "../images/PdfIcon.svg";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {warn} from "../actions/SnackBar";
import UploadBankDdiFileModal from "../components/UploadBankDdiFileModal";
import {useNavigate} from "react-router-dom";


const acceptedPdfFormats = [
	".pdf"
].join(',');

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const UploadBankDdiFile = (props = {
	history: () => {},
	showSandwich: true,
	notifications: [],
	headerLink: "home",
	modalBody: {},
	changeInfo: {},
	visualizeNotification: () => {}
}): JSX.Element => {
	const {
		profile_data,
		profile_loading,
	} = useSelector(state => state?.Profile);
	const initialState = {alt: "", srcPdf: null};
	const [{alt, srcPdf}, setPreview] = useState(initialState);
	const [isLoading, setIsLoading] = useState(false);
	const push = useNavigate();


	const handleDrop = event => {
		const files = event;
		let file_size = files[0].size;
		if (file_size > 3145728) {
			dispatch(warn("The file must be less than 3 Megabytes"));
		} else {
			if (files.length > 0) {
				setPreview(
					files.length
						?
						{
							srcPdf: files[0],
							alt: files[0].name
						}
						: initialState
				);
			}
		}
	};

	const changeInfo = props.history;

	let dispatch = useDispatch();

	const [open, setOpen] = React.useState(false);
	const handleModal = (open) => {
		setOpen(!open);
	}


	useEffect(() => {
		dispatch(Profile.me());
	}, [
		dispatch,
	]);

	if (!profile_data || profile_loading) {
		return (
			<Wrapper>
				<LoadingPanel/>
			</Wrapper>
		);
	}

	return (
		<Wrapper
			history={props?.history}
			showSandwich={props?.showSandwich}
			notifications={props?.notifications}
			headerLink={props?.headerLink}
			visualizeNotification={props?.visualizeNotification}
		>
			<TabPanel
				spacing={0}
				className={"main-page-container-wrapper"}
				id={"new-company-info-logged-wrapper"}
				value={1}
				index={1}
			>
				<PrimaryHeader
					changeInfo={changeInfo}
					backPage={'/main-page'}
				>
					Select Your Bank
				</PrimaryHeader>

				{profile_data?.employer?.ddi_pdf_link ?
					<Grid
						className={"upload-bank-ddi-file"}
						marginBottom={0}
					>
						<Grid
							container
							justifyContent={"center"}
							size={12}
						>
							<Typography
								variant={"body1"}
								className={"upload-bank-ddi-file-processing-file"}
							>
								We are processing your file, you will receive an email once it is done.
							</Typography>
							<img
								alt=""
								src={ProcessingFileDdi}
								className={"ddi-file-upload-img-size"}
							/>
						</Grid>
						<Grid
							container
							justifyContent={"flex-end"}
							size={12}
						>
							<Button
								color="primary"
								variant="contained"
								className={"company-info-buttons-refresh"}
								onClick={() => push("/")}
							>
								Go back to the dashboard
							</Button>
						</Grid>
					</Grid>
					:
					<Grid
						className={"upload-bank-ddi-file"}
					>

						<Grid
							container
							size={12}
						>
							{
								isLoading ?
									<LoadingPanel/>
									:
									<Dropzone
										accept={acceptedPdfFormats}
										onDrop={e => handleDrop(e)}
									>
										{({getRootProps, getInputProps}) => (
											<Grid
												container
												size={12}
												justifyContent={"center"}
												className={!srcPdf ? "drag-and-drop-bank-ddi-file" : "upload-logo-no-hover"}
												{...getRootProps()}
											>
												<Grid
													container
													size={12}
													justifyContent={"center"}
												>
													<Typography
														variant={"body1"}
														noWrap
													>
														{srcPdf ? "" : "Upload your completed DDI file here"}
													</Typography>
												</Grid>

												<input
													{...getInputProps()}
													onChange={ e => handleDrop(e.target.files) }
													disabled={srcPdf ? "disabled" : ""}
												/>
												<img
													alt=""
													src={!srcPdf ? UploadDNDDrawing : PdfIcon}
													className={!srcPdf ?
														"upload-bank-ddi-file-img-drawing"
														:
														""
													}
												/>
												<Grid
													container
													size={12}
													justifyContent={"center"}
												>
													{
														!srcPdf ?
															<Typography
																variant={"body1"}
																noWrap
																className={"company-info-drag-and-drop"}
															>
																Drag and drop your file here
															</Typography>
															:
															<Typography
																variant={"body1"}
																className={"company-info-img-name"}
																noWrap
															>
																{alt + ".pdf"}
															</Typography>
													}
													{srcPdf ?
														<Delete
															className={"upload-bank-ddi-deleted"}
															onClick={() =>
																setPreview({
																	srcPdf: null,
																	alt: ""
																})
															}
														/>
														:
														""
													}
												</Grid>
											</Grid>
										)}
									</Dropzone>
							}
						</Grid>

						<Grid
							container
							size={12}
							justifyContent={"flex-end"}
							marginTop={2}
						>
							{
								open &&
									<UploadBankDdiFileModal
										open={open}
										handleModal={handleModal}
									/>
							}
							<Button
								color="primary"
								variant="contained"
								className={"company-info-buttons-refresh"}
								onClick={() =>
									dispatch(uploadDdiFile(
										{pdf: srcPdf},
										profile_data?.is_external_advisor,
										profile_data?.employer?.setup_complete,
										setIsLoading
									))}
							>
								Send
							</Button>
						</Grid>
					</Grid>
				}
			</TabPanel>
			{
				!profile_data?.employer?.ddi_pdf_link ?
					<Grid
						container
						size={12}
						justifyContent={"center"}
					>
						<Grid
							className={"btn-download-ddi-file"}
						>
							<Button
								color="primary"
								variant="outlined"
								className={"company-info-buttons-refresh"}
								onClick={() => window.open(process.env.REACT_APP_API + "download-ddi-file")}
							>
								<FileDownloadIcon
									className={"company-info-buttons-refresh"}
								/>
								Download the DDI file
							</Button>
						</Grid>
						{
							!profile_data?.is_external_advisor ?
								<Grid
									className={"fill-online"}
								>
									<Button
										color="primary"
										variant="contained"
										className={"company-info-buttons-refresh"}
										onClick={() => handleModal(open)}
									>
										Fill Online
									</Button>
								</Grid>
								:
								<Grid
									className={"fill-online"}
								>
									<Button
										color="primary"
										variant="contained"
										className={"company-info-buttons-refresh"}
										onClick={() => dispatch(Profile.sendEmail({
											'employers': {
												'email': profile_data?.employer?.primary_email,
												'id': profile_data?.id,
												'title': profile_data?.employer?.title,
											}
										}))}
									>
										Send DDI Form to employer
									</Button>
								</Grid>
						}
					</Grid>
					:
					""
			}
		</Wrapper>
	);
}

export default UploadBankDdiFile;