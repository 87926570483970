import Login from './Login';
import SelectService from "./SelectService";
import SignUp from './SignUp';
import ForgotPassword from "./ForgotPassword";
import NotFound from "./NotFound";
import PasswordRequestSent from "./PasswordRequestSent";
import ResetPassword from "./ResetPassword";
import DirectDebit from "./DirectDebit/index";
import Page_403 from "./403";

// logged in pages
import MainPage from "./MainPage";
import ActivateAccount from "./ActivateAccount";
import PayrollSoftware from "./PayrollSoftware";
import Logout from "./Logout";
import Users from "./Users";
import Authorization from './Authorization';
import MyAccount from './MyAccount';
import ChangeInfo from './ChangeInfo';
import ChangeTradingAddress from './ChangeTradingAddress';
import ChangeEmail from './ChangeEmail';
import ChangePassword from './ChangePassword';
import MyAdvisors from "./MyAdvisors/MyAdvisors";
import PapdisDetails from "./PapdisDetails";
import UploadBankDdiFile from "./UploadBankDdiFile";
import Materials from "./Materials"
import Benefits from "./Benefits";
import EmailPreferences from "./EmailPreferences";
import InviteEmployees from "./InviteEmployees/InviteEmployees";
import MyIFA from "./MyIFA/MyIFA";
import EmployeesCommunications from "./EmployeesCommunications";
import ApiPapdisDetails from "./ApiPapdisDetails";
import ApiUploads from "./ApiUploads";
import DebitInformation from "./DebitInformation";

/**
 * @type {{SignUp: (*), Login: (*), SelectService: (*), ForgotPassword: (*)}}
 */
const Pages = {
	Page_403,
	Login,
	SelectService,
	SignUp,
	ForgotPassword,
	NotFound,
	PasswordRequestSent,
	ResetPassword,
	DirectDebit,

	// logged in pages
	MainPage,
	ActivateAccount,
	Logout,
	Users,
	MyAdvisors,
	PayrollSoftware,
	Authorization,
	MyAccount,
	ApiUploads,
	ChangeInfo,
	ChangeTradingAddress,
	ChangeEmail,
	ChangePassword,
	PapdisDetails,
	ApiPapdisDetails,
	UploadBankDdiFile,
	Materials,
	Benefits,
	EmailPreferences,
	InviteEmployees,
	MyIFA,
	EmployeesCommunications,
	DebitInformation,
}

export default Pages;