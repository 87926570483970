import React from 'react';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import Grid from '@mui/material/Grid2';
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";

/**
 * @param props
 * @returns {JSX.Element|*[]}
 * @constructor
 */
export default function AdvisorsCard(props): JSX.Element | *[] {
	if (props) {

		return (
			<Card
				id={"advisors-card-container"}
				onClick={() => window.location.href = process.env.REACT_APP_ADVISOR_DEFAULT_APP_LINK + "/sign-up-advisor"}
				className={"card-size-equal"}
			>
				<CardHeader
					avatar={
						<Typography gutterBottom className={"MuiCardHeader-avatar"}/>
					}
					title={"For Advisors"}
					subheader={"Sign up as an advisor"}
				/>
				<CardContent className={"MuiCard-content"}>
					<Typography gutterBottom>
						If you're either a payroll bureau or an accounting firm, we can help you set up a great Auto
						Enrolment scheme for your clients.
					</Typography>
				</CardContent>
				<CardActions>
					<Grid
						size={6}
						container
					>
						<Grid
							size={6}
							alignContent={"flex-start"}
							direction={"column"}
							container
						>
							<Button className={"MuiCardHeader-button"}>SIGN UP</Button>
						</Grid>
					</Grid>
				</CardActions>
			</Card>
		);
	}

	return [];
}