import React, {useEffect, useState} from 'react';

import {connect} from "react-redux";
import {
	cancelDirectDebitSigning,
	cancelPrepareEmandate,
	confirmDirectDebit,
	details,
	verifyIfTheMandateFinish
} from "../../actions/DirectDebit";
import Wrapper from "../../layout/Logged/Wrapper";
import Grid from '@mui/material/Grid2';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import {retrieveCollegiaAccessToken} from "../../utils/Helpers";
import {me} from "../../actions/Profile";
import {withRouter} from "../../utils/withRouter";
import {useNavigate} from "react-router-dom";
import PrimaryHeader from "../../components/PrimaryHeader";
import Loading from "../../components/Loading";
import LoadingPanel from "../../components/LoadingPanel";

const DirectDebitDetails = ({
	profile_data = [],
	profile_loading = false,
	me = () => {},
	cancelPrepareEmandate = () => {},
	verifyIfTheMandateFinish = () => {},
	verify_emandate_loading = false,
	prepare_emandate_loading = false,
}): JSX.Element => {
	const push = useNavigate();
	const [link, setLink] = useState("");

	useEffect(() => {
		me();
	}, [me]);

	useEffect(() => {
		const prepareEmandateId = profile_data.employer.prepare_emandate?.id;
		const verifyAndSetInterval = () => {
			verifyIfTheMandateFinish(prepareEmandateId);
		};
		verifyAndSetInterval();
		const intervalId = setInterval(verifyAndSetInterval, 2000);
		return () => clearInterval(intervalId);

	}, [profile_data.employer.prepare_emandate?.id, verifyIfTheMandateFinish]);

	useEffect(() => {
		if (profile_data.employer.prepare_emandate?.active) {
			if (process.env.REACT_APP_ENV === "production") {
				setLink("https://api.nuapay.com/tpp-ui/emandate-ui/redirect?conversationToken=" + profile_data.employer.prepare_emandate?.token);
			} else {
				setLink("https://sandbox.nuapay.com/tpp-ui/emandate-ui/redirect?conversationToken=" + profile_data.employer.prepare_emandate?.token);
			}
		}
	}, [setLink, profile_data]);

	if (!retrieveCollegiaAccessToken()) {
		push("/login");
	}

	if (profile_loading || prepare_emandate_loading) {
		return (
			<Loading/>
		);
	}

	return (
        <Wrapper>
            <Slide in={true} direction={"up"}>
				<Grid>
					<Grid
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        alignContent={"center"}
                        id={"direct-debit-contribution-container"}
                        container
                        size={12}
					>
						<PrimaryHeader>
							Finish the e-mandate
						</PrimaryHeader>
						<Paper
							id={"direct-debit-container"}
						>
							<Grid
                                alignContent={"center"}
                                direction={"column"}
                                container
                                size={{
                                    xs: 12,
                                    sm: 12,
                                    lg: 12,
                                    md: 12
                                }}
							>
								<Typography
									className={"main-title"}
									variant={"h5"}
									align={"center"}
								>
									{"Lost the link to your Direct Debit?"}
								</Typography>
								<Typography
									className={"main-title"}
									variant={"h5"}
									align={"center"}
								>
									{"Click "}
									<a href={link} target={"_blank"} rel={"noopener noreferrer"}>here</a>
									{" to be redirected "}
								</Typography>
								<Typography
									className={"main-title"}
									variant={"h5"}
									align={"center"}
								>
									or click
									<span
										className={"span-red"}
										onClick={() => cancelPrepareEmandate(profile_data.employer.prepare_emandate?.id)}
									>
										{" here "}
									</span>
									to cancel the direct debit.
								</Typography>
							</Grid>
							{
								verify_emandate_loading ?
									<Grid
										container
										size={12}
										alignItems={"center"}
										alignContent={"center"}
									>
										<LoadingPanel/>
									</Grid>
									:
									<></>
							}
						</Paper>
					</Grid>
				</Grid>
			</Slide>
        </Wrapper>
    );
}

const mapStateToProps = state => {
	const {
		DirectDebit = [],
		Profile = [],
	} = state;

	return {
		...DirectDebit,
		...Profile,
	};
}

/**
 * @param dispatch
 * @returns {{details: (function(string=): *)}}
 */
const mapDispatchToProps = dispatch => ({
	details: (orderId: string = "", scrollUp: boolean = false) => dispatch(details(orderId ?? "", scrollUp)),
	me: () => dispatch(me(true, false)),
	confirmDirectDebit: (data:any = false) => dispatch(confirmDirectDebit(data ?? false)),
	cancelDirectDebitSigning: (endToEnd:string = "") => dispatch(cancelDirectDebitSigning(endToEnd ?? "")),
	cancelPrepareEmandate: (id: number) => dispatch(cancelPrepareEmandate(id)),
	verifyIfTheMandateFinish: (id) => dispatch(verifyIfTheMandateFinish(id)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DirectDebitDetails));