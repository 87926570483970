import React from "react";

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {renderReferralData} from "../utils/Helpers";

/**
 * @param props
 * @returns {JSX.Element}
 */
const GenericTermsAndConditions = (props = {
	referral: {},
	token: "",
	admins: [],
	formValues: [],
	directorList: [],
	setup_data: [],
	company: [],
	accordion: {},
	setAccordion: [],
	agreedTermsAndConditions: () => {},
	handleChange: () => {},
	signUp: () => {},
	proceedStep: () => {},
	backStep: () => {},
}): JSX.Element => (
	<Grid
        alignItems={"center"}
        alignContent={"center"}
        direction={"column"}
        container
	>
		<Grid className={"dialog-without-padding dialog-without-border-top"}>
			{renderReferralData(props?.referral ?? {})}
			<Grid
                direction="column"
                alignItems="center"
                justifyContent="center"
                className={"big-picture-title-container"}
                container
                size={{
                    lg: 12
                }}
			>
				<Typography
					variant={"subtitle2"}
					component={"p"}
				>
					The big picture:
				</Typography>
			</Grid>
			<Grid
                direction="row"
                className={"big-picture-container"}
                container
                size={{
                    lg: 12
                }}
			>
				<Accordion
					expanded={
						props?.accordion?.section === 'What does Collegia do?' &&
						props?.accordion?.expanded
					}
					onChange={() =>
						props.handleChange(
							'What does Collegia do?',
							props.accordion,
							props.setAccordion
						)}
					className={"expansion-panel"}
				>
					<AccordionSummary
						expandIcon={
							<ExpandMoreIcon/>
						}
						aria-controls="panel1bh-content"
						id="panel1bh-header"
					>
						<Typography>What does Collegia do?</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography
							component={"p"}
							variant={"body1"}
						>
							Collegia is a personal pension scheme registered with HMRC.
							It can be used as an Automatic Enrolment scheme to provide
							benefits to eligible workers for the purposes of Automatic
							Enrolment.
							<br/>
							<br/>
							Collegia does not provide pensions, financial, regulatory,
							tax, investment, payroll, accounting or business advisory
							services. If you have any questions about these topics, you
							must refer to your own trusted business advisors.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion
					expanded={
						props?.accordion?.section === 'What do you need to provide Collegia with?' &&
						props?.accordion?.expanded
					}
					onChange={() =>
						props.handleChange(
							'What do you need to provide Collegia with?',
							props.accordion,
							props.setAccordion
						)}
					className={"expansion-panel"}
				>
					<AccordionSummary
						expandIcon={
							<ExpandMoreIcon/>
						}
						aria-controls="panel2bh-content"
						id="panel2bh-header"
					>
						<Typography>
							What do you need to provide Collegia with?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography
							component={"p"}
							variant={"body1"}
						>
							We require the workforce’s data and information that is
							necessary for us to operate the pension scheme. This data
							and information must be accurate, complete and updated
							within reasonable timescales. The information must be
							provided in a format required by Collegia – either via
							direct API integration with your payroll software or CSV
							upload. The assessment of the workforce under the Automatic
							Enrolment law is your responsibility.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion
					expanded={
						props?.accordion?.section === 'How much does Collegia charge me?' &&
						props?.accordion?.expanded
					}
					onChange={() =>
						props.handleChange(
							'How much does Collegia charge me?',
							props.accordion,
							props.setAccordion
						)}
					className={"expansion-panel"}
				>
					<AccordionSummary
						expandIcon={
							<ExpandMoreIcon/>
						}
						aria-controls="panel3bh-content"
						id="panel3bh-header"
					>
						<Typography>
							How much does Collegia charge me?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography
							component={"p"}
							variant={"body1"}
						>
							£0, for every company in the UK. We reserve the right to
							charge you for costs and expenses in case of incomplete
							and inaccurate information provided by you in relation to
							the management of the pension scheme.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion
					expanded={
						props?.accordion?.section === 'How much am I contributing to my workforce?' &&
						props?.accordion?.expanded
					}
					onChange={() =>
						props.handleChange(
							'How much am I contributing to my workforce?',
							props.accordion,
							props.setAccordion
						)}
					className={"expansion-panel"}
				>
					<AccordionSummary
						expandIcon={
							<ExpandMoreIcon/>
						}
						aria-controls="panel4bh-content"
						id="panel4bh-header"
					>
						<Typography>
							How much am I contributing to my workforce?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography
							component={"p"}
							variant={"body1"}
						>
							You have to follow the rules set by Automatic Enrolment
							legislation. Currently, the minimum is set at 8% of your
							employee’s earnings. You, the employer, must pay at least
							3% of this, but you can choose to pay more. For more
							information, please check The Pension Regulator’s website.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion
					expanded={
						props?.accordion?.section === 'Can I stop using Collegia?' &&
						props?.accordion?.expanded
					}
					onChange={() =>
						props.handleChange(
							'Can I stop using Collegia?',
							props.accordion,
							props.setAccordion
						)}
					className={"expansion-panel"}
				>
					<AccordionSummary
						expandIcon={
							<ExpandMoreIcon/>
						}
						aria-controls="panel5bh-content"
						id="panel5bh-header"
					>
						<Typography>
							Can I stop using Collegia?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography
							component={"p"}
							variant={"body1"}
						>
							You can tell us at any time, via the employer dashboard,
							that you do not wish to use Collegia anymore. However,
							contributions for the pay period in which you cancel are
							required to be paid.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion
					expanded={
						props?.accordion?.section ===
						'What happens to contributions that I have paid into another pension scheme?' &&
						props?.accordion?.expanded
					}
					onChange={() =>
						props.handleChange(
							'What happens to contributions that I have paid into another pension scheme?',
							props.accordion,
							props.setAccordion
						)}
					className={"expansion-panel"}
				>
					<AccordionSummary
						expandIcon={
							<ExpandMoreIcon/>
						}
						aria-controls="panel6bh-content"
						id="panel6bh-header"
					>
						<Typography>
							What happens to contributions that I have paid into another pension scheme?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography
							component={"p"}
							variant={"body1"}
						>
							Your employees can request them to be transferred to
							Collegia or other pension providers. If they decide to
							do nothing, these contributions will continue to be managed
							by the other pension provider.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion
					expanded={
						props?.accordion?.section === 'How do I pay contributions?' &&
						props?.accordion?.expanded
					}
					onChange={() =>
						props.handleChange(
							'How do I pay contributions?',
							props.accordion,
							props.setAccordion
						)}
					className={"expansion-panel"}
				>
					<AccordionSummary
						expandIcon={
							<ExpandMoreIcon/>
						}
						aria-controls="panel7bh-content"
						id="panel7bh-header"
					>
						<Typography>
							How do I pay contributions?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography
							component={"p"}
							variant={"body1"}
						>
							We do all the heavy lifting for you. Our systems
							automatically set up a direct debit instruction and
							collect the contributions based on the information you
							have provided. Please remember that we only collect
							contributions for employees whose opt out period (30 days)
							has lapsed. This means that contributions for newly
							enrolled employees will be collected only 30 days after
							their enrolment.
						</Typography>
					</AccordionDetails>
				</Accordion>
			</Grid>
			<Grid
                className={"terms-and-conditions-buttons-container"}
                alignContent={"center"}
                alignItems={"center"}
                direction={"column"}
                container
                size={{
                    lg: 12
                }}
			>
				<Grid
                    container
                    size={{
                        lg: 12
                    }}
				>
					<Button
						color={"primary"}
						variant={"contained"}
						className={'primary'}
						onClick={() =>
							props.agreedTermsAndConditions(
								props.referral,
								props.token,
								props.admins,
								props.directorList,
								props.formValues,
								props.setup_data,
								props.company,
								props.proceedStep,
								props.backStep,
								props.signUp,
							)
						}
					>
						I've read and accept it
					</Button>
					<Grid
                        container
                        size={{
                            lg: 12
                        }}
					>
						<Button
							type={"button"}
							color={"primary"}
							variant={"outlined"}
							className={"opt-out"}
							onClick={() =>
								window.location.href = "/login"
							}
						>
							I don't accept it
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	</Grid>
);

/**
 * @stateless
 * @param props
 * @returns {JSX.Element}
 */
export default GenericTermsAndConditions;