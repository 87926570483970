import Wrapper from "../layout/Logged/Wrapper";
import SectionGrid from "../layout/SectionGrid";
import React, {useEffect, useState} from "react";
import {validateEmail} from "../utils/Helpers";
import {connect} from "react-redux";
import Grid from "@mui/material/Grid2";
import {deleteEmailConfiguration, me, updateEmailConfiguration} from "../actions/Profile";
import Loading from "../components/Loading";
import PrimaryHeader from "../components/PrimaryHeader";
import DeleteIcon from '@mui/icons-material/Delete';
import MUIDataTable from "mui-datatables";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import {ThemeProvider} from "@mui/styles";
import {createTheme} from "@mui/material/styles";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import {ModalContents} from "../utils/ModalContents";
import {getEmailPreferenceTypes} from "../actions/EmailPreferenceTypes";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

export const theme = createTheme({
	breakpoints: {
		md: "0px",
	},
});
/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const EmailPreferences = (
	{
		history = {
			push: () => {}
		},
		profile_loading = false,
		visualizeNotification = () => {},
		notifications = [],
		profile_data = {
			email_configurations: [],},
		updateEmailConfiguration = () => {},
		deleteEmailConfiguration = () => {},
		getEmailPreferenceTypes,
		emailPreferenceTypes = [],
	}
): JSX.Element => {
	const [openCreateEdit, setOpenCreateEdit] = useState(false),
		[openDelete, setOpenDelete] = useState(false),
		[id, setId] = useState(0),
		[email_preference_type_id, setEmailPreferenceTypeId] = useState(0),
		[email, setEmail] = useState(""),
		[errorMessage, setErrorMessage] = useState("");

	const columns = [
		{
			name: 'email',
			label: 'E-MAIL',
			options: {
				filter: true,
				sort: false,
				setCellProps: () => ({align: 'center'}),
				setCellHeaderProps: () => ({align: 'center'}),
				customBodyRender: (value) => value
			}
		},
		{
			name: 'email_preference_type',
			label: 'Notification type',
			options: {
				filter: true,
				sort: false,
				setCellProps: () => ({align: 'center'}),
				setCellHeaderProps: () => ({align: 'center'}),
				customBodyRender: (value) => value.name
			}
		},
		{
			name: "id",
			label: 'ACTIONS',
			options: {
				filter: true,
				sort: false,
				setCellProps: () => ({align: 'center'}),
				setCellHeaderProps: () => ({align: 'center'}),
				customBodyRender: (value, tableMeta) =>
					<Grid>
						<IconButton
							className={"users-table-edit-icon"}
							onClick={() => {
								setId(value);
								setEmail(tableMeta.rowData[0]);
								setEmailPreferenceTypeId(tableMeta.rowData[1].id);
								setOpenCreateEdit(true);
							}}
						>
							<EditIcon/>
						</IconButton>
						<IconButton
							className={"users-table-delete-icon"}
							onClick={() => {
								setId(value);
								setOpenDelete(true);
							}}
						>
							<DeleteIcon color={"error"}/>
						</IconButton>
					</Grid>
			}
		},
	];

	const AddNewDirector = () => (
		<IconButton
			onClick={() => setOpenCreateEdit(true)}
		>
			<PersonAddIcon/>
		</IconButton>
	);


	useEffect(() => {
		const fetchGetEmailPreferenceTypes = async () => {
			await getEmailPreferenceTypes();
		};
		fetchGetEmailPreferenceTypes().then(r => r);
	}, [getEmailPreferenceTypes]);

	if (profile_loading) {
		return (
			<Loading/>
		);
	}

	return (
        <Wrapper
			history={history}
			showSandwich={true}
			notifications={notifications}
			visualizeNotification={visualizeNotification}
		>
            <SectionGrid id={"my-account-content"}>
				<Grid
					id={"menu-logged-wrapper"}
					justifyContent={"center"}
					container
					size={12}
					minHeight={2}
					width={"100%"}
				>
					<PrimaryHeader
						changeInfo={history}
						helpModal={ModalContents.email_preference_help}
						backIcon
						helpIcon
						backPage={'/main-page'}
					>
						Email Preferences
					</PrimaryHeader>
					<Grid
                        container
                        marginBottom={"30px"}
                        width={"100%"}
                        size={{
                            xs: 12,
                            sm: 12,
                            lg: 12,
                            md: 12
                        }}
					>
						<ThemeProvider theme={theme}>
							<MUIDataTable
								className={"data-table data-table-width"}
								columns={columns}
								data={profile_data?.employer?.email_configurations}
								options={{
									selectableRowsHeader: false,
									selectableRows: "none",
									responsive: "simple",
									viewColumns: false,
									customToolbar: AddNewDirector,
								}}
							/>
						</ThemeProvider>
						<Dialog
							open={openCreateEdit}
							onClose={() => {
								setEmail("");
								setId(0);
								setOpenCreateEdit(false);
							}}
							sx={{'& .MuiDialog-paper': {width: '80%', maxHeight: 435}}}
						>
							<DialogTitle id={"alert-dialog-title"}>
								Please select which notification should be
							</DialogTitle>
							<DialogContent>
								<Grid>
									<Grid
										marginTop={"10px"}
									>
										<FormControl
											margin={"dense"}
											fullWidth
										>
											<InputLabel id="email_preference_type_id">Preference type</InputLabel>
											<Select
												labelId="email_preference_type_id"
												id="email_preference_type_id"
												label="Notification type"
												value={email_preference_type_id}
												onChange={event => setEmailPreferenceTypeId(
													parseInt(event.target.value)
												)}
												variant={"filled"}
											>

												<MenuItem
													key={0}
													value={0}
													disabled
													selected
												>
													Please select
												</MenuItem>
												{
													emailPreferenceTypes.map(e => (
														<MenuItem
															key={e.id}
															value={e.id}
														>
															{e.name}
														</MenuItem>
													))
												}
											</Select>
										</FormControl>
										<FormControl
											margin={"dense"}
											fullWidth
										>
											<TextField
												value={email}
												onChange={(event) => setEmail(event.target.value)}
												label={'Email'}
												fullWidth
											/>
										</FormControl>
										{
											errorMessage.length > 0
												? <Typography color={"red"} fontSize={12}>
													{errorMessage}
												</Typography>
												: ""
										}
									</Grid>
								</Grid>
							</DialogContent>
							<DialogActions>
								<Button
									autoFocus
									onClick={() => {
										setId(0);
										setEmail("");
										setErrorMessage("");
										setOpenCreateEdit(false);
									}}
								>
									CANCEL
								</Button>
								<Button
									autoFocus
									onClick={() => {
										if (validateEmail(email)) {
											id !== 0
												? updateEmailConfiguration({
													email_configurations: {
														opt_out_email: email,
														id: id,
														email_preference_type_id,
													},
												})
												: updateEmailConfiguration({
													email_configurations: {
														opt_out_email: email,
														email_preference_type_id,
													},
												});
											setErrorMessage("");
											setId(0);
											setEmail("");
											setOpenCreateEdit(false);
										} else {
											setErrorMessage("Please, type a valid email");
										}

									}}
								>
									{id === 0 ? "Create" : "Update"}
								</Button>
							</DialogActions>
						</Dialog>
						<Dialog
							open={openDelete}
							onClose={() => {
								setEmail("");
								setId(0);
								setOpenCreateEdit(false);
							}}
							aria-labelledby={"alert-dialog-title"}
							aria-describedby={"alert-dialog-description"}
						>
							<DialogTitle id={"alert-dialog-title"}>
								Delete this email?
							</DialogTitle>
							<DialogActions>
								<Button onClick={() => {
									setId(0);
									setOpenDelete(false);
								}}>No</Button>
								<Button onClick={() => {
									deleteEmailConfiguration(id);
									setId(0);
									setOpenDelete(false);
								}} autoFocus>
									Yes
								</Button>
							</DialogActions>
						</Dialog>
					</Grid>
				</Grid>
			</SectionGrid>
        </Wrapper>
    );
};

const mapStateToProps = state => {
	return {
		...state.Profile,
		...state.EmailPreferenceType,
	};
};

const mapDispatchToProps = dispatch => ({
	me: dispatch(me()),
	updateEmailConfiguration: (data = {}) => dispatch(updateEmailConfiguration(data)),
	deleteEmailConfiguration: (data = {}) => dispatch(deleteEmailConfiguration(data)),
	getEmailPreferenceTypes: () => dispatch(getEmailPreferenceTypes()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmailPreferences);