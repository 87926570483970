import Wrapper from "../layout/Logged/Wrapper";
import {useNavigate, useParams} from "react-router-dom";
import {me} from "../actions/Profile";
import {connect} from "react-redux";
import MUIDataTable from "mui-datatables";
import React, {useEffect} from "react";
import {getEmployeeCommunications} from "../actions/Employer";
import Loading from "../components/Loading";
import Grid from "@mui/material/Grid2";
import SectionGrid from "../layout/SectionGrid";
import AccountPrimaryHeader from "../components/MyAccount/AccountPrimaryHeader";

/**
 * @param profile_data
 * @param employees_communications
 * @param is_loading
 * @param getCommunications
 * @returns {JSX.Element}
 * @constructor
 */
const EmployeesCommunications = (
	{
		employees_communications = [],
		is_loading = false,
		getCommunications = () => {},
	}
): JSX.Element => {
	const history = useNavigate();
	let id = useParams().id;

	useEffect(() => {
		getCommunications({
			employers: {
				id: id,
			}
		});
	}, [id, getCommunications]);

	const columns = [
		{
			"name": "forename",
			"label": "Forename",
		},
		{
			"name": "surname",
			"label": "Surname",
		},
		{
			"name": "email",
			"label": "EmaiL",
		},
		{
			"name": "national_insurance_number",
			"label": "NIN",
		},
		{
			"name": "status",
			"label": "Email status",
		},
		{
			"name": "sent_date",
			"label": "Sent date",
		},
		{
			"name": "description",
			"label": "Email description",
		},
	];

	if (is_loading) {
		return (
			<Loading absolute/>
		);
	}

	return (
		<Wrapper
			history={history}
		>
			<SectionGrid id={"my-account-content"}>
				<Grid
					id={"menu-logged-wrapper"}
					justifyContent={"center"}
					container
					size={12}
					minHeight={2}
					marginBottom={"40px"}
				>
					<AccountPrimaryHeader
						title={"Employees communications"}
						history={history}
						location={"/main-page"}
					/>
					<Grid width={"100%"}>
						<MUIDataTable
							className={"data-table"}
							columns={columns}
							data={employees_communications}
							options={{
								filter: false,
								selectableRowsHeader: false,
								selectableRows: "none",
								responsive: "simple",
								viewColumns: false,
							}}
							pagination
						/>
					</Grid>
				</Grid>
			</SectionGrid>
		</Wrapper>
	);
}

const mapStateToProps = state => {
	return {
		...state.Profile,
		...state.Employer,
	};
};

const mapDispatchToProps = () => dispatch => ({
	me: dispatch(me()),
	getCommunications: (data) => dispatch(getEmployeeCommunications(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmployeesCommunications);