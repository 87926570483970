import React from "react";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import SectionGrid from "../../layout/SectionGrid";
import Grid from "@mui/material/Grid2";
import MUIDataTable from "mui-datatables";
import TableCellItem from "../../components/TableCellItem";

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const UserTablePanel = (
	props: Object = {},
): JSX.Element => {

	const users = props?.users?.filter(u => u.is_external_advisor === false);

	function editUser(id) {
		const user = props?.users?.filter(u => u.id === id);
		props.onPressEditUser(user[0]);
		props.setOpen(true);
	}

	function deleteUser(id) {
		const user = props?.users?.filter(u => u.id === id);
		props.onPressDeleteUser(user[0]);
	}

	const AddNewUser = () => (
		<IconButton
			onClick={() => {
				props.onPressCreateUser();
				props.setOpen(true);
			}}
		>
			<PersonAddIcon style={{marginRight:"10px"}}/>
			<Typography style={{color:"white"}}>
				ADD USER
			</Typography>
		</IconButton>
	);


	const renderActions = (value, tableMeta) => (
		<Grid>
			{
				(
					props?.profile_data?.role?.role?.name === "Admin" ||
					props?.profile_data?.id === tableMeta?.rowData[3]
				) &&
				<IconButton
					className={"users-table-edit-icon"}
					onClick={() => editUser(value)}
				>
					<EditIcon/>
				</IconButton>
			}
			{
				props?.profile_data?.role?.role?.name === "Admin" &&
				<IconButton
					className={"users-table-delete-icon"}
					onClick={() => deleteUser(value)}
				>
					<DeleteIcon/>
				</IconButton>
			}
		</Grid>
	);

	const columns = [
		{
			name: 'forename',
			label: "NAME",
			options: {
				filter: true,
				sort: true,
				setCellProps: () => ({align: 'center'}),
				customBodyRender: (value) =>
					<TableCellItem
						title={value}
					/>,
			},
		},
		{
			name: 'role.role.name',
			label: 'ROLE',
			options: {
				filter: true,
				sort: true,
				setCellProps: () => ({align: 'center'}),
				customBodyRender: (value) =>
					<TableCellItem
						title={value}
					/>,
			}
		},
		{
			name: "",
			label: 'LAST ACTIVITY',
			options: {
				filter: true,
				sort: true,
				setCellProps: () => ({align: 'center'}),
				customBodyRender: () =>
					<TableCellItem
						title={""}
					/>,
			}
		},
		{
			name: "id",
			label: 'ACTIONS',
			options: {
				filter: true,
				sort: true,
				setCellProps: () => ({align: 'center'}),
				customBodyRender: (value, tableMeta) => renderActions(value, tableMeta),
			}
		},
	];

	return (
		<SectionGrid>
			<MUIDataTable
				className={"data-table users-table"}
				columns={columns}
				data={users}
				options={{
					filter: false,
					selectableRowsHeader: false,
					selectableRows: "none",
					responsive: "simple",
					viewColumns: false,
					elevation: 0,
					customToolbar: AddNewUser,
					enableNestedDataAccess: ".",
				}}
				pagination
			/>
		</SectionGrid>
	);
};

export default UserTablePanel;