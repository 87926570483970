import React from "react";
import Grid from "@mui/material/Grid2";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

/**
 * @returns {JSX.Element}
 * @constructor
 */
const MinimalFooter = (): JSX.Element => (
	<Grid
        alignItems={"center"}
        alignContent={"center"}
        id={"footer"}
        direction={"column"}
        container>
		<Grid
            className={"copyrights"}
            size={{
                lg: 12
            }}
		>
			<Typography
				align={"center"}
				component={"p"}
				variant={"subtitle1"}
			>
				<Link
					href={"https://www.collegia.co.uk/"}
					target={"_blank"}
				>
					© {new Date().getFullYear()} Collegia Partners Limited. All rights reserved.
				</Link>
				<br />
				All rights reserved. Oxford, United Kingdom.
			</Typography>
		</Grid>
	</Grid>
);

export default MinimalFooter;