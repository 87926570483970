import React, {useEffect, useState} from "react";
import {connect, useDispatch} from "react-redux";
import Grid from '@mui/material/Grid2';
import Wrapper from "../layout/Logged/Wrapper";
import SectionGrid from "../layout/SectionGrid";
import LoadingPanel from "../components/LoadingPanel";

import {me} from "../actions/Profile";
import AccountPrimaryHeader from "../components/MyAccount/AccountPrimaryHeader";
import {getApiPastPAPDISUploaded} from "../actions/Contributions";
import ApiPastUploadTable from "../components/MainPage/PayrollTab/ApiPastUploadTable";
import Pagination from "@mui/material/Pagination";

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ApiUploads =  ({
	profile_data = {},
	history = () => {},
	showSandwich = true,
	notifications = [],
	headerLink = "home",
	visualizeNotification = () => {},
	getApiPastPAPDISUploaded = () => {},
	papdis = {},
}): JSX.Element => {
	const dispatch = useDispatch();
	
	const [page, setPage] = useState(1);
	
	useEffect(() => {
		if (!profile_data.company_number){
			dispatch(me());
			getApiPastPAPDISUploaded(page);
		}
	}, [
		profile_data.company_number,
		dispatch,
		getApiPastPAPDISUploaded,
		page
	]);
	
	useEffect(() => {
		if (!profile_data) {
			dispatch(me())
		}
	}, [
		profile_data,
		dispatch
	]);
	
	if (profile_data.profile_loading) {
		return (
			<Wrapper>
				<LoadingPanel/>
			</Wrapper>
		);
	}
	
	return (
        <Wrapper
			history={history}
			showSandwich={showSandwich}
			notifications={notifications}
			headerLink={headerLink}
			visualizeNotification={visualizeNotification}
		>
            <SectionGrid id={"my-account-section-grid"}>
				<Grid
					id={"menu-logged-wrapper"}
					justifyContent={"center"}
					container
					size={12}
				>
					<AccountPrimaryHeader
						title={"Software Past Uploads"}
						history={history}
						location={"/main-page"}
					/>
					
					<Grid
						className={"past-upload-tablet"}
						alignContent="stretch"
						direction="column"
						justifyContent="center"
						container
						size={12}
					>
						<Grid>
							<ApiPastUploadTable
								uploads={papdis.api_uploads?.data || []}
								loading={papdis?.isLoading || papdis?.isLoadingPastUploads}
								profile_data={profile_data}
								percentage={papdis.upload_percentage}
								papdis_file_upload_id={papdis.papdis_file_upload_id}
								label={papdis.label}
							/>
						</Grid>
						<Grid container direction="row" justifyContent="center" size={12}>
							{(papdis?.api_uploads && (papdis?.api_uploads?.last_page > 1))
								&& <Grid>
									<Pagination
										onChange={(e, value) => {
											if (value !== page) {
												getApiPastPAPDISUploaded(value);
												setPage(value);
											}
										}}
										color="primary"
										count={papdis?.api_uploads?.last_page}/>
								</Grid>
							}
						</Grid>
					</Grid>
				</Grid>
			</SectionGrid>
        </Wrapper>
    );
}

const mapStateToProps = state => {
	const {
		PAPDIS,
		Profile,
	} = state;
	
	return {
		papdis: PAPDIS,
		...Profile,
	}
};

const mapDispatchToProps = dispatch => ({
	getApiPastPAPDISUploaded: (page) => dispatch(getApiPastPAPDISUploaded(page)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApiUploads);