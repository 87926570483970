import React from "react";
import {connect} from "react-redux";

import Grid from "@mui/material/Grid2";
import CardActionArea from "@mui/material/CardActionArea";
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

import PagesPrimaryHeader from "../components/PagesPrimaryHeader";
import Wrapper from "../layout/Logged/Wrapper";
import SectionGrid from "../layout/SectionGrid";
import LoadingPanel from "../components/LoadingPanel";
import {CardMedia} from "@mui/material";
import {get} from "../actions/Benefits";
import CardHeader from "@mui/material/CardHeader";


/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Benefits = ({
	history = () => {},
	benefits = {},
}): JSX.Element => {
	const cards = benefits?.data?.data;
	const loading = benefits?.data?.isLoading;
	
	if (loading) {
		return (
			<Wrapper>
				<LoadingPanel/>
			</Wrapper>
		);
	}

	return (
        <Wrapper
			history={history}
		>
            <SectionGrid>
				<Grid
					id={"menu-logged-wrapper"}
					justifyContent={"center"}
					container
					size={12}
					style={{marginBottom: 40}}
				>
					<PagesPrimaryHeader
						history={history}
						title={"Benefits"}
					/>
					<Grid
                        className={"collegia-border benefits-screen-content"}
                        justifyContent={"space-around"}
                        container
                        size={{
                            xs: 12,
                            sm: 12,
                            lg: 12,
                            md: 12
                        }}
					>
						{
							cards?.length === 0 && (
								<Grid
                                    alignItems={"center"}
                                    justifyContent={"center"}
                                    container
                                    size={{
                                        xs: 12,
                                        sm: 12,
                                        lg: 12,
                                        md: 12
                                    }}
								>
									<Typography>
										So far no benefits added, keep a look at the future.
									</Typography>
								</Grid>
							)
						}
						{
							cards?.length > 0 && (
								cards?.map((card, index) => (
									<Grid
                                        key={index}
                                        id={"benefits-cards-outside-board"}
                                        justifyContent={"center"}
                                        alignItems={"center"}
                                        container
                                        size={{
                                            xs: 12,
                                            sm: 12,
                                            lg: 12,
                                            md: 12
                                        }}
									>
										<Card className={"benefits-cards"}>
											<CardHeader
												title={card?.title}
												subheader={card?.subtitle}
											/>
											<CardContent
												className={"benefits-card-content"}
											>
												<CardMedia
													component="img"
													height="100"
													image={card?.logo}
													alt="Paella dish"
												/>
											</CardContent>
											<CardActionArea
												onClick={() => window.open(card?.link, '_blank')}
											>
												<CardContent>
													<Typography
														gutterBottom variant="h5"
														component="div"
														fontSize={16}
														color={"rgba(0, 0, 0, 0.6)"}
													>
														{card?.description}
													</Typography>

													<Typography
														className={"access-link"}
														variant="body2"
														color="text.secondary"
														align={"right"}
													>
														ACCESS
													</Typography>
												</CardContent>
											</CardActionArea>
										</Card>
									</Grid>
								))
							)
						}
					</Grid>
				</Grid>
			</SectionGrid>
        </Wrapper>
    );
};

/**
 * @param state
 * @returns {{}}
 */
const mapStateToProps = state => state.Benefits;

export default connect(
	mapStateToProps,
	dispatch => ({
		get: dispatch(get()),
	})
)(Benefits);