import Grid from "@mui/material/Grid2";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import React from "react";
import {useNavigate} from "react-router-dom";

/**
 * @param history
 * @param title
 * @param location
 * @returns {JSX.Element}
 * @constructor
 */
const AccountPrimaryHeader = ({
	title = "",
	location = "",
}): JSX.Element => {
	const push = useNavigate();
	
	return (
        <Grid
			justifyContent={"center"}
			container
			size={12}
		>
            <Grid
				className={"primary-header"}
				alignItems={"center"}
				justifyContent={"space-evenly"}
				container
				size={12}
			>
				<Grid
                    justifyContent={"center"}
                    alignItems={"center"}
                    container
                    size={{
                        sm: 1
                    }}
				>
					<ArrowBackIos
						cursor={"pointer"}
						onClick={()=>push(location)}
					/>
				</Grid>

				<Grid
                    justifyContent={"center"}
                    alignItems={"center"}
                    container
                    size={{
                        sm: 10
                    }}
				>
					{title}
				</Grid>

				<Grid
                    justifyContent={"center"}
                    alignItems={"center"}
                    container
                    size={{
                        sm: 1
                    }} />
			</Grid>
        </Grid>
    );
};

export default AccountPrimaryHeader;